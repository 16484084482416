import { Button, Modal, ModalSection, FlexGrid } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import type { Metafield } from '../../types/database/metafields.type'

type Props = {
    isLoading: boolean
    type: string
    isModalOpen: boolean
    metafield: Metafield
    setIsModalOpen: (isOpen: boolean) => void
    deleteMetafield: (metafield: Metafield) => void
}

export const MetafieldsDeleteConfirm: FunctionComponent<Props> = (
    props: Props
): any => {
    const {
        isLoading,
        type,
        isModalOpen,
        metafield,
        setIsModalOpen,
        deleteMetafield,
    } = props

    return (
        <Modal
            open={isModalOpen}
            title={`Delete ${
                type.charAt(0).toUpperCase() + type.slice(1)
            }  Metafield`}
            animate={false}
            onDismiss={(): void => setIsModalOpen(false)}
            centerY={true}
        >
            <ModalSection className="stl-mb-2">
                <div className="stl-mb-5 stl-justify-center">
                    <div className="stl-mb-20">
                        <b>Are you sure you want delete metafield?</b>
                    </div>
                    <div className="stl-center">
                        <p className="stl-mb-5">
                            <span>KEY: </span>
                            <b style={{ color: 'gray' }}>{metafield?.key}</b>
                        </p>
                        <p>
                            <span>NAMESPACE: </span>
                            <b style={{ color: 'gray' }}>
                                {metafield?.namespace}
                            </b>
                        </p>
                    </div>
                </div>
                <div className="centered-flex-container">
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-mt-4"
                    >
                        <Button
                            loading={isLoading}
                            variant="destructive"
                            size="medium"
                            onClick={(): void => {
                                deleteMetafield(metafield)
                            }}
                        >
                            Yes, Delete
                        </Button>
                        <Button
                            disabled={isLoading}
                            variant="neutral"
                            size="medium"
                            onClick={(): void => setIsModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </FlexGrid>
                </div>
            </ModalSection>
        </Modal>
    )
}
