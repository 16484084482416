import type { AlertColorVariant } from '@algolia/satellite'
import { Alert } from '@algolia/satellite'
import type { Dispatch, SetStateAction } from 'react'

export type NotificationProps = {
    title: string
    variant: AlertColorVariant
    message?: React.FC | string
    bannerState: Dispatch<SetStateAction<boolean>>
}

export const Notification: React.FunctionComponent<NotificationProps> = (
    props: NotificationProps
) => {
    const { title, variant, message, bannerState } = props

    return (
        <Alert
            title={title}
            variant={variant}
            usageContext="page"
            className="app-notification"
            onDismiss={(): void => bannerState(false)}
        >
            {message as string}
        </Alert>
    )
}
