import type { Entity } from '../types/database/entities.type'

export const findCategoryEntity = (entities: Entity[]): Entity | undefined => {
    for (const entity of entities) {
        if (entity.entity_type === 'category') {
            return entity
        }
    }
    return undefined
}
