import { stl, Input } from '@algolia/satellite'
import clamp from 'lodash/clamp'
import moment from 'moment'
import type { FunctionComponent } from 'react'

export type TimeValue = {
    hours: number
    minutes: number
}

type Props = {
    disabled?: boolean
    value: TimeValue
    onChange: (newValue: TimeValue) => void
}

export const TimeInput: FunctionComponent<Props> = (props): any => {
    const { disabled, value, onChange } = props

    return (
        <span className={stl`flex items-center space-x-1`}>
            <Input
                className="width-100"
                type="number"
                onChange={(evt): void => {
                    const hours = clamp(parseInt(evt.target.value, 10), 0, 23)
                    onChange({ ...value, hours })
                }}
                variant="small"
                value={moment(value).format('HH')}
                disabled={disabled}
            />
            <span>h</span>

            <Input
                className="width-100"
                type="number"
                onChange={(evt): void => {
                    const minutes = clamp(parseInt(evt.target.value, 10), 0, 59)
                    onChange({ ...value, minutes })
                }}
                variant="small"
                value={moment(value).format('mm')}
                disabled={disabled}
            />
            <span>m</span>
        </span>
    )
}
