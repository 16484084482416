import type { DateRangePickerCompleteTimeRange } from '@algolia/satellite'
import moment from 'moment'

const DATE_FORMAT = 'MMM D'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = `${DATE_FORMAT} [-] ${TIME_FORMAT}`
const DATE_YEAR_FORMAT = `${DATE_FORMAT}, YYYY`
const DATE_TIME_YEAR_FORMAT = `${DATE_YEAR_FORMAT} [-] ${TIME_FORMAT}`

export const isSameDayRange = (
    dateRange: DateRangePickerCompleteTimeRange
): boolean =>
    moment(dateRange.start).dayOfYear() === moment(dateRange.end).dayOfYear()

export const isFullStartOfRange = (
    dateRange: DateRangePickerCompleteTimeRange
): boolean =>
    moment(dateRange.start)
        .milliseconds(0)
        .isSame(moment(dateRange.start).startOf('day').millisecond(0))

export const isFullEndOfRange = (
    dateRange: DateRangePickerCompleteTimeRange
): boolean =>
    moment(dateRange.end)
        .milliseconds(0)
        .isSame(moment(dateRange.end).endOf('day').millisecond(0))

export const isFullDateRange = (
    dateRange: DateRangePickerCompleteTimeRange
): boolean => isFullStartOfRange(dateRange) && isFullEndOfRange(dateRange)

export const formatDateTimeRange = (
    dateRange: DateRangePickerCompleteTimeRange
): string => {
    const now = new Date()

    if (isSameDayRange(dateRange)) {
        const showYear = moment(dateRange.start).isSame(now, 'year')
        const dateFormat = showYear ? DATE_FORMAT : DATE_YEAR_FORMAT
        const dateTimeFormat = showYear
            ? DATE_TIME_FORMAT
            : DATE_TIME_YEAR_FORMAT

        return `${
            isFullDateRange(dateRange)
                ? moment(dateRange.start).utc().format(dateFormat)
                : `${moment(dateRange.start)
                      .utc()
                      .format(dateTimeFormat)} → ${moment(dateRange.end)
                      .utc()
                      .format(TIME_FORMAT)}`
        } (UTC)`
    }

    const showStartYear = !moment(dateRange.start).isSame(now, 'year')
    const rangeStart = isFullStartOfRange(dateRange)
        ? moment(dateRange.start)
              .utc()
              .format(showStartYear ? DATE_YEAR_FORMAT : DATE_FORMAT)
        : moment(dateRange.start)
              .utc()
              .format(showStartYear ? DATE_TIME_YEAR_FORMAT : DATE_TIME_FORMAT)

    const showEndYear = !moment(dateRange.end).isSame(now, 'year')
    const rangeEnd = isFullEndOfRange(dateRange)
        ? moment(dateRange.end)
              .utc()
              .format(showEndYear ? DATE_YEAR_FORMAT : DATE_FORMAT)
        : moment(dateRange.end)
              .utc()
              .format(showEndYear ? DATE_TIME_YEAR_FORMAT : DATE_TIME_FORMAT)

    return `${rangeStart} → ${rangeEnd} (UTC)`
}

// This is something of a hack that abuses the Date constructor's ability to parse a date string.
// We need to use a date relative to UTC-12:00 hours for enabling days in the datepicker so that
// e.g. somebody at 2022-06-22T00:01:00.000Z UTC can choose a date for users relative to the current day, like
// somebody still on 2022-06-21 in the Eastern US timezone.
// Equivalent construction using the Date constructor: new Date(new Date().toLocaleString('en-US', { timeZone: 'Etc/GMT+12' }))
// see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones and https://en.wikipedia.org/wiki/UTC%E2%88%9212:00
// for additional information regarding tz (timezone) lists.
export const internationalDateLineWest: Date = new Date(
    moment().utcOffset('-12').format('YYYY-MM-DD HH:mm')
)

export const toFakeLocal = (timestamp: number): number =>
    moment(new Date(timestamp).toISOString().slice(0, -1)).valueOf()

export const fromFakeLocal = (timestamp: number): number =>
    moment(`${moment(timestamp).format('YYYY-MM-DDTHH:mm:ss.sss')}Z`).valueOf()
