import { FlexGrid, IconButton, stl } from '@algolia/satellite'
import type { FunctionComponent, ReactElement } from 'react'
import { useCallback, useState, useEffect } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import type { DroppableProvided, DroppableProps } from 'react-beautiful-dnd'
import { PlusCircle } from 'react-feather'

import type { InstantSearchFacet } from '../../../types/database/instantSearch.type'

import { FacetCard } from './facetCard'

type FacetListProps = {
    facetableAttributes: Array<{ value: string; label: string }>
    facets: InstantSearchFacet[]
    onFacetChange: (facets: InstantSearchFacet[]) => void
    isNewInstantSearch?: boolean
    setIsChanged: (isChanged: boolean) => void
    isFromAdminView: () => boolean
    fetchIndexData: () => Promise<void>
}

const StrictModeDroppable = ({ children, ...props }: DroppableProps): any => {
    const [enabled, setEnabled] = useState(false)
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true))
        return () => {
            cancelAnimationFrame(animation)
            setEnabled(false)
        }
    }, [])
    if (!enabled) {
        return null
    }
    return <Droppable {...props}>{children}</Droppable>
}

export const FacetList: FunctionComponent<FacetListProps> = (
    props: FacetListProps
) => {
    const {
        facetableAttributes,
        facets,
        onFacetChange,
        setIsChanged,
        isNewInstantSearch,
        isFromAdminView,
        fetchIndexData,
    } = props
    const [reorderedFacets, setReorderedFacets] =
        useState<InstantSearchFacet[]>(facets)
    const [isNewFacetOpen, setIsNewFacetOpen] = useState<boolean>(false)

    const [newFacet, setNewFacet] = useState<InstantSearchFacet>({
        title: '',
        attribute: '',
        enabled: true,
        type: '',
    })

    useEffect(() => {
        if (isFromAdminView() && reorderedFacets.length !== facets.length) {
            setReorderedFacets(facets)
        }
    }, [facets])

    useEffect(() => {
        if (reorderedFacets.length === 0 && !isNewInstantSearch) {
            setIsNewFacetOpen(true)
        }

        onFacetChange(reorderedFacets)
    }, [reorderedFacets])

    function addNewFacet(updatedFacet: InstantSearchFacet): void {
        if (updatedFacet.title && updatedFacet.attribute && updatedFacet.type) {
            setReorderedFacets([updatedFacet, ...reorderedFacets])
            onFacetChange(reorderedFacets)
            setIsChanged(true)
        }
        setIsNewFacetOpen(false)
        setNewFacet({
            title: '',
            attribute: '',
            enabled: true,
            type: '',
        })
    }

    const onDragEnd = useCallback(
        (result: any): void => {
            if (!result.destination) {
                return
            }

            const items = Array.from(reorderedFacets)
            const [reorderedItem] = items.splice(result.source.index, 1)
            items.splice(result.destination.index, 0, reorderedItem)

            setReorderedFacets(items)
            onFacetChange(reorderedFacets)
            setIsChanged(true)
        },
        [reorderedFacets]
    )

    return (
        <DragDropContext onDragEnd={(result: any): void => onDragEnd(result)}>
            <FlexGrid direction="column" className={stl`w-full`} spacing="sm">
                <FlexGrid spacing="sm" alignment="center">
                    <h4 className={stl`display-body`}>
                        <b>Add Facet</b>{' '}
                    </h4>
                    <IconButton
                        disabled={isNewFacetOpen}
                        variant="subtle"
                        title="Add new facet"
                        icon={PlusCircle}
                        onClick={async (): Promise<void> => {
                            await fetchIndexData()
                            setIsNewFacetOpen(true)
                        }}
                    />
                </FlexGrid>
                <StrictModeDroppable
                    droppableId="droppable"
                    direction="vertical"
                    type="FACET"
                >
                    {(provided: DroppableProvided): any => (
                        <div {...provided.innerRef} ref={provided.innerRef}>
                            {isNewFacetOpen && (
                                <FacetCard
                                    facetableAttributes={facetableAttributes}
                                    setIsChanged={setIsChanged}
                                    key="new-facet"
                                    facet={newFacet}
                                    onFacetAdd={(
                                        updatedFacet: InstantSearchFacet
                                    ): void => {
                                        addNewFacet(updatedFacet)
                                    }}
                                    isNewFacet={true}
                                    // drag and drop props
                                    draggableId="new-facet"
                                    index={0}
                                />
                            )}
                            {reorderedFacets?.map(
                                (
                                    facet: InstantSearchFacet,
                                    index: number
                                ): ReactElement => (
                                    <FacetCard
                                        facetableAttributes={
                                            facetableAttributes
                                        }
                                        setIsChanged={setIsChanged}
                                        facet={facet}
                                        key={facet.attribute}
                                        onFacetEdit={(
                                            updatedFacet: InstantSearchFacet
                                        ): void => {
                                            const updatedFacets =
                                                reorderedFacets.map(
                                                    (
                                                        f: InstantSearchFacet,
                                                        i: number
                                                    ) => {
                                                        if (i === index) {
                                                            return updatedFacet
                                                        }
                                                        return f
                                                    }
                                                )
                                            setReorderedFacets(updatedFacets)
                                            onFacetChange(reorderedFacets)
                                        }}
                                        onFacetDelete={(
                                            attribute: string
                                        ): void => {
                                            const updatedFacets =
                                                reorderedFacets.filter(
                                                    (f: InstantSearchFacet) =>
                                                        f.attribute !==
                                                        attribute
                                                )
                                            setReorderedFacets(updatedFacets)
                                            onFacetChange(reorderedFacets)
                                            setIsChanged(true)
                                        }}
                                        // drag and drop props
                                        draggableId={facet.attribute}
                                        index={
                                            isNewFacetOpen ? index + 1 : index
                                        }
                                    />
                                )
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </FlexGrid>
        </DragDropContext>
    )
}
