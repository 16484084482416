import * as Sentry from '@sentry/react'

const IS_AUTHED_STORAGE_KEY = 'isPlatformAuthed'
const EXPIRATION = 3600000 // 1 hour

const AlgoliaAuthService = {
    updateStorage(isAuthed: boolean): void {
        try {
            const expirationDate = new Date().getTime() + EXPIRATION

            const data = {
                isAuthed,
                expiration: expirationDate,
            }

            localStorage.setItem(IS_AUTHED_STORAGE_KEY, JSON.stringify(data))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Please enable cookies to continue', error)
            Sentry.captureException(error)
        }
    },
    checkAuthStatus(): boolean {
        try {
            const storedData = localStorage.getItem(IS_AUTHED_STORAGE_KEY)
            if (storedData) {
                const { isAuthed, expiration } = JSON.parse(storedData)
                if (isAuthed && expiration > new Date().getTime()) {
                    return true
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Please enable cookies to continue', error)
            Sentry.captureException(error)
        }
        return false
    },
    removeAuth(): void {
        try {
            const storedData = localStorage.getItem(IS_AUTHED_STORAGE_KEY)
            if (storedData) {
                localStorage.removeItem(IS_AUTHED_STORAGE_KEY)
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Please enable cookies to continue', error)
            Sentry.captureException(error)
        }
    },
}

export default AlgoliaAuthService
