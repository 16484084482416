import {
    Button,
    Card,
    Field,
    FlexGrid,
    stl,
    TextArea,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState } from 'react'

import type { SourceType } from '../../../types/database/source.type'
import { isEmpty } from '../../../utils/utils'
import { useAuth } from '../../AuthContext'
import { AlgoliaLoginVerify } from '../AlgoliaLoginVerify'

type Props = {
    source: SourceType
    integrationId: string
    loading: boolean
    updateSource: (source: SourceType) => void
    setShowBcSetup: (show: boolean) => void
}

export const ManageAttributesToExclude: FunctionComponent<Props> = (
    props: Props
): any => {
    const { source, loading, updateSource, setShowBcSetup } = props
    const [attributesToExclude, setAttributesToExclude] = useState(
        source.attributes_to_exclude
    )
    const { isPlatformAuthed, platform } = useAuth()

    return (
        <FlexGrid
            className="stl-w-full stl-h-full stl-flex stl-justify-center stl-min-h-screen stl-p-6 stl-app-bg"
            direction="column"
            alignment="center"
            spacing="md"
        >
            <Card fullBleed>
                <AlgoliaLoginVerify />
                <FlexGrid
                    direction="column"
                    alignment="center"
                    spacing="md"
                    className={stl`p-6`}
                >
                    <h1 className="stl-display-heading stl-text-grey-900">
                        Configure your attributes to exclude
                    </h1>

                    <FlexGrid
                        className="stl-w-25 stl-w-full stl-mb-10 stl-p-2"
                        direction="column"
                        alignment="center"
                        spacing="md"
                    >
                        <Field
                            description={
                                <span>
                                    Comma separated list of attributes to be
                                    excluded from the index. If left empty all
                                    attributes will be indexed
                                </span>
                            }
                        >
                            <TextArea
                                value={attributesToExclude ?? ''}
                                onChange={(e: any): void => {
                                    setAttributesToExclude(
                                        e.target.value === ''
                                            ? null
                                            : e.target.value
                                    )
                                }}
                                placeholder="Enter comma separated list of attributes to exclude from the index"
                            />
                        </Field>
                    </FlexGrid>
                    <Button
                        className="stl-w-full stl-xenon-button"
                        size="large"
                        loading={loading}
                        disabled={!isPlatformAuthed || isEmpty(platform)}
                        onClick={(): void => {
                            updateSource({
                                ...source,
                                attributes_to_exclude: attributesToExclude,
                            })
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        className="stl-w-full"
                        size="large"
                        onClick={(): void => {
                            setShowBcSetup(false)
                        }}
                    >
                        Cancel
                    </Button>
                </FlexGrid>
            </Card>
        </FlexGrid>
    )
}
