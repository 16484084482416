import { Card, FlexGrid, stl } from '@algolia/satellite'
import { isEmpty } from 'lodash'
import type { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'

import algolia from '../../assets/images/algolia-logo-blue.svg'

export const ProductSync: FunctionComponent = (): any => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const error = queryParams.get('error')

    return (
        <FlexGrid
            className={stl`w-full h-full p-6 App-bg pt-80`}
            distribution="center"
        >
            <Card style={{ width: '500px' }}>
                <FlexGrid
                    direction="column"
                    className={stl`px-4 mb-4`}
                    spacing="sm"
                    alignment="center"
                >
                    <img
                        style={{
                            width: '250px',
                            height: 'auto',
                            paddingTop: '20px',
                        }}
                        className="login-logo "
                        src={algolia}
                        alt="Algolia"
                    />

                    <p style={{ paddingTop: '20px' }}>
                        {isEmpty(error) && (
                            <b>Product sync with Algolia started.</b>
                        )}
                        {!isEmpty(error) && <b>Product sync has failed.</b>}
                    </p>
                    {isEmpty(error) && (
                        <p style={{ paddingTop: '10px', textAlign: 'center' }}>
                            Index will be updated soon. Open you Algolia
                            BigCommerce Application to check the indexing
                            status.
                        </p>
                    )}
                    {!isEmpty(error) && (
                        <p style={{ paddingTop: '20px' }}>
                            Please try again later.
                        </p>
                    )}
                    <p style={{ paddingTop: '20px' }}>
                        You can close this window now.
                    </p>
                </FlexGrid>
            </Card>
        </FlexGrid>
    )
}
