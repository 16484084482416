import { Button, EmptyState, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import IntegrationService from '../../../services/database/integrationService'
import SourceService from '../../../services/database/sourceService'
import type { IntegrationType } from '../../../types/database/integration.type'
import { PLATFORMS } from '../../../utils/platforms'
import { useAlert } from '../../AlertContext'

type Props = {
    integrationId: string
    sourceId: string
    channelId?: string
}

export const IndexStep: FunctionComponent<Props> = (props: Props) => {
    const { integrationId, sourceId, channelId } = props
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [integration, setIntegration] = useState<
        IntegrationType | undefined
    >()
    const { showErrorAlert, showSuccessAlert } = useAlert()

    useEffect(() => {
        IntegrationService.fetch(integrationId).then((data) => {
            setIntegration(data)
        })
    }, [integrationId])

    const goToSourceList = function (): void {
        switch (integration?.platform.toLowerCase()) {
            case PLATFORMS.bigcommerce.toLowerCase():
                return navigate(
                    `/bigcommerce/${integrationId}/channels/${channelId}`
                )
            default:
                throw new Error("Platform doesn't exist")
        }
    }

    const indexProducts = function (): void {
        setIsLoading(true)
        SourceService.reindex(integrationId, sourceId)
            .then((data) => {
                setIsLoading(false)
                if (typeof data.error !== 'undefined') {
                    showErrorAlert(
                        'There was an error triggering the reindex',
                        'Error'
                    )

                    return
                }

                showSuccessAlert('Index has been triggered', 'Success')
                goToSourceList()
            })
            .catch(() => {
                setIsLoading(false)
                showErrorAlert(
                    'There was an error triggering the index',
                    'Error'
                )
                goToSourceList()
            })
    }

    return (
        <FlexGrid
            className={stl`w-full h-full min-h-screen p-6 bg-grey-50 App-bg`}
            direction="column"
            alignment="center"
            spacing="md"
            wrap={true}
        >
            <span className="index-step-state">
                <EmptyState
                    title={`Index Products`}
                    description="In order to have all your products in your index, you need to trigger a full reindex once. Would you like to index all your products now?"
                    actions={
                        <div
                            style={{
                                display: 'grid',
                                gap: '1rem',
                                gridAutoFlow: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                loading={isLoading}
                                variant="neutral"
                                className={stl`text-right xenon-button`}
                                onClick={indexProducts}
                            >
                                Yes, index now
                            </Button>
                            <Button
                                variant="subtle"
                                className={stl`text-right`}
                                onClick={goToSourceList}
                            >
                                No, index later
                            </Button>
                        </div>
                    }
                    centered
                    usageContext="feature"
                />
            </span>
        </FlexGrid>
    )
}
