import { FlexGrid } from '@algolia/satellite'
import React from 'react'
import type { RouteObject } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { useAuth } from '../components/AuthContext'
import { ChannelsList } from '../components/bigcommerce/channels'
import { SetupApplication } from '../components/bigcommerce/setupApplication'
import { SetupSource } from '../components/bigcommerce/setupSource'
import { Runs } from '../components/common/observability/runs'
import { Sources } from '../components/common/sources/sources'
import type { SourceType } from '../types/database/source.type'

import { commonCrumbs } from './commonCrumbs'
import { RequireAuth } from './requireAuth'

export const bigCommerceRoutes = (): RouteObject[] => {
    const { platform } = useAuth()

    const channelsCrumb = (data: Record<string, unknown>): any => {
        return (
            <Link to={`/bigcommerce/${data.integrationId}/channels`}>
                Channels
            </Link>
        )
    }

    const channelDetailCrumb = (data: Record<string, unknown>): any => {
        const sources = platform?.integrations?.sources?.filter(
            (source: SourceType) =>
                source.channel_id?.toString() === data.channelId
        )

        let channelName = undefined

        if (sources) {
            channelName = sources[0]?.config.channelName
        }

        return (
            <Link
                to={`/bigcommerce/${data.integrationId}/channels/${data.channelId}`}
            >
                {channelName ? channelName : 'Sources'}
            </Link>
        )
    }

    const bcRunsCrumb = (data: Record<string, unknown>): any => {
        return (
            <Link
                to={`/bigcommerce/${data.integrationId}/channels/${data.channelId}/sources/${data.sourceId}/manage/${data.taskId}`}
            >
                Runs
            </Link>
        )
    }

    const routes: RouteObject[] = [
        {
            path: 'bigcommerce/setup',
            element: (
                <RequireAuth>
                    <SetupApplication />
                </RequireAuth>
            ),

            handle: {},
        },
        {
            path: 'bigcommerce/setup/:integrationId/channels/:channelId',
            element: (
                <RequireAuth>
                    <SetupSource />
                </RequireAuth>
            ),

            handle: {
                crumb: (data: Record<string, unknown>) => (
                    <FlexGrid alignment="center">
                        {channelsCrumb(data)}
                        {commonCrumbs.separator}
                        {channelDetailCrumb(data)}
                        {commonCrumbs.separator}
                        <b>New source</b>
                    </FlexGrid>
                ),
            },
        },
        {
            path: 'bigcommerce/:integrationId/channels',
            element: (
                <RequireAuth>
                    <ChannelsList />
                </RequireAuth>
            ),
            handle: {
                crumb: (data: Record<string, unknown>) => (
                    <FlexGrid>{channelsCrumb(data)}</FlexGrid>
                ),
            },
        },
        {
            path: 'bigcommerce/:integrationId/channels/:channelId',
            element: (
                <RequireAuth>
                    <Sources />
                </RequireAuth>
            ),
            handle: {
                crumb: (data: Record<string, unknown>) => (
                    <FlexGrid alignment="center">
                        {channelsCrumb(data)}
                        {commonCrumbs.separator}
                        {channelDetailCrumb(data)}
                    </FlexGrid>
                ),
            },
        },
        {
            path: 'bigcommerce/:integrationId/channels/:channelId/sources/:sourceId/manage/:taskId',
            element: (
                <RequireAuth>
                    <Runs />
                </RequireAuth>
            ),
            handle: {
                crumb: (data: Record<string, unknown>) => (
                    <FlexGrid alignment="center">
                        {channelsCrumb(data)}
                        {commonCrumbs.separator}
                        {channelDetailCrumb(data)}
                        {commonCrumbs.separator}
                        {bcRunsCrumb(data)}
                    </FlexGrid>
                ),
            },
        },
    ]

    return routes
}
