import { ChevronRight } from 'react-feather'
import { Link } from 'react-router-dom'

export const commonCrumbs = {
    separator: <ChevronRight height={'18px'} />,
    integrations: <Link to="/integrations">Integrations</Link>,
    sources: (data: Record<string, unknown>): any => {
        return (
            <Link to={`/integrations/${data.integrationId ?? data.id}/sources`}>
                Sources
            </Link>
        )
    },
    source: (data: Record<string, unknown>): any => {
        return (
            <Link
                to={`/integrations/${data.integrationId}/sources/${data.sourceId}`}
            >
                Sources
            </Link>
        )
    },
    runs: (data: Record<string, unknown>): any => {
        return (
            <Link
                to={`/integrations/${data.integrationId}/sources/${data.sourceId}/manage/${data.taskId}`}
            >
                Runs
            </Link>
        )
    },
}
