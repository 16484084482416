import type { ReactNode } from 'react'
import { useEffect, createContext, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import type { HeaderSteps } from './common/header/header'
import { Header } from './common/header/header'

type HeaderContextType = {
    setSteps: (steps: HeaderSteps) => any
    setTitle: (title: string) => any
    clearHeader: () => void
}

const HeaderContext = createContext<HeaderContextType>({
    setSteps: (): void => {},
    setTitle: (): void => {},
    clearHeader: (): void => {},
})

export function useHeader(): HeaderContextType {
    return useContext(HeaderContext)
}

type Props = {
    children: ReactNode
}

export function HeaderProvider({ children }: Props): JSX.Element {
    const [title, setTitle] = useState<string | undefined>(undefined)
    const [steps, setSteps] = useState<HeaderSteps | undefined>(undefined)
    const location = useLocation()
    const [path, setPath] = useState(window.location.pathname)

    useEffect(() => {
        if (location.pathname !== path) {
            setPath(window.location.pathname)
            clearHeader()
        }
    }, [location.pathname])

    function clearHeader(): void {
        setTitle(undefined)
        setSteps(undefined)
    }

    return (
        <HeaderContext.Provider value={{ setSteps, setTitle, clearHeader }}>
            <Header title={title} steps={steps} />
            {children}
        </HeaderContext.Provider>
    )
}
