import {
    Card,
    FlexGrid,
    IconButton,
    stl,
    Input,
    AutoComplete,
    AutoCompleteEmptyState,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { CheckCircle, Menu, Trash2, X } from 'react-feather'

import type { InstantSearchSortOrder } from '../../../types/database/instantSearch.type'
import { isEmpty } from '../../../utils/utils'

type Props = {
    replicaNames: Array<{ value: string; label: string }>
    sortOrder?: InstantSearchSortOrder
    draggableId: string
    index: number
    isNew?: boolean
    setIsNew: (isNew: boolean) => void
    setIsChanged: (isChanged: boolean) => void
    addNewSortOrder: (sort: InstantSearchSortOrder) => void
    updateSortOrder: (sort: InstantSearchSortOrder, order: number) => void
    deleteSortOrder: (order: number) => void
}

export const SortOrderingCard: FunctionComponent<Props> = (props) => {
    const {
        replicaNames,
        sortOrder,
        index,
        draggableId,
        isNew,
        setIsNew,
        setIsChanged,
        addNewSortOrder,
        updateSortOrder,
        deleteSortOrder,
    } = props
    const so = sortOrder
    if (so) {
        so.order = index ?? 0
    }
    const [updatedSortOrders, setUpdatedSortOrders] = useState<
        InstantSearchSortOrder | undefined
    >(so)

    function validateSortOrder(): boolean {
        if (
            isEmpty(updatedSortOrders) ||
            isEmpty(updatedSortOrders?.replicaName) ||
            isEmpty(updatedSortOrders?.title)
        ) {
            return false
        }

        return true
    }

    function autocompleteAttributeValue(): any {
        if (isEmpty(updatedSortOrders?.replicaName)) {
            return undefined
        }

        return {
            value: updatedSortOrders?.replicaName,
            label: updatedSortOrders?.replicaName,
        }
    }

    return (
        <>
            <Draggable
                draggableId={draggableId}
                index={index}
                isDragDisabled={isNew}
            >
                {(provided: any): any => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Card
                            fullBleed
                            className={stl`p-2 mb-1`}
                            style={{
                                width: '585px',
                                marginBottom: isNew ? '10px' : undefined,
                            }}
                        >
                            <FlexGrid
                                alignment="center"
                                spacing="sm"
                                distribution="equalSpacing"
                                className="stl-w-full"
                            >
                                <IconButton
                                    title="Drag to reorder"
                                    icon={Menu}
                                    variant="subtle"
                                    disabled={isNew}
                                    size="small"
                                />

                                <Input
                                    placeholder="Title"
                                    variant="small"
                                    value={updatedSortOrders?.title}
                                    onChange={(e): void => {
                                        setUpdatedSortOrders({
                                            ...updatedSortOrders,
                                            title: e.target.value,
                                        } as InstantSearchSortOrder)
                                    }}
                                    onBlur={(e): void => {
                                        updateSortOrder(
                                            {
                                                ...updatedSortOrders,
                                                title: e.target.value,
                                            } as InstantSearchSortOrder,
                                            index
                                        )
                                    }}
                                />

                                <AutoComplete
                                    creatable
                                    clearable
                                    menuFooter="Attributes"
                                    options={replicaNames}
                                    placeholder="Attribute"
                                    menuSize="medium"
                                    disabled={updatedSortOrders?.isDefault}
                                    emptyState={
                                        <AutoCompleteEmptyState title="No replicas created for this index" />
                                    }
                                    className="stl-input stl-group stl-input-small width-200"
                                    value={autocompleteAttributeValue()}
                                    variant="large"
                                    onChange={(option: any): void => {
                                        if (option === null) {
                                            return
                                        }

                                        if (isNew) {
                                            setUpdatedSortOrders({
                                                ...updatedSortOrders,
                                                replicaName: option.value,
                                            } as InstantSearchSortOrder)
                                        } else {
                                            updateSortOrder(
                                                {
                                                    ...updatedSortOrders,
                                                    replicaName: option.value,
                                                } as InstantSearchSortOrder,
                                                index
                                            )
                                        }
                                    }}
                                />

                                <Input
                                    hidden={true}
                                    value={index}
                                    onChange={(e): void => {
                                        setUpdatedSortOrders({
                                            ...updatedSortOrders,
                                            order: Number(e.target.value),
                                        } as InstantSearchSortOrder)
                                    }}
                                    onBlur={(e): void => {
                                        updateSortOrder(
                                            {
                                                ...updatedSortOrders,
                                                order: Number(e.target.value),
                                            } as InstantSearchSortOrder,
                                            index
                                        )
                                    }}
                                />

                                {isNew ? (
                                    <IconButton
                                        variant={
                                            validateSortOrder()
                                                ? 'primary'
                                                : undefined
                                        }
                                        icon={
                                            validateSortOrder()
                                                ? CheckCircle
                                                : X
                                        }
                                        title={
                                            validateSortOrder()
                                                ? 'Confirm Facet'
                                                : 'Cancel'
                                        }
                                        onClick={(): void => {
                                            addNewSortOrder({
                                                ...updatedSortOrders,
                                                isDefault: false,
                                            } as InstantSearchSortOrder)
                                            if (validateSortOrder()) {
                                                setIsChanged(true)
                                            }
                                            setIsNew(false)
                                        }}
                                    />
                                ) : (
                                    <IconButton
                                        disabled={updatedSortOrders?.isDefault}
                                        icon={Trash2}
                                        title="Delete Sort"
                                        onClick={(e: any): void => {
                                            setUpdatedSortOrders({
                                                ...updatedSortOrders,
                                                order: Number(e.target.value),
                                            } as InstantSearchSortOrder)
                                            deleteSortOrder(index)
                                            setIsNew(false)
                                            setIsChanged(true)
                                        }}
                                    />
                                )}
                            </FlexGrid>
                        </Card>
                    </div>
                )}
            </Draggable>
        </>
    )
}
