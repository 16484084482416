import {
    AutoCompleteEmptyState,
    Card,
    FlexGrid,
    IconButton,
    stl,
    Input,
    Select,
    Checkbox,
    AutoComplete,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { CheckCircle, Menu, Trash2, X } from 'react-feather'

import type { InstantSearchFacet } from '../../../types/database/instantSearch.type'
import { isEmpty } from '../../../utils/utils'

type Props = {
    facetableAttributes: Array<{ value: string; label: string }>
    facet: InstantSearchFacet
    draggableId: string
    index: number
    onFacetAdd?: (facet: InstantSearchFacet) => void
    onFacetEdit?: (facets: InstantSearchFacet, index: number) => void
    onFacetDelete?: (attribute: string) => void
    isNewFacet?: boolean
    setIsChanged: (isChanged: boolean) => void
}

export const FacetCard: FunctionComponent<Props> = (props) => {
    const {
        facetableAttributes,
        facet,
        index,
        draggableId,
        onFacetAdd,
        onFacetEdit,
        onFacetDelete,
        isNewFacet,
        setIsChanged,
    } = props
    const [updatedFacet, setUpdatedFacet] = useState<InstantSearchFacet>(facet)

    useEffect(() => {
        if (onFacetEdit && !isNewFacet) {
            onFacetEdit(updatedFacet, index)
        }
    }, [updatedFacet])

    function handleisChanged(): void {
        if (!isNewFacet) {
            setIsChanged(true)
        }
    }

    function autocompleteAttributeValue(): any {
        if (isEmpty(updatedFacet.attribute)) {
            return undefined
        }
        if (updatedFacet.type === 'hierarchical_menu') {
            return {
                value: 'categories',
                label: 'Category',
            }
        }
        return {
            value: updatedFacet.title,
            label: updatedFacet.attribute,
        }
    }

    return (
        <>
            <Draggable
                draggableId={draggableId}
                index={index}
                isDragDisabled={isNewFacet}
            >
                {(provided: any): any => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Card
                            fullBleed
                            className={stl`p-2 mb-1`}
                            style={{
                                width: '585px',
                                marginBottom: isNewFacet ? '10px' : undefined,
                            }}
                        >
                            <FlexGrid
                                alignment="center"
                                spacing="sm"
                                distribution="equalSpacing"
                                className={stl`w-full p-2`}
                            >
                                <IconButton
                                    title="Drag to reorder"
                                    icon={Menu}
                                    variant="subtle"
                                    disabled={isNewFacet}
                                    size="small"
                                />
                                <Input
                                    placeholder="Title"
                                    variant="small"
                                    style={{ width: '14%' }}
                                    value={updatedFacet.title}
                                    onChange={(e): void => {
                                        handleisChanged()
                                        setUpdatedFacet({
                                            ...updatedFacet,
                                            title: e.target.value,
                                        })
                                    }}
                                />
                                <AutoComplete
                                    creatable
                                    clearable
                                    menuFooter="Attributes"
                                    options={facetableAttributes}
                                    placeholder="Attribute"
                                    menuSize="medium"
                                    disabled={
                                        updatedFacet.type ===
                                        'hierarchical_menu'
                                    }
                                    emptyState={
                                        <AutoCompleteEmptyState title="Index products to enable attribute autocomplete" />
                                    }
                                    className="stl-input stl-group stl-input-small width-21"
                                    value={autocompleteAttributeValue()}
                                    variant="medium"
                                    onChange={(option: any): void => {
                                        if (option === null) {
                                            return
                                        }

                                        handleisChanged()
                                        setUpdatedFacet({
                                            ...updatedFacet,
                                            attribute: option.value,
                                        })
                                    }}
                                />
                                <Select
                                    style={{ width: '28%' }}
                                    variant="small"
                                    value={updatedFacet.type}
                                    onChange={(e): void => {
                                        handleisChanged()

                                        if (
                                            e.target.value ===
                                            'hierarchical_menu'
                                        ) {
                                            setUpdatedFacet({
                                                ...updatedFacet,
                                                attribute: 'categories',
                                                type: e.target.value,
                                            })
                                        } else {
                                            setUpdatedFacet({
                                                ...updatedFacet,
                                                type: e.target.value,
                                            })
                                        }
                                    }}
                                >
                                    <option value="">Select a type</option>
                                    <option value="conjunctive">
                                        Conjunctive
                                    </option>
                                    <option value="disjunctive">
                                        Disjunctive
                                    </option>
                                    <option value="slider">Slider</option>
                                    <option value="slider_range_combo">
                                        Slider + Range
                                    </option>
                                    <option value="input">Input</option>
                                    <option value="hierarchical_menu">
                                        Hierarchical
                                    </option>
                                </Select>
                                <Checkbox
                                    disabled={
                                        updatedFacet.type ===
                                            'hierarchical_menu' ||
                                        updatedFacet.type === 'slider' ||
                                        updatedFacet.type ===
                                            'slider_range_combo'
                                    }
                                    checked={updatedFacet.searchable}
                                    onChange={(e): void => {
                                        handleisChanged()
                                        setUpdatedFacet({
                                            ...updatedFacet,
                                            searchable: e.target.checked,
                                        })
                                    }}
                                >
                                    Searchable
                                </Checkbox>

                                {isNewFacet ? (
                                    <IconButton
                                        variant={
                                            updatedFacet.title &&
                                            updatedFacet.attribute &&
                                            updatedFacet.type
                                                ? 'primary'
                                                : undefined
                                        }
                                        icon={
                                            updatedFacet.title &&
                                            updatedFacet.attribute &&
                                            updatedFacet.type
                                                ? CheckCircle
                                                : X
                                        }
                                        title={
                                            updatedFacet.title &&
                                            updatedFacet.attribute &&
                                            updatedFacet.type
                                                ? 'Confirm Facet'
                                                : 'Cancel'
                                        }
                                        onClick={(): void => {
                                            if (onFacetAdd) {
                                                onFacetAdd(updatedFacet)
                                            }
                                        }}
                                    />
                                ) : (
                                    <IconButton
                                        icon={Trash2}
                                        title="Delete Facet"
                                        onClick={(): void => {
                                            if (onFacetDelete) {
                                                onFacetDelete(facet.attribute)
                                            }
                                        }}
                                    />
                                )}
                            </FlexGrid>
                        </Card>
                    </div>
                )}
            </Draggable>
        </>
    )
}
