import { CardHeader, Checkbox, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import type { Insight } from '../../../../types/database/insight.type'

type Props = {
    currentInsights: Insight
    setCurrentInsights: (insights: Insight) => void
}

export const ClickAnalytics: FunctionComponent<Props> = (props): any => {
    const { currentInsights, setCurrentInsights } = props

    return (
        <>
            <CardHeader className={stl`px-4 mt-4 mb-2`}>
                <h2 className={stl`display-heading`}>Click Analytics</h2>
            </CardHeader>
            <FlexGrid
                className={`stl-w-full stl-p-6`}
                direction="column"
                spacing="lg"
            >
                <FlexGrid className={stl`w-full`}>
                    <p className={`stl-prl-05`}>
                        Boost your conversion rates and improve user engagement
                        by understanding how users interact with your
                        application or website.
                    </p>
                </FlexGrid>
                <FlexGrid className={stl`w-full`}>
                    <p className={`stl-prl-05`}>
                        Capturing event data empowers you to make informed
                        decisions and enables you to activate AI-powered
                        features like Dynamic ReRanking that leverage this data
                        to further enhance search performance, delivering an
                        even more powerful and efficient user experience.{' '}
                        <a
                            target={'_blank'}
                            href={`https://www.algolia.com/doc/guides/sending-events/getting-started`}
                        >
                            Read Our Docs
                        </a>
                    </p>
                </FlexGrid>
                <FlexGrid className={stl`w-full`}>
                    <Checkbox
                        checked={currentInsights?.is_enabled}
                        onChange={(): void => {
                            setCurrentInsights({
                                ...(currentInsights as Insight),
                                is_enabled: !currentInsights?.is_enabled,
                                config: {
                                    ...currentInsights?.config,
                                    use_cookies: currentInsights?.is_enabled
                                        ? false
                                        : currentInsights?.is_enabled,
                                },
                            })
                        }}
                    >
                        <div className={stl`cursor-pointer`}>
                            Use click analytics
                        </div>
                    </Checkbox>
                </FlexGrid>
            </FlexGrid>
        </>
    )
}
