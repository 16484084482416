import { Button, FlexGrid, Modal, ModalSection } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import { Loader } from '../../common/loader'

type Props = {
    type: string
    isModalOpen: boolean
    isLoading: boolean
    setIsModalOpen: (isOpen: boolean) => void
    applyToTheme: (isEnabled: boolean) => void
}

export const ApplyToThemeConfirm: FunctionComponent<Props> = (props): any => {
    const { type, isModalOpen, isLoading, setIsModalOpen, applyToTheme } = props

    return (
        <Modal
            open={isModalOpen}
            title={`Apply ${type} To Your Channel Themes`}
            animate={false}
            onDismiss={(): void => setIsModalOpen(false)}
            centerY={true}
        >
            <ModalSection className="stl-mb-2">
                {isLoading && (
                    <>
                        <Loader />
                        <div className="stl-mb-5 stl-justify-center">
                            {`Applying ${type} to all channel themes now`}
                        </div>
                    </>
                )}
                {!isLoading && (
                    <div className="stl-mb-5 stl-justify-center">
                        {`Are you sure you want to apply ${type} to your all themes
                    for this channel?`}
                    </div>
                )}
                <FlexGrid
                    direction="row"
                    alignment="center"
                    spacing="md"
                    className="stl-mt-4"
                >
                    <Button
                        disabled={isLoading}
                        variant="destructive"
                        size="medium"
                        onClick={(): void => {
                            applyToTheme(true)
                        }}
                    >
                        Yes, Apply To Channel Themes
                    </Button>
                    <Button
                        variant="neutral"
                        size="medium"
                        onClick={(): void => setIsModalOpen(false)}
                    >
                        Cancel
                    </Button>
                </FlexGrid>
            </ModalSection>
        </Modal>
    )
}
