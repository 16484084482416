import type { ContentTab } from '@algolia/satellite'
import { Field, Select, ContentTabs, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import BigCommerceService from '../../../services/bigCommerce/bigCommerceService'
import type { BcChannel } from '../../../types/bigCommerce/channelsRespone.type'
import type { AutoComplete } from '../../../types/database/autoComplete.type'
import type { Entity } from '../../../types/database/entities.type'
import type { InstantSearch } from '../../../types/database/instantSearch.type'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { Recommend } from '../../../types/database/recommend.type'
import type { SourceType } from '../../../types/database/source.type'
import { PLATFORMS } from '../../../utils/platforms'
import { isEmpty } from '../../../utils/utils'
import { useAuth } from '../../AuthContext'
import { SourceGrid } from '../../common/sources/sourceGrid'
import { Support } from '../../common/support'

import { AutocompleteConfig } from './autocomplete'
import { Insights } from './insights/insights'
import { InstantSearchConfig } from './instantSearch'
import { RecommendConfig } from './recommend'

type Props = {
    currentChannel: BcChannel | undefined
    setCurrentChannel: (chn: BcChannel | undefined) => void
    currentIntegration: IntegrationType | null | undefined
    applications: string[]
    showBcSetup: boolean
    selectedSourceId: string | undefined
    activeTab: number
    setSelectedSourceId: (id: string | undefined) => void
    setShowBcSetup: (show: boolean) => void
    editSource: (src: SourceType) => void
    setActiveTab: (tab: number) => void
}

const defaultAutocomplete = {
    is_enabled: false,
    config: {
        debug_mode: false,
        hitsPerPage: 6,
        placement_region: 'header_bottom--global',
        algolia_search_css_selector: '#quickSearch',
    },
}

const defaultInstantSearch = {
    is_enabled: false,
    config: {
        bc_page_url: '/search-results',
        is_distinct: false,
        bc_page_name: 'Search Results',
        hits_per_page: '15',
        placement_region: 'page_builder_content',
        search_form_attribute: 'data-url',
        show_out_of_stock_items: false,
        search_form_css_selector: 'form.form[data-quick-search-form]',
        facet_options_limit: 35,
    },
    sort_orders: [],
    facets: [
        {
            type: 'hierarchical_menu',
            title: 'Category',
            enabled: true,
            attribute: 'categories',
        },
        {
            type: 'slider_range_combo',
            title: 'Price',
            enabled: true,
            attribute: 'default_price',
        },
    ],
}

const defaultCategories: Entity = {
    entity_type: 'category',
    config: {
        isEnabled: false,
        placement_region: 'category_below_header',
        css_selector: '.page',
        category_ids: {},
    },
}

export const ChannelDetail: FunctionComponent<Props> = (props: Props): any => {
    const { platform, isAdmin, appexJwt } = useAuth()
    const {
        currentChannel,
        setCurrentChannel,
        currentIntegration,
        applications,
        showBcSetup,
        selectedSourceId,
        activeTab,
        setSelectedSourceId,
        setShowBcSetup,
        editSource,
        setActiveTab,
    } = props
    const [channels, setChannels] = useState<BcChannel[]>([])
    const [currentInstantSearch, setCurrentInstantSearch] = useState<
        InstantSearch | undefined
    >(defaultInstantSearch)
    const [currentAutocomplete, setCurrentAutocomplete] = useState<
        AutoComplete | undefined
    >(defaultAutocomplete)
    const [currentRecommend, setCurrentRecommend] = useState<
        Recommend | undefined
    >()
    const [isMultiChannel, setIsMultiChannel] = useState<boolean>(false)
    const [currentCategories, setCurrentCategories] =
        useState<Entity>(defaultCategories)

    function isFromAdminView(): boolean {
        if (
            currentIntegration?.platform === PLATFORMS.bigcommerce &&
            isAdmin &&
            (isEmpty(platform) || isEmpty(appexJwt))
        ) {
            return true
        }

        return false
    }

    async function isMultiChannelIntegration(): Promise<void> {
        if (
            currentIntegration?.platform === PLATFORMS.bigcommerce &&
            !isFromAdminView()
        ) {
            const store = await BigCommerceService.fetchStoreInfo()
            setIsMultiChannel(
                store?.features?.multi_storefront_enabled ?? false
            )
        }
    }

    function updateAutocomplete(): void {
        const autocomplete = currentIntegration?.autocomplete?.find(
            (ac) => ac.channel_id === currentChannel?.id
        ) as AutoComplete

        if (typeof autocomplete === 'undefined') {
            setCurrentAutocomplete(defaultAutocomplete)
        } else {
            setCurrentAutocomplete(autocomplete)
        }
    }

    function updateInstantsearch(): void {
        const instantsearch = currentIntegration?.instantsearch?.find(
            (iS) => iS.channel_id === currentChannel?.id
        )

        if (!instantsearch) {
            setCurrentInstantSearch(defaultInstantSearch)
            setCurrentCategories(defaultCategories)
            return
        }

        setCurrentInstantSearch(instantsearch)

        const currentCategory = instantsearch.entities?.find(
            (entity) => entity.entity_type === 'category'
        )

        if (currentCategory) {
            setCurrentCategories(currentCategory)
        }
    }

    function updateRecommend(): void {
        const recommend = currentIntegration?.recommend?.find(
            (rec) => rec.channel_id === currentChannel?.id
        )

        if (recommend) {
            setCurrentRecommend(recommend)
        }
    }

    useEffect(() => {
        if (typeof currentIntegration !== 'undefined') {
            if (isFromAdminView()) {
                const chnls: any[] = []
                currentIntegration?.sources?.forEach((source) => {
                    const channel = {
                        name: source.config.channelName,
                        id: source.channel_id,
                    }

                    if (
                        chnls.filter((chn) => {
                            return chn.id === channel.id
                        }).length === 0
                    ) {
                        chnls.push(channel)
                    }
                })
                setChannels(chnls)
                if (chnls.length > 0 && currentChannel?.id === undefined) {
                    setCurrentChannel(chnls[0])
                }
            }
            isMultiChannelIntegration()
        }
    }, [currentIntegration])

    useEffect(() => {
        if (typeof currentChannel !== 'undefined') {
            updateAutocomplete()
            updateInstantsearch()
            updateRecommend()
        }
    }, [currentChannel])

    const SearchSettingsTab: ReactNode = (
        <FlexGrid
            className={stl`w-full h-full p-6`}
            direction="column"
            spacing="md"
            wrap={true}
            distribution="center"
            alignment="center"
        >
            <AutocompleteConfig
                currentChannel={currentChannel}
                currentIntegration={currentIntegration}
                currentAutocomplete={currentAutocomplete}
                setCurrentAutocomplete={setCurrentAutocomplete}
                isFromAdminView={isFromAdminView}
                setCurrentChannel={setCurrentChannel}
            />
            <InstantSearchConfig
                currentChannel={currentChannel}
                currentIntegration={currentIntegration}
                currentInstantSearch={currentInstantSearch}
                setCurrentInstantSearch={setCurrentInstantSearch}
                setCurrentCategories={setCurrentCategories}
                isFromAdminView={isFromAdminView}
                isMultiChannel={isMultiChannel}
                currentCategories={currentCategories}
            />
        </FlexGrid>
    )

    const SourceTab: ReactNode = (
        <FlexGrid
            className={stl`w-full h-full pt-4 px-4`}
            direction="row"
            spacing="md"
            wrap={true}
            distribution="center"
        >
            <SourceGrid
                applications={applications}
                currentChannel={currentChannel}
                showBcSetup={showBcSetup}
                selectedSourceId={selectedSourceId}
                setShowBcSetup={setShowBcSetup}
                editSource={editSource}
                isFromAdminView={isFromAdminView}
            />
        </FlexGrid>
    )

    const insightsTab: ReactNode = (
        <FlexGrid
            className={stl`w-full h-full p-6`}
            direction="column"
            spacing="md"
            wrap={true}
            distribution="center"
            alignment="center"
        >
            <Insights
                currentChannel={currentChannel}
                currentIntegration={currentIntegration}
                isFromAdminView={isFromAdminView}
            />
        </FlexGrid>
    )

    const supportTab: ReactNode = (
        <FlexGrid
            className={stl`w-full h-full p-6`}
            direction="column"
            spacing="md"
            wrap={true}
            distribution="center"
            alignment="center"
        >
            <Support
                platform="BigCommerce"
                documentationUrl="https://www.algolia.com/doc/integration/bigcommerce/get-started/installation/"
            />
        </FlexGrid>
    )

    const recommendTab: ReactNode = (
        <FlexGrid
            className={stl`w-full h-full p-6`}
            direction="column"
            spacing="md"
            wrap={true}
            distribution="center"
            alignment="center"
        >
            <RecommendConfig
                currentChannel={currentChannel}
                currentIntegration={currentIntegration}
                currentRecommend={currentRecommend}
                setCurrentRecommend={setCurrentRecommend}
                isFromAdminView={isFromAdminView}
                isMultiChannel={isMultiChannel}
            />
        </FlexGrid>
    )

    const tabs: ContentTab[] = [
        {
            label: `Sources`,
            content: SourceTab,
        },
        {
            label: `Search Settings`,
            content: SearchSettingsTab,
        },
        {
            label: `Insights`,
            content: insightsTab,
        },
        {
            label: `Recommend`,
            content: recommendTab,
        },
        {
            label: `Support`,
            content: supportTab,
        },
    ]

    return (
        <>
            {isFromAdminView() && (
                <FlexGrid className={stl`w-56 pt-4 px-6`}>
                    <Field label={'Channel'}>
                        <Select
                            value={currentChannel?.id}
                            onChange={(e: any): void => {
                                const channel = channels.find(
                                    (chn) => `${chn.id}` === e.target.value
                                )
                                setShowBcSetup(false)
                                setCurrentChannel(channel)
                            }}
                        >
                            {channels.map((channel) => (
                                <option key={channel.id} value={channel.id}>
                                    {channel.name}
                                </option>
                            ))}
                        </Select>
                    </Field>
                </FlexGrid>
            )}
            <div>
                <ContentTabs
                    tabs={tabs}
                    tabIndex={activeTab}
                    className={stl`pt-4 px-6`}
                    onChange={(tabIndex: number): void => {
                        setShowBcSetup(false)
                        setSelectedSourceId(undefined)
                        setActiveTab(tabIndex)
                    }}
                />
            </div>
        </>
    )
}
