import type { AutoComplete } from '../../types/database/autoComplete.type'
import type { AutocompleteDefaultCode } from '../../types/theming/autocompleteDefaultCode.type'
import type { Versioning } from '../../types/theming/versioning.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const AutocompleteService = {
    async createAutocompleteSettings(
        integrationId: string,
        channel: number,
        data: AutoComplete
    ): Promise<AutoComplete> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete`,
            REQUEST_TYPES.post,
            JSON.stringify(data)
        )
    },
    async fetchAutocompleteSettings(
        integrationId: string,
        channel: number,
        autocompleteId: string
    ): Promise<AutoComplete> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}`,
            REQUEST_TYPES.get
        )
    },
    async updateAutocompleteSettings(
        integrationId: string,
        channel: number,
        autocompleteId: string,
        data: AutoComplete
    ): Promise<AutoComplete> {
        // eslint-disable-next-line no-param-reassign
        delete data.id
        // eslint-disable-next-line no-param-reassign
        delete data.integration_id
        // eslint-disable-next-line no-param-reassign
        delete data.channel_id
        // eslint-disable-next-line no-param-reassign
        delete data.config?.widget_id
        // eslint-disable-next-line no-param-reassign
        delete data.config?.placement_id
        // eslint-disable-next-line no-param-reassign
        delete data.created_at
        // eslint-disable-next-line no-param-reassign
        delete data.last_modified

        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}`,
            REQUEST_TYPES.put,
            JSON.stringify(data)
        )
    },
    async applyAutocompleteSettings(
        integrationId: string,
        channel: number,
        autocompleteId: string
    ): Promise<AutoComplete> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}/apply`,
            REQUEST_TYPES.post
        )
    },
    async removeAutocompleteSettings(
        integrationId: string,
        channel: number,
        autocompleteId: string
    ): Promise<AutoComplete> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}/remove`,
            REQUEST_TYPES.delete
        )
    },
    async getAutocompleteDefaultCode(
        integrationId: string,
        channel: number,
        autocompleteId: string
    ): Promise<AutocompleteDefaultCode> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}/default-code`,
            REQUEST_TYPES.get
        )
    },
    async getAutocompleteVersioning(
        integrationId: string,
        channel: number,
        autocompleteId: string
    ): Promise<Versioning> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}/versioning`,
            REQUEST_TYPES.get
        )
    },
    async updateCss(
        integrationId: string,
        channel: number,
        autocompleteId: string,
        cssData: { css: string; config: { css_version: string } }
    ): Promise<string[]> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}/update-css`,
            REQUEST_TYPES.patch,
            JSON.stringify(cssData)
        )
    },
    async updateJavascript(
        integrationId: string,
        channel: number,
        autocompleteId: string,
        javascriptData: {
            javascript: string
            config: { javascript_version: string }
        }
    ): Promise<string[]> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/autocomplete/${autocompleteId}/update-javascript`,
            REQUEST_TYPES.patch,
            JSON.stringify(javascriptData)
        )
    },
}

export default AutocompleteService
