import {
    OverflowTooltipWrapper,
    Sidebar,
    SidebarLink,
    SidebarNav,
    stl,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { Edit, EyeOff, List, Settings } from 'react-feather'

import type { SourceType } from '../../../types/database/source.type'
import { ReindexButton } from '../reindexButton'

type Props = {
    activeView: string
    isNew: boolean
    setActiveView: (view: string) => void
    source?: SourceType
}

export const ManageSidebar: FunctionComponent<Props> = (props: Props): any => {
    const { source, activeView, isNew, setActiveView } = props

    const handleClick = (view: string): void => {
        if (isNew) {
            return
        }

        setActiveView(view)
    }

    const getClassName = (view: string): string => {
        if (
            isNew &&
            ['customFields', 'metafields', 'attributesToExclude'].includes(view)
        ) {
            return 'disabled-side-tab '
        }

        return activeView === view ? 'active-side-tab' : 'side-tab'
    }

    return (
        <>
            <OverflowTooltipWrapper>
                <div className={stl`max-w-15ch ptb-20`}>
                    {typeof source?.config.indexName !== 'undefined'
                        ? source.config.indexName
                        : 'New Source'}
                </div>
            </OverflowTooltipWrapper>

            <Sidebar
                location={globalThis.location}
                variant="secondary"
                className={stl`pt-12 no-border`}
            >
                <SidebarNav label="Source settings">
                    {!isNew && (
                        <>
                            <ReindexButton
                                source={source as SourceType}
                                isFromAdminView={(): boolean => false}
                            />
                        </>
                    )}
                    <SidebarLink
                        href="/settings"
                        icon={Settings}
                        className={getClassName('settings')}
                        onClick={(e): void => {
                            handleClick('settings')
                            e.preventDefault()
                        }}
                    >
                        Settings
                    </SidebarLink>
                    <SidebarLink
                        href="/customfields"
                        icon={Edit}
                        className={getClassName('customFields')}
                        onClick={(e): void => {
                            handleClick('customFields')
                            e.preventDefault()
                        }}
                    >
                        Custom Fields
                    </SidebarLink>
                    <SidebarLink
                        href="/metafields"
                        icon={List}
                        className={getClassName('metafields')}
                        onClick={(e): void => {
                            handleClick('metafields')
                            e.preventDefault()
                        }}
                    >
                        Metafields
                    </SidebarLink>
                    <SidebarLink
                        href="/attributes-to-exclude"
                        icon={EyeOff}
                        className={getClassName('attributesToExclude')}
                        onClick={(e): void => {
                            handleClick('attributesToExclude')
                            e.preventDefault()
                        }}
                    >
                        Attributes To Exclude
                    </SidebarLink>
                </SidebarNav>
            </Sidebar>
        </>
    )
}
