import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const ConnectionsService = {
    async sourceUpdate(integrationId: string, sourceId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source-connect/${sourceId}`,
            REQUEST_TYPES.put
        )
    },
}

export default ConnectionsService
