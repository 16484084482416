import type { RenderTargetParams } from '@algolia/satellite'
import {
    OverflowTooltipWrapper,
    IconButton,
    FlexGrid,
    Modal,
    ModalSection,
    Link,
    Button,
    Card,
    Dropdown,
    DropdownButtonItem,
    stl,
} from '@algolia/satellite'
import type { FunctionComponent, MouseEventHandler } from 'react'
import { useState } from 'react'
import {
    MoreVertical,
    Trash2,
    Clock,
    Server,
    Edit,
    ExternalLink,
} from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'

import SourceService from '../../../services/database/sourceService'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { SourceType } from '../../../types/database/source.type'
import type { Platform } from '../../../types/platform.type'
import { PLATFORMS } from '../../../utils/platforms'
import { RECORD_TYPES } from '../../../utils/recordTypes'
import showHtml from '../../../utils/showHtml'
import { isEmpty } from '../../../utils/utils'
import { useAuth } from '../../AuthContext'
import { ReindexButton } from '../reindexButton'

type Props = {
    userAlgoliaApplications: string[]
    source: SourceType
    integration: IntegrationType
    platform: Platform | null
    showErrorAlert: (message: string, type: string) => void
    showSuccessAlert: (message: string, type: string) => void
    onActionComplete: () => void
    isFromAdminView: () => boolean
    editSource: (src: SourceType) => void
}

export const SourceCard: FunctionComponent<Props> = (props: Props) => {
    const params = useParams()
    const {
        userAlgoliaApplications,
        source,
        integration,
        showErrorAlert,
        showSuccessAlert,
        onActionComplete,
        isFromAdminView,
        editSource,
    } = props

    const navigate = useNavigate()
    const { isAdmin, platform } = useAuth()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const renderButton = ({ toggle }: RenderTargetParams): any => {
        const handleClick: MouseEventHandler<HTMLButtonElement> = (evt) => {
            if (!evt.defaultPrevented) {
                toggle()
            }
        }

        return (
            <IconButton
                type="button"
                title="More"
                showTooltip={false}
                icon={MoreVertical}
                onClick={handleClick}
            />
        )
    }

    function isIndexInUse(): boolean {
        if (typeof integration.instantsearch !== 'undefined') {
            for (const instantsearch of integration.instantsearch) {
                if (
                    instantsearch.is_enabled &&
                    source.id === instantsearch.config?.current_source
                ) {
                    return false
                }
            }
        }

        if (typeof integration.autocomplete !== 'undefined') {
            for (const autocomplete of integration.autocomplete) {
                if (
                    autocomplete.is_enabled &&
                    source.id === autocomplete.config?.current_source
                ) {
                    return false
                }
            }
        }

        if (typeof integration.recommend !== 'undefined') {
            for (const recommend of integration.recommend) {
                if (
                    recommend.is_enabled &&
                    source.id === recommend.config?.current_source
                ) {
                    return false
                }
            }
        }

        return true
    }

    function deleteSource(): any {
        if (!isIndexInUse()) {
            showErrorAlert(
                `Failed to delete source - Source in use by Autocomplete, Instantsearch or Recommend and can not be deleted`,
                'Error'
            )

            setIsModalOpen(false)
            return
        }
        SourceService.delete(
            source.integration_id as string,
            source.id as string
        )
            .then((response) => {
                if (
                    !isEmpty(response.message) &&
                    response.message.includes(
                        'Indexing job is currently running'
                    )
                ) {
                    showErrorAlert(response.message, 'Error')
                    setIsModalOpen(false)
                    return
                }

                showSuccessAlert('Source deleted', 'Success')
                onActionComplete()
            })
            .catch((error) => {
                showErrorAlert(`Failed to delete source - ${error}`, 'Error')
                setIsModalOpen(false)
            })
    }

    function navigateToObservability(taskId?: string): any {
        switch (platform?.platform_type.toLowerCase()) {
            case PLATFORMS.bigcommerce.toLowerCase():
                navigate(
                    `/bigcommerce/${source.integration_id}/channels/${params.channelId}/sources/${source.id}/manage/${taskId}`
                )
                break

            default:
                navigate(
                    `/integrations/${source.integration_id}/sources/${source.id}/manage/${taskId}`
                )
        }
    }

    return (
        <>
            <Modal
                open={isModalOpen}
                title={'Delete source'}
                animate={true}
                onDismiss={(): void => setIsModalOpen(false)}
                centerY={true}
            >
                <ModalSection className="stl-mb-2">
                    <p className="stl-mb-5">
                        Are you sure you want to delete this source?
                    </p>
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-mt-4"
                    >
                        <Button
                            variant="destructive"
                            size="medium"
                            onClick={(): void => {
                                deleteSource()
                            }}
                        >
                            Yes, Delete
                        </Button>
                        <Button
                            variant="neutral"
                            size="medium"
                            onClick={(): void => setIsModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </FlexGrid>
                </ModalSection>
            </Modal>
            <Card
                key={source.id}
                className={stl`mt-6 items-center border-b border-grey-200`}
                fullBleed
            >
                <Card.Header
                    className={stl`p-6 flex items-center border-b border-grey-200`}
                >
                    <Card.Title className="stl-mr-4">
                        <OverflowTooltipWrapper>
                            <div className={stl`max-w-25ch`}>
                                {source.config.indexName
                                    ? source.config.indexName
                                    : ''}
                            </div>
                        </OverflowTooltipWrapper>

                        {integration ? `(${integration.algolia_app_id})` : ''}
                    </Card.Title>

                    <FlexGrid spacing="sm" alignment="center">
                        {showHtml(
                            userAlgoliaApplications,
                            integration,
                            platform
                        ) && (
                            <ReindexButton
                                source={source}
                                isFromAdminView={isFromAdminView}
                                isIconButton={true}
                            />
                        )}
                        <Dropdown renderTarget={renderButton}>
                            <DropdownButtonItem
                                startIcon={Edit}
                                onClick={(): void => {
                                    editSource(source as SourceType)
                                }}
                            >
                                Edit Source
                            </DropdownButtonItem>
                            {showHtml(
                                userAlgoliaApplications,
                                integration,
                                platform
                            ) && (
                                <>
                                    <DropdownButtonItem
                                        toggle={true}
                                        disabled={isFromAdminView()}
                                        startIcon={Trash2}
                                        onClick={(): void => {
                                            setIsModalOpen(true)
                                        }}
                                    >
                                        Delete Source
                                    </DropdownButtonItem>
                                </>
                            )}
                            {showHtml(
                                userAlgoliaApplications,
                                integration,
                                platform
                            ) &&
                                !isAdmin && (
                                    <DropdownButtonItem
                                        startIcon={ExternalLink}
                                        onClick={(): void => {
                                            window.open(
                                                `https://algolia.com/apps/${integration.algolia_app_id}/explorer/configuration/${source.config.indexName}/`,
                                                '_blank'
                                            )
                                        }}
                                    >
                                        Manage Algolia Index
                                    </DropdownButtonItem>
                                )}
                            {isAdmin && (
                                <DropdownButtonItem
                                    startIcon={ExternalLink}
                                    onClick={(): void => {
                                        window.open(
                                            `https://admin.algolia.com/admin/?query=${integration.algolia_app_id}&page=1`,
                                            '_blank'
                                        )
                                    }}
                                >
                                    Manage Algolia Index (Admin)
                                </DropdownButtonItem>
                            )}
                        </Dropdown>
                    </FlexGrid>
                </Card.Header>
                <div className={stl`p-6 space-y-6`}>
                    {integration && (
                        <div>
                            <b>Algolia configuration</b>
                            <table className={stl`w-full mb-4`}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50%' }}>Index:</td>
                                        <td>
                                            <Link
                                                title="View Index"
                                                href={`https://algolia.com/apps/${integration.algolia_app_id}/explorer/browse/${source.config.indexName}/`}
                                            >
                                                <OverflowTooltipWrapper>
                                                    <div
                                                        className={stl`max-w-20ch`}
                                                    >
                                                        {typeof source.config
                                                            ?.indexName !==
                                                        'undefined'
                                                            ? source.config
                                                                  .indexName
                                                            : ''}
                                                    </div>
                                                </OverflowTooltipWrapper>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>App ID:</td>
                                        <td>{integration.algolia_app_id}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b>{integration.platform} configuration</b>
                            <table className={stl`w-full`}>
                                <tbody>
                                    {integration.platform?.toLowerCase() ===
                                        PLATFORMS.bigcommerce.toLowerCase() && (
                                        <>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    Channel Name:
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    {source.config
                                                        .channelName ??
                                                        'unavailable'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    Currencies:
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    {source.config.currencies?.toString()}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <td style={{ width: '50%' }}>
                                            Record Type:
                                        </td>
                                        <td>
                                            {source.config?.recordType ??
                                                RECORD_TYPES.variant}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '50%' }}>
                                            Success Threshold:
                                        </td>
                                        <td>
                                            {source.config?.successThreshold ??
                                                100}
                                            %
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <FlexGrid
                                distribution="trailing"
                                className={stl`text-right mt-4`}
                            >
                                <Button
                                    variant="subtle"
                                    className={stl`mr-4`}
                                    onClick={(): void => {
                                        navigateToObservability(
                                            source.realtime_task_id
                                        )
                                    }}
                                    startIcon={Clock}
                                >
                                    Real-time updates
                                </Button>
                                <Button
                                    variant="subtle"
                                    onClick={(): void => {
                                        navigateToObservability(
                                            source.reindex_task_id
                                        )
                                    }}
                                    startIcon={Server}
                                >
                                    Reindexes
                                </Button>
                            </FlexGrid>
                        </div>
                    )}
                </div>
            </Card>
        </>
    )
}
