import { Navigate } from 'react-router-dom'

import { AlertProvider } from '../components/AlertContext'
import { useAuth } from '../components/AuthContext'
import { HeaderProvider } from '../components/HeaderContext'
import { Loader } from '../components/common/loader'

export function RequireAuth({ children, redirectTo = '/login' }: any): any {
    const { user, platform } = useAuth()

    if (user === undefined && platform === null) {
        return (
            <>
                <Loader />
            </>
        )
    }

    return user || platform ? (
        <HeaderProvider>
            <AlertProvider>{children}</AlertProvider>
        </HeaderProvider>
    ) : (
        <Navigate to={redirectTo} />
    )
}
