import type { IndexDataResponse } from '../../types/algolia/indexDataResponse.type'
import type { SourceType } from '../../types/database/source.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const SourceService = {
    async create(source: SourceType, integrationId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source`,
            REQUEST_TYPES.post,
            JSON.stringify(source)
        )
    },
    async update(source: SourceType, integrationId: string): Promise<any> {
        // eslint-disable-next-line no-param-reassign
        delete source.config.recordType

        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${source.id}`,
            REQUEST_TYPES.put,
            JSON.stringify(source)
        )
    },
    async get(integrationId: string, sourceId: string): Promise<SourceType> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${sourceId}`,
            REQUEST_TYPES.get
        )
    },
    async reindex(integrationId: string, sourceId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/reindex/${sourceId}`,
            REQUEST_TYPES.post
        )
    },
    async delete(integrationId: string, sourceId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${sourceId}`,
            REQUEST_TYPES.delete
        )
    },
    async getIndexData(
        integrationId: string,
        sourceId: string
    ): Promise<IndexDataResponse> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${sourceId}/index-data`,
            REQUEST_TYPES.get
        )
    },
}

export default SourceService
