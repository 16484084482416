// Stores the JWT token
let token: string | null = null

export function setToken(newToken: string): void {
    token = newToken
}

export function getToken(): string | null {
    return token
}

export function clearToken(): void {
    token = null
}
