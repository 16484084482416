import * as Sentry from '@sentry/react'
import React from 'react'
import '@algolia/satellite/satellite.min.css'
import './assets/css/App.css'
import type { FallbackProps } from 'react-error-boundary'
import { ErrorBoundary } from 'react-error-boundary'

import { AuthProvider } from './components/AuthContext'
import { NotificationBanner } from './components/common/notificationBanner'
import ErrorBoundaryHtml from './components/errorBoundary/errorBoundaryHtml'
import { Router } from './routes'

const fallbackRender = ({ error }: FallbackProps): React.ReactNode => {
    Sentry.captureException(error)
    return <ErrorBoundaryHtml />
}

const App: React.FunctionComponent = () => {
    return (
        <div className="app stl-bg-grey-50">
            <AuthProvider>
                <ErrorBoundary fallbackRender={fallbackRender}>
                    <NotificationBanner />
                    <Router />
                </ErrorBoundary>
            </AuthProvider>
        </div>
    )
}

export default Sentry.withProfiler(App)
