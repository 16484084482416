import EntityService from '../services/theming/entitiesService'
import type { CategoriesPatch } from '../types/bigCommerce/categories.type'
import type { Entity } from '../types/database/entities.type'

const MAX_PAYLOAD_SIZE = 100 * 1024

function calculateEntrySize(entry: [string, any]): number {
    return new TextEncoder().encode(JSON.stringify(entry)).length
}

export function chunkEntriesByPayloadSize(
    entries: Array<[string, any]>,
    maxSize: number = MAX_PAYLOAD_SIZE
): Array<Array<[string, any]>> {
    const chunks: Array<Array<[string, any]>> = []
    let currentChunk: Array<[string, any]> = []
    let currentSize = 0

    for (const entry of entries) {
        const entrySize = calculateEntrySize(entry)
        if (currentSize + entrySize > maxSize) {
            chunks.push(currentChunk)
            currentChunk = []
            currentSize = 0
        }
        currentChunk.push(entry)
        currentSize += entrySize
    }

    if (currentChunk.length > 0) {
        chunks.push(currentChunk)
    }

    return chunks
}

export async function processCategoryChunks(
    chunkedCategoryIds: any[],
    currentIntegrationId: string,
    entityResponse: Entity,
    deletedCategories: number[] = []
): Promise<Entity> {
    let index = 0
    let categoryResponse: Entity = entityResponse

    for (const categoryChunk of chunkedCategoryIds) {
        const chunkedCategories: CategoriesPatch = {
            config: {
                category_ids: Object.fromEntries(categoryChunk),
            },
        }

        if (deletedCategories.length > 0) {
            chunkedCategories.removed = deletedCategories
        }

        if (index === chunkedCategoryIds.length - 1) {
            chunkedCategories.last_chunk = true
        }

        categoryResponse = await EntityService.patchEntity(
            currentIntegrationId,
            categoryResponse,
            chunkedCategories
        )

        // Reset deleted categories since we sent it
        // eslint-disable-next-line no-param-reassign
        deletedCategories = []

        index++
    }

    return categoryResponse
}
