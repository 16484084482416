import {
    CardTitle,
    DropdownDivider,
    FlexGrid,
    stl,
    Toggle,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import BigCommerceService from '../../services/bigCommerce/bigCommerceService'
import { APP_EXTENSION_MODEL_TYPE } from '../../types/bigCommerce/appExtensionModelTypes'
import type { Settings } from '../../types/settings.type'
import { PLATFORMS } from '../../utils/platforms'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'

interface Props {
    settings: Settings
    setSettings: (
        settings: Settings | ((prevSettings: Settings) => Settings)
    ) => void
}

export const AppExtensionsSettings: FunctionComponent<Props> = (
    props: Props
): any => {
    const { settings, setSettings } = props
    const { platform } = useAuth()
    const { showErrorAlert, showSuccessAlert } = useAlert()

    const toggleExtension = async (
        extension: string,
        value: boolean,
        type: string
    ): Promise<void> => {
        try {
            const response = await BigCommerceService.toggleAppExtenstion(
                type,
                value
            )
            if (!response) {
                showErrorAlert('Failed to toggle app extension', 'Error')
                return
            }

            setSettings((prevSettings: Settings) => ({
                ...prevSettings,
                [extension]: value,
            }))

            showSuccessAlert('Successfully toggled app extension')
        } catch (error) {
            showErrorAlert('Failed to toggle app extension', 'Error')
        }
    }

    return (
        <>
            {platform.platform_type === PLATFORMS.bigcommerce && (
                <>
                    <CardTitle
                        className={`stl-px-4 stl-mt-4 stl-mb-2 settings-title`}
                    >
                        App Extensions Settings
                    </CardTitle>

                    <DropdownDivider />

                    <FlexGrid
                        className={`stl-w-full stl-p-6`}
                        direction="column"
                        spacing="lg"
                    >
                        <FlexGrid className={stl`w-full`}>
                            <p className="settings-body-item">
                                Enable Sync with Algolia on your BigCommerce
                                admins product list and product view pages to
                                index a single product
                            </p>
                        </FlexGrid>
                        <FlexGrid
                            className={`stl-w-full  settings-body-item`}
                            direction="column"
                            spacing="lg"
                        >
                            <table className={stl`w-full`}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '30%' }}>
                                            Product List
                                        </td>
                                        <td>
                                            <Toggle
                                                checked={
                                                    settings.productListAppExtension ??
                                                    false
                                                }
                                                onClick={(): void => {
                                                    toggleExtension(
                                                        'productListAppExtension',
                                                        !settings.productListAppExtension,
                                                        APP_EXTENSION_MODEL_TYPE.PRODUCTS
                                                    )
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>
                                            Product Description
                                        </td>
                                        <td>
                                            <Toggle
                                                checked={
                                                    settings.productDescriptionAppExtension ??
                                                    false
                                                }
                                                onClick={(): void => {
                                                    toggleExtension(
                                                        'productDescriptionAppExtension',
                                                        !settings.productDescriptionAppExtension,
                                                        APP_EXTENSION_MODEL_TYPE.PRODUCT_DESCRIPTION
                                                    )
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </FlexGrid>
                    </FlexGrid>
                </>
            )}
        </>
    )
}
