import type { ReactNode } from 'react'
import { createContext, useContext, useState, useEffect } from 'react'

import getPlatform from '../utils/getPlatform'
import getUser from '../utils/getUser'
import { getToken, setToken } from '../utils/tokenManager'
import { checkAtob, isEmpty } from '../utils/utils'

declare global {
    interface Window {
        appexJwt?: string | null
    }
}

export type AuthContextType = {
    user: any
    setUser: (user: any) => void
    platform: any
    isPlatformAuthed: any
    setIsPlatformAuthed: (isAuthed: boolean) => void
    setPlatform: any
    isAdmin: boolean
    appexJwt?: string | null
    setAppexJwt: (token: string) => void
    jwtUser: any
    jwtPlatform: any
}

export const AuthContext = createContext<AuthContextType>({
    user: undefined,
    setUser: (): void => {},
    platform: undefined,
    isPlatformAuthed: false,
    isAdmin: false,
    setIsPlatformAuthed: (): void => {},
    setPlatform: (): void => {},
    appexJwt: undefined,
    setAppexJwt: (): void => {},
    jwtUser: undefined,
    jwtPlatform: undefined,
})

export function useAuth(): AuthContextType {
    return useContext(AuthContext)
}

type Props = {
    children: ReactNode
}

type Platform = {
    id: string
    platform_id: string
    integration_id: string
    integrations: Record<string, unknown>
}

export function AuthProvider({ children }: Props): JSX.Element {
    const [isLoading, setIsLoading] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)
    const [user, setUser] = useState(undefined)
    const [platform, setPlatform] = useState<Platform | null>()
    const [isPlatformAuthed, setIsPlatformAuthed] = useState(false)
    const [appexJwt, setAppexJwt] = useState<string | null>()
    const [jwtPlatform, setJwtPlatform] = useState()
    const [jwtUser, setJwtUser] = useState()

    async function fetchData(): Promise<any> {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token') as string
        if (isEmpty(getToken()) && !isEmpty(token)) {
            setToken(token)
            setAppexJwt(token)
        }

        setUser(await getUser())
        setPlatform(await getPlatform())
    }

    useEffect(() => {
        if (!user) {
            return
        }
        // @ts-expect-error
        setIsAdmin(user?.email?.endsWith('@algolia.com'))
    }, [user])

    useEffect(() => {
        if (!isEmpty(appexJwt) && appexJwt) {
            const jwtParts = appexJwt.split('.')
            if (jwtParts.length > 1 && checkAtob(jwtParts[1])) {
                const decodedToken = JSON.parse(atob(jwtParts[1]))
                if (!isEmpty(decodedToken.currentPlatform)) {
                    setJwtPlatform(decodedToken.currentPlatform)
                }

                if (!isEmpty(decodedToken.currentUser)) {
                    setJwtUser(decodedToken.currentUser)
                }
            }
        }
    }, [appexJwt])

    useEffect(() => {
        setIsLoading(true)
        fetchData().finally(() => {
            setIsLoading(false)
        })
    }, [])

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                platform,
                setPlatform,
                isPlatformAuthed,
                setIsPlatformAuthed,
                isAdmin,
                appexJwt,
                setAppexJwt,
                jwtUser,
                jwtPlatform,
            }}
        >
            {!isLoading && children}
        </AuthContext.Provider>
    )
}
