import type {
    Pagination as PaginationType,
    GetEventsProps,
    IngestionClient,
    Run,
    Event,
    RunListResponse,
} from '@algolia/ingestion'
import type {
    BadgeVariants,
    ContentTab,
    DateRangePickerProps,
} from '@algolia/satellite'
import {
    Pagination,
    stl,
    ProgressBar,
    IconButton,
    Badge,
    Card,
    CardHeader,
    CardTitle,
    FlexGrid,
    ContentTabs,
} from '@algolia/satellite'
import type { FunctionComponent, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { RefreshCw } from 'react-feather'

import formatDateTime from '../../../utils/DateFormatter'
import { isEmpty } from '../../../utils/utils'
import { DateRange } from '../dateRange/dateRange'

import { EventCard } from './eventCard'

type ObservabilityProps = {
    ingestionClient: IngestionClient
    run: Run
    setRun: (run: Run) => void
    runList: RunListResponse | undefined
    setRunList: (runList: RunListResponse) => void
    handleBadge: (status: string, outcome?: string) => BadgeVariants | undefined
}

export const RunDetail: FunctionComponent<ObservabilityProps> = (
    props: ObservabilityProps
) => {
    const [progress, setProgress] = useState(0)
    const [expected, setExpected] = useState<number | null>()
    const [received, setReceived] = useState<number | null>()
    const { run, setRun, runList, setRunList, ingestionClient, handleBadge } =
        props
    const [eventList, setEventList] = useState<Event[]>()
    const [errors, setErrors] = useState<Event[]>()
    const [activeTab, setActiveTab] = useState(0)
    const [pagination, setPagination] = useState<PaginationType>({
        itemsPerPage: 10,
        nbItems: 0,
        nbPages: 0,
        page: 1,
    })
    const [errorPagination, setErrorPagination] = useState<PaginationType>({
        itemsPerPage: 10,
        nbItems: 0,
        nbPages: 0,
        page: 1,
    })

    const dateTime = new Date(new Date().setHours(23, 59, 59, 999))
    const initialDate = {
        start: new Date(run.createdAt),
        end: !isEmpty(run.finishedAt)
            ? new Date(run.finishedAt ?? '')
            : dateTime,
    }
    const [dateRange, setDateRange] =
        useState<DateRangePickerProps['range']>(initialDate)

    useEffect(() => {
        setRun(props.run)
    }, [props.run])

    async function fetchEvents(
        page: number = 1,
        itemsPerPage: number = 10
    ): Promise<any> {
        const eventProps: GetEventsProps = {
            runID: run.runID,
            startDate: dateRange?.start?.toISOString(),
            endDate: dateRange?.end?.toISOString(),
        }
        const requestOptions = {
            queryParameters: {
                page,
                itemsPerPage,
            },
        }

        const events = await ingestionClient.getEvents(
            eventProps,
            requestOptions
        )

        setEventList(events.events)
        setPagination(events.pagination)
    }

    async function fetchErrors(
        page: number = 1,
        itemsPerPage: number = 10
    ): Promise<any> {
        const eventProps: GetEventsProps = {
            runID: run.runID,
            startDate: dateRange?.start?.toISOString(),
            endDate: dateRange?.end?.toISOString(),
            status: ['failed'],
        }
        const requestOptions = {
            queryParameters: {
                page,
                itemsPerPage,
            },
        }
        const errorList = await ingestionClient.getEvents(
            eventProps,
            requestOptions
        )
        setErrors(errorList.events)
        setErrorPagination(errorList.pagination)
    }

    function calculateRunPercentage(): number {
        const receivedEvents = run.progress?.receivedNbOfEvents ?? 0
        const expectedEvents = run.progress?.expectedNbOfEvents ?? 0
        setReceived(run?.progress?.receivedNbOfEvents)
        setExpected(run.progress?.expectedNbOfEvents)
        return (receivedEvents / expectedEvents) * 100
    }

    async function refetchRunSetProgress(): Promise<void> {
        const currentRun = await ingestionClient.getRun({ runID: run.runID })
        setRun(currentRun)
        setProgress(calculateRunPercentage())
        if (typeof runList !== 'undefined') {
            const newRuns = runList?.runs.filter((r: Run) => {
                if (r.runID === currentRun.runID) {
                    // eslint-disable-next-line no-param-reassign
                    r.status = currentRun.status
                    return r
                }
                return r
            })
            runList.runs = newRuns
            setRunList(runList)
        }
    }

    const EventLabels: ReactNode = (
        <Card fullBleed className="stl-p-2" style={{ width: '98%' }}>
            <CardHeader>
                <FlexGrid
                    alignment="center"
                    spacing="sm"
                    distribution="equalSpacing"
                    className="stl-w-full"
                >
                    <FlexGrid alignment="center">
                        <span
                            className="stl-inline-flex stl-items-center stl-justify-center stl-px-1"
                            style={{
                                width: '87px',
                                marginRight: '20px',
                            }}
                        >
                            Status
                        </span>
                        <span className="stl-capitalize">Type</span>
                    </FlexGrid>
                    <FlexGrid
                        alignment="center"
                        spacing="sm"
                        distribution="fill"
                    >
                        <div style={{ minWidth: '200px' }}>
                            <span>Published At (UTC)</span>
                        </div>
                    </FlexGrid>
                </FlexGrid>
            </CardHeader>
        </Card>
    )

    const EventsTab: ReactNode = (
        <>
            <FlexGrid
                direction="column"
                className="stl-bg-grey-100 stl-p-2"
                spacing="sm"
                alignment="center"
            >
                {EventLabels}

                {eventList?.map((event: Event) => {
                    return (
                        <EventCard
                            event={event}
                            handleBadge={handleBadge}
                            key={event.eventID}
                        />
                    )
                })}
                {pagination?.nbPages > 0 && (
                    <Pagination
                        onChange={(page: number): void => {
                            fetchEvents(page)
                        }}
                        currentPage={pagination?.page || 0}
                        nbPages={pagination?.nbPages || 0}
                        maxButtons={4}
                    />
                )}
            </FlexGrid>
        </>
    )

    const ErrorsTab: ReactNode = (
        <>
            <FlexGrid
                direction="column"
                className="stl-bg-grey-100 stl-p-2"
                spacing="sm"
                alignment="center"
            >
                {EventLabels}

                {errors?.map((event: Event) => {
                    return (
                        <EventCard
                            event={event}
                            handleBadge={handleBadge}
                            key={`error-${event.eventID}`}
                        />
                    )
                })}
                {errorPagination?.nbPages > 0 && (
                    <Pagination
                        onChange={(page: number): void => {
                            fetchErrors(page)
                        }}
                        currentPage={errorPagination?.page || 0}
                        nbPages={errorPagination?.nbPages || 0}
                        maxButtons={6}
                    />
                )}
            </FlexGrid>
        </>
    )

    const tabs: ContentTab[] = [
        {
            label: 'Events',
            content: EventsTab,
        },
        {
            label: `Errors`,
            content: ErrorsTab,
        },
    ]

    useEffect(() => {
        setProgress(calculateRunPercentage())
        if (tabs[activeTab].label === 'Events') {
            fetchEvents(pagination.page)
        } else if (tabs[activeTab].label === 'Errors') {
            fetchErrors(errorPagination.page)
        }

        let interval: NodeJS.Timeout
        const handleVisibilityChange = (): void => {
            if (document.hidden) {
                clearInterval(interval)
            } else {
                interval = setInterval(() => {
                    refetchRunSetProgress()
                }, 5000)
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        if (
            isEmpty(run.outcome) &&
            initialDate.start.toISOString() ===
                dateRange?.start?.toISOString() &&
            initialDate.end.toISOString() === dateRange?.end?.toISOString()
        ) {
            interval = setInterval(() => {
                refetchRunSetProgress()
            }, 5000) // 5 secs
        }

        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
            clearInterval(interval)
        }
    }, [run, activeTab, dateRange])

    useEffect(() => {
        setDateRange({
            start: new Date(run.createdAt),
            end: !isEmpty(run.finishedAt)
                ? new Date(run.finishedAt ?? '')
                : dateTime,
        })
    }, [run])

    return (
        <Card style={{ width: '500px' }} fullBleed>
            <CardHeader className="stl-px-6 stl-py-2 stl-border-b stl-border-grey-200">
                <CardTitle
                    className="stl-display-body"
                    style={{ alignSelf: 'center' }}
                >
                    Run Details
                </CardTitle>
                <DateRange
                    minDateTime={new Date(run.createdAt)}
                    maxDateTime={
                        !isEmpty(run.finishedAt)
                            ? new Date(run.finishedAt as string)
                            : undefined
                    }
                    rightPanelType="timeRangePanel"
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
                <IconButton
                    icon={RefreshCw}
                    title="Refresh"
                    variant="subtle"
                    onClick={(): void => {
                        fetchEvents()
                        refetchRunSetProgress()
                    }}
                />
            </CardHeader>

            <FlexGrid direction="column" className="stl-p-6 stl-space-y-6">
                <div className="stl-w-full stl-flex stl-justify-between">
                    <span>Run ID:</span> <span>{run?.runID}</span>
                </div>
                <div className="stl-w-full stl-flex stl-justify-between">
                    <span>Task ID:</span>
                    <span>{run?.taskID}</span>
                </div>
                <div className="stl-w-full stl-flex stl-justify-between">
                    <div className="stl-w-full stl-flex">
                        <span>Status: </span>

                        <Badge
                            size="small"
                            variant={handleBadge(
                                run.status,
                                run.outcome as string
                            )}
                            className="stl-ml-2 stl-capitalize"
                        >
                            {run.status}
                        </Badge>
                    </div>
                    {run?.outcome !== null && (
                        <div className="stl-w-full stl-flex stl-justify-end">
                            <span>Outcome: </span>
                            <Badge
                                size="small"
                                variant={handleBadge(run.outcome as string)}
                                className="stl-ml-2 stl-capitalize"
                            >
                                {run.outcome}
                            </Badge>
                        </div>
                    )}
                </div>
                <div className="stl-w-full stl-flex stl-justify-between">
                    <span>Created at:</span>{' '}
                    <span>
                        {formatDateTime(run?.createdAt, {
                            timeZoneName: 'short',
                        })}{' '}
                    </span>
                </div>
                <div className="stl-w-full stl-flex stl-justify-between">
                    <span>Finished at:</span>{' '}
                    <span>
                        {formatDateTime(run?.finishedAt, {
                            timeZoneName: 'short',
                        })}{' '}
                    </span>
                </div>
            </FlexGrid>

            {received !== expected &&
                received !== null &&
                expected !== null &&
                expected !== 1 &&
                isEmpty(run.outcome) && (
                    <div className="stl-text-center">
                        <span className="stl-text-center">Index Progress </span>
                        <FlexGrid
                            direction="column"
                            className="stl-p-6 stl-space-y-6"
                        >
                            <div
                                className={stl`flex flex-col justify-center w-full`}
                            >
                                <p
                                    className={stl`display-caption flex justify-between mb-2`}
                                >
                                    <span>0%</span>
                                    <span>25%</span>
                                    <span>50%</span>
                                    <span>75%</span>
                                    <span>100%</span>
                                </p>
                                <ProgressBar
                                    value={progress}
                                    aria-label="Progress"
                                />
                            </div>
                        </FlexGrid>
                        <span className="stl-text-center">
                            {`${received} received / ${expected} expected (${progress.toFixed(
                                0
                            )}%)`}
                        </span>
                    </div>
                )}

            <ContentTabs
                tabs={tabs}
                onChange={(tabIndex: number): void => {
                    setActiveTab(tabIndex)
                }}
                className="stl-p-2 stl-border-b stl-border-grey-200"
            />
        </Card>
    )
}
