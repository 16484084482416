import {
    DateRangePickerActionTypes,
    Button,
    stl,
    FlexGrid,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { Calendar, RotateCcw } from 'react-feather'

import type { RightSidePanelComponentArgs } from './dateRange'

type Props = {
    panelArgs: RightSidePanelComponentArgs
}

export const DateRangePanel: FunctionComponent<Props> = (props): any => {
    const { panelArgs } = props
    const now = new Date()

    function createStartDate(numDays: number): Date {
        const date = new Date()
        const startDate = new Date(
            date.getTime() - numDays * 24 * 60 * 60 * 1000
        )
        startDate.setHours(0, 0, 0, 0)
        return startDate
    }

    return (
        <div className={stl`flex flex-col items-start space-y-2`}>
            <Button
                variant="subtle"
                startIcon={Calendar}
                className={stl`inline-flex`}
                onClick={(): void => {
                    panelArgs.dispatch({
                        type: DateRangePickerActionTypes.rangeSelect,
                        payload: {
                            start: createStartDate(0),
                            end: now,
                        },
                    })
                }}
            >
                Today
            </Button>
            <Button
                variant="subtle"
                startIcon={RotateCcw}
                className={stl`inline-flex`}
                onClick={(): void => {
                    panelArgs.dispatch({
                        type: DateRangePickerActionTypes.rangeSelect,
                        payload: {
                            start: createStartDate(7),
                            end: now,
                        },
                    })
                }}
            >
                Last week
            </Button>

            <FlexGrid direction="column" spacing="sm" alignment="leading">
                <span style={{ paddingTop: '150px', fontSize: '10px' }}>
                    <p>Max selected date range allowed is 1 week</p>
                    <p>Events are stored for 30 days</p>
                </span>
            </FlexGrid>
        </div>
    )
}
