import { Card, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import ConnectionsService from '../../../services/connections/connectionsService'
import SourceService from '../../../services/database/sourceService'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { SourceType } from '../../../types/database/source.type'
import { PLATFORMS } from '../../../utils/platforms'
import { isEmpty } from '../../../utils/utils'
import { useAlert } from '../../AlertContext'
import { useAuth } from '../../AuthContext'
import { SourceForm } from '../../bigcommerce/sourceForm'
import { AlgoliaLoginVerify } from '../AlgoliaLoginVerify'

import { ManageAttributesToExclude } from './manageAttributesToExclude'
import { ManageCustomFields } from './manageCustomFields'
import { ManageMetafields } from './manageMetafields'
import { ManageSidebar } from './manageSidebar'

type Props = {
    channelId: string
    integration: IntegrationType
    sourceId?: string
    setShowBcSetup: (show: boolean) => void
}

export const ManageSource: FunctionComponent<Props> = (props: Props): any => {
    const { channelId, integration, sourceId, setShowBcSetup } = props
    const { showErrorAlert, showSuccessAlert } = useAlert()
    const { setIsPlatformAuthed } = useAuth()

    const [activeView, setActiveView] = useState('settings')
    const [source, setSource] = useState<SourceType | undefined>()
    const [loading, setLoading] = useState(false)

    const updateSource = (src: SourceType): void => {
        setLoading(true)
        const sanitisedSource = src
        if (src.custom_fields) {
            sanitisedSource.custom_fields = trimSpaces(src.custom_fields)
        }
        if (src.attributes_to_exclude) {
            sanitisedSource.attributes_to_exclude = trimSpaces(
                src.attributes_to_exclude
            )
        }
        SourceService.update(sanitisedSource, integration.id as string)
            .then((response) => {
                setSource(response)
                ConnectionsService.sourceUpdate(
                    integration.id as string,
                    response.id
                )
                    .then(() => {
                        setLoading(false)
                        showSuccessAlert('Source has been updated')
                    })
                    .catch(() => {
                        setLoading(false)
                        showErrorAlert(
                            'Error updating source with connections',
                            'Error'
                        )
                    })
            })
            .catch((err) => {
                if (err.includes('User does not have access')) {
                    setIsPlatformAuthed(false)
                    showErrorAlert(err, 'Error')
                } else {
                    showErrorAlert('Error updating source', 'Error')
                }
                setLoading(false)
            })
    }

    function trimSpaces(str: string): string | undefined {
        const array = str.split(',')
        const trimmedArray = array.map((item) => item.trim())
        return trimmedArray.join(',')
    }

    const fetchSource = async (): Promise<void> => {
        setSource(
            await SourceService.get(
                integration.id as string,
                sourceId as string
            )
        )
    }

    useEffect(() => {
        if (typeof sourceId !== 'undefined') {
            fetchSource()
        }
    }, [sourceId])

    return (
        <>
            <FlexGrid className={stl`w-full px-4 mb-4`} distribution="fill">
                <FlexGrid
                    className={stl`min-h-screen bg-grey-50`}
                    direction="row"
                    alignment="leading"
                    spacing="md"
                >
                    <FlexGrid
                        className={stl`min-h-screen bg-grey-50 pl-5`}
                        direction="column"
                        alignment="leading"
                        spacing="md"
                    >
                        <ManageSidebar
                            source={source}
                            activeView={activeView}
                            setActiveView={setActiveView}
                            isNew={isEmpty(source)}
                        />
                    </FlexGrid>

                    <FlexGrid
                        className={stl`min-h-screen bg-grey-50`}
                        direction="column"
                        alignment="trailing"
                        spacing="md"
                        wrap={true}
                    >
                        {integration?.platform.toLowerCase() ===
                            PLATFORMS.bigcommerce.toLowerCase() &&
                            activeView === 'settings' && (
                                <Card fullBleed>
                                    <AlgoliaLoginVerify />
                                    <FlexGrid
                                        direction="column"
                                        alignment="center"
                                        spacing="md"
                                        className={stl`p-6`}
                                    >
                                        <h1 className="stl-display-heading stl-text-grey-900">
                                            Configure your Algolia application
                                        </h1>

                                        <FlexGrid
                                            className="stl-w-25 stl-w-full stl-mb-10 stl-p-2"
                                            direction="column"
                                            alignment="center"
                                            spacing="md"
                                        >
                                            <SourceForm
                                                integration={integration}
                                                source={source}
                                                channelId={Number(channelId)}
                                                setSource={setSource}
                                                updateSource={updateSource}
                                                loading={loading}
                                                setLoading={setLoading}
                                                setShowBcSetup={setShowBcSetup}
                                                setShowIndexStep={(): void => {}}
                                            />
                                        </FlexGrid>
                                    </FlexGrid>
                                </Card>
                            )}
                        {activeView === 'customFields' && (
                            <ManageCustomFields
                                loading={loading}
                                integrationId={integration.id as string}
                                source={source as SourceType}
                                updateSource={updateSource}
                                setShowBcSetup={setShowBcSetup}
                            />
                        )}
                        {activeView === 'metafields' && (
                            <ManageMetafields
                                loading={loading}
                                integrationId={integration.id as string}
                                source={source as SourceType}
                                setShowBcSetup={setShowBcSetup}
                            />
                        )}
                        {activeView === 'attributesToExclude' && (
                            <ManageAttributesToExclude
                                loading={loading}
                                integrationId={integration.id as string}
                                source={source as SourceType}
                                updateSource={updateSource}
                                setShowBcSetup={setShowBcSetup}
                            />
                        )}
                    </FlexGrid>
                </FlexGrid>
            </FlexGrid>
        </>
    )
}
