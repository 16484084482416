import { Select } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import BigCommerceService from '../../services/bigCommerce/bigCommerceService'
import type { BcRegionsResponse } from '../../types/bigCommerce/regionsResponse.type'
import { useAuth } from '../AuthContext'

type Props = {
    channelId: number
    templateFile?: string
    setSelectedPlacementRegion: (placement_region: string) => void
    selectedPlacementRegion?: string
    disabled?: boolean
}
export const RegionsDropdown: FunctionComponent<Props> = (props) => {
    const { platform } = useAuth()
    const {
        channelId,
        templateFile,
        setSelectedPlacementRegion,
        selectedPlacementRegion,
        disabled,
    } = props
    const [placementRegionsData, setPlacementRegionsData] =
        useState<BcRegionsResponse>()

    async function fetchRegions(): Promise<void> {
        if (typeof channelId === 'undefined') {
            return
        }

        const regionsData = await BigCommerceService.fetchRegions(
            channelId,
            templateFile
        )
        if (regionsData) {
            setPlacementRegionsData(regionsData)
        }
    }

    useEffect(() => {
        if (platform !== null) {
            fetchRegions()
        } else {
            setPlacementRegionsData([
                {
                    name: selectedPlacementRegion ?? 'header_bottom--global',
                },
            ])
        }
    }, [templateFile, selectedPlacementRegion])

    return (
        <>
            <Select
                className="stl-w-400"
                onChange={(event: any): void => {
                    if (event.target.selectedIndex === 0) {
                        setSelectedPlacementRegion('')
                    } else {
                        setSelectedPlacementRegion(event.target.value)
                    }
                }}
                value={selectedPlacementRegion}
                disabled={disabled}
            >
                <option>Select a placement region</option>
                {placementRegionsData?.map((region: { name: string }) => (
                    <option key={region?.name} value={region?.name}>
                        {region?.name}
                    </option>
                ))}
            </Select>
        </>
    )
}
