import type { Settings } from '../../types/settings.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const SettingsService = {
    async fetchSettings(): Promise<Settings> {
        return await makeRequest(`/api/v1/settings`, REQUEST_TYPES.get)
    },
    async toggleAppExtension(extension: string, value: boolean): Promise<void> {
        return await makeRequest(
            `/api/v1/settings/app-extension`,
            REQUEST_TYPES.put,
            JSON.stringify({
                [extension]: value,
            })
        )
    },
}

export default SettingsService
