import { Button, Card, EmptyState, FlexGrid, stl } from '@algolia/satellite'

export const IntegrationEmptyState: React.FunctionComponent = () => {
    return (
        <>
            <div
                style={{ paddingTop: '200px' }}
                className="stl-w-full stl-h-full stl-flex stl-justify-center stl-min-h-screen stl-p-6"
            >
                <FlexGrid
                    className="login-container"
                    direction="column"
                    alignment="center"
                    spacing="xl"
                    distribution="fill"
                >
                    <FlexGrid
                        direction="column"
                        spacing="md"
                        alignment="center"
                        className={stl`w-128`}
                    >
                        <FlexGrid distribution="center" className={stl`p-10`}>
                            <Card className={stl`mb-8 px-32 py-16 p-16`}>
                                <EmptyState
                                    title={<span>No integrations found</span>}
                                    description="Install the Algolia BigCommerce app to get started"
                                    actions={
                                        <Button
                                            className="stl-xenon-button"
                                            onClick={(): void => {
                                                window.open(
                                                    `https://www.bigcommerce.com/apps/algolia-search-discovery/`,
                                                    '_blank'
                                                )
                                            }}
                                        >
                                            Install Algolia BigCommerce App
                                        </Button>
                                    }
                                    centered
                                    usageContext="feature"
                                />
                            </Card>
                        </FlexGrid>
                    </FlexGrid>
                </FlexGrid>
            </div>
        </>
    )
}
