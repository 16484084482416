import { Button, FlexGrid, Modal, ModalSection, stl } from '@algolia/satellite'
import * as Sentry from '@sentry/react'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Plus, Trash2 } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'

import bigcommerce from '../../../assets/images/bigcommerce.svg'
import BigCommerceService from '../../../services/bigCommerce/bigCommerceService'
import IntegrationService from '../../../services/database/integrationService'
import type { BcChannel } from '../../../types/bigCommerce/channelsRespone.type'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { SourceType } from '../../../types/database/source.type'
import getAlgoliaApplicationsList from '../../../utils/getAlgoliaApplicationsList'
import { PLATFORMS } from '../../../utils/platforms'
import showHtml from '../../../utils/showHtml'
import { isEmpty } from '../../../utils/utils'
import { useAlert } from '../../AlertContext'
import { useAuth } from '../../AuthContext'
import { ChannelDetail } from '../../bigcommerce/channelDetail'
import { Loader } from '../loader'

import { StoreUrl } from './storeUrl'

export const Sources: FunctionComponent = (): any => {
    const { platform, setPlatform, isAdmin, appexJwt } = useAuth()
    const { showErrorAlert, showSuccessAlert } = useAlert()
    const navigate = useNavigate()
    const params = useParams()
    const { channelId, integrationId } = params
    const [integration, setIntegration] = useState<IntegrationType | null>()
    const [sources, setSources] = useState<SourceType[] | [] | [SourceType]>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [applications, setApplications] = useState<string[]>([])
    const [channel, setChannel] = useState<BcChannel | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const [showBcSetup, setShowBcSetup] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [selectedSourceId, setSelectedSourceId] = useState<
        string | undefined
    >()

    async function fetchChannel(): Promise<any> {
        const channelsList = await BigCommerceService.fetchChannels()
        const channelData = channelsList.data.filter(
            (activeChannel: BcChannel) =>
                activeChannel.id.toString() === channelId
        )
        setChannel(channelData[0])
    }

    function redirectToPlaformSetup(): any {
        switch (platform.platform_type.toLowerCase()) {
            case PLATFORMS.bigcommerce.toLowerCase():
                platform.integration_id = null
                setPlatform(platform)
                navigate('/bigcommerce/setup')
                break

            default:
        }
    }

    function isFromAdminView(): boolean {
        if (
            integration?.platform === PLATFORMS.bigcommerce &&
            isAdmin &&
            (isEmpty(platform) || isEmpty(appexJwt))
        ) {
            return true
        }

        return false
    }

    async function fetchIntegration(): Promise<any> {
        try {
            const activeIntegration = await IntegrationService.fetch(
                integrationId as string
            )
            setIntegration(activeIntegration)
            setSources(activeIntegration?.sources)
        } catch (err) {
            Sentry.captureException(err)
            switch (integration?.platform.toLowerCase()) {
                case PLATFORMS.bigcommerce.toLowerCase():
                    redirectToPlaformSetup()
                    break
                default:
                    throw new Error("Platform doesn't exist")
            }
        }
    }

    useEffect(() => {
        if (typeof integrationId !== 'undefined') {
            fetchIntegration()
        }
        if (channelId) {
            fetchChannel()
        }
    }, [integrationId, showBcSetup])

    function editSource(src: SourceType): void {
        switch (integration?.platform.toLowerCase()) {
            case PLATFORMS.bigcommerce.toLowerCase():
                setShowBcSetup(true)
                setSelectedSourceId(src.id)
                break
            default:
                throw new Error("Platform doesn't exist")
        }
    }

    function createNewSource(): any {
        setActiveTab(0)
        switch (integration?.platform.toLowerCase()) {
            case PLATFORMS.bigcommerce.toLowerCase():
                setShowBcSetup(true)
                setSelectedSourceId(undefined)
                return null
            default:
                throw new Error("Platform doesn't exist")
        }
    }

    function deleteIntegration(): any {
        if (integration?.id === null) {
            return
        }

        IntegrationService.delete(integration?.id as string)
            .then(() => {
                setIsLoading(false)
                setIsModalOpen(false)
                showSuccessAlert(
                    `Deleted ${integration?.name} integration`,
                    'Success'
                )
                switch (integration?.platform.toLowerCase()) {
                    case PLATFORMS.bigcommerce.toLowerCase():
                        window.location.href = `/bigcommerce/setup?token=${appexJwt}`
                        break
                    default:
                        throw new Error("Platform doesn't exist")
                }
            })
            .catch(() => {
                setIsLoading(false)
                showErrorAlert(
                    `Failed to delete ${integration?.name} integration`,
                    'Error'
                )
            })
    }

    async function deleteIntegrationButtonHandler(): Promise<any> {
        await fetchIntegration()
        setIsModalOpen(true)
    }

    const logos: Record<string, string> = {
        bigcommerce,
    }

    useEffect(() => {
        const getApplications = async (): Promise<any> => {
            const apps = await getAlgoliaApplicationsList()
            setApplications(apps)
        }

        getApplications()
    }, [])

    return (
        <>
            {!integration ||
                (applications.length === 0 && (
                    <>
                        <Loader message={'Fetching sources...'} />
                    </>
                ))}
            <div className="stl-app-bg">
                <Modal
                    open={isModalOpen}
                    title={'Delete integration'}
                    animate={true}
                    onDismiss={(): void => setIsModalOpen(false)}
                    centerY={true}
                >
                    <ModalSection className="stl-mb-2">
                        <p className="stl-mb-5">
                            Are you sure you want to delete this integration
                            with {sources?.length} source(s)?
                        </p>
                        {isLoading && <Loader />}
                        <FlexGrid
                            direction="row"
                            alignment="center"
                            spacing="md"
                            className="stl-mt-4"
                        >
                            <Button
                                disabled={isLoading}
                                variant="destructive"
                                size="medium"
                                onClick={(): void => {
                                    setIsLoading(true)
                                    deleteIntegration()
                                }}
                            >
                                Yes, Delete
                            </Button>
                            <Button
                                disabled={isLoading}
                                variant="neutral"
                                size="medium"
                                onClick={(): void => setIsModalOpen(false)}
                            >
                                Cancel
                            </Button>
                        </FlexGrid>
                    </ModalSection>
                </Modal>

                {integration && applications.length > 0 && (
                    <>
                        <FlexGrid
                            className={stl`w-full px-6 pt-4`}
                            spacing="md"
                            direction="column"
                        >
                            <FlexGrid spacing="sm" alignment="center">
                                {integration.platform && (
                                    <img
                                        src={
                                            logos[
                                                integration.platform.toLowerCase()
                                            ]
                                        }
                                        className={stl`h-full w-6 inline`}
                                    />
                                )}
                                <h1 className={stl`display-medium`}>
                                    {integration.name}
                                    {integration.platform
                                        ? ` - ${integration.platform}`
                                        : ''}
                                </h1>
                            </FlexGrid>

                            <StoreUrl
                                integration={integration}
                                channelId={channel?.id ?? Number(channelId)}
                            />

                            <FlexGrid spacing="sm" alignment="center">
                                {showHtml(
                                    applications,
                                    integration,
                                    platform
                                ) && (
                                    <Button
                                        disabled={isFromAdminView()}
                                        startIcon={Plus}
                                        size="medium"
                                        onClick={(): void => {
                                            createNewSource()
                                        }}
                                    >
                                        New Source
                                    </Button>
                                )}
                                {showHtml(
                                    applications,
                                    integration,
                                    platform
                                ) && (
                                    <Button
                                        disabled={isFromAdminView()}
                                        startIcon={Trash2}
                                        size="medium"
                                        onClick={async (): Promise<void> =>
                                            await deleteIntegrationButtonHandler()
                                        }
                                    >
                                        Delete integration
                                    </Button>
                                )}
                            </FlexGrid>
                        </FlexGrid>

                        {integration?.platform.toLowerCase() ===
                            PLATFORMS.bigcommerce.toLowerCase() && (
                            <>
                                <ChannelDetail
                                    currentChannel={channel}
                                    setCurrentChannel={setChannel}
                                    currentIntegration={integration}
                                    applications={applications}
                                    showBcSetup={showBcSetup}
                                    selectedSourceId={selectedSourceId}
                                    setSelectedSourceId={setSelectedSourceId}
                                    setShowBcSetup={setShowBcSetup}
                                    editSource={editSource}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
}
