import AlgoliaService from '../services/database/algoliaService'

export default async function getAlgoliaApplicationsList(): Promise<any> {
    const applicationsList: string[] = []

    await AlgoliaService.getApplications()
        .then((data) => {
            data?.map((application: any) =>
                applicationsList.push(application?.id)
            )
        })
        .catch(() => {
            return applicationsList
        })

    return applicationsList
}
