import type { IntegrationType } from '../types/database/integration.type'
import type { Platform } from '../types/platform.type'

export default function showHtml(
    applications: string[],
    integration: IntegrationType,
    platform: Platform | null
): boolean {
    if (applications?.includes(integration.algolia_app_id as string)) {
        return true
    }

    if (platform !== null) {
        return true
    }

    return false
}
