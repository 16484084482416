import { Card, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import SettingsService from '../../services/settings/settingsService'
import type { Settings as SettingType } from '../../types/settings.type'
import { Loader } from '../common/loader'

import { AppExtensionsSettings } from './appExtensionSettings'
import './settings.css'

export const Settings: FunctionComponent = (): any => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [settings, setSettings] = useState<SettingType>({})

    const fetchSettings = async (): Promise<void> => {
        setIsLoading(true)
        const savedSettings = await SettingsService.fetchSettings()
        if (!savedSettings) {
            setIsLoading(false)
            return
        }

        setSettings(savedSettings)
        setIsLoading(false)
    }

    // @todo: add settings to our state management when we finally get one.....MobX?
    useEffect(() => {
        fetchSettings()
    }, [])

    return (
        <>
            <FlexGrid
                className={stl`w-full h-full p-6`}
                direction="column"
                spacing="md"
                wrap={true}
                alignment="center"
            >
                <Card className={stl`w-900`} fullBleed>
                    {isLoading && (
                        <FlexGrid direction="column" alignment="center">
                            <Loader />
                        </FlexGrid>
                    )}
                    {!isLoading && (
                        <AppExtensionsSettings
                            settings={settings}
                            setSettings={setSettings}
                        />
                    )}
                </Card>
            </FlexGrid>
        </>
    )
}
