import { Checkbox, Field, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
    showOutofStock?: boolean
    setShowOutofStock: (indexingLevel: boolean) => void
}

export const ShowOutOfStockCheckbox: FunctionComponent<Props> = (props) => {
    const params = useParams()
    const { sourceId } = params
    const { showOutofStock, setShowOutofStock } = props

    useEffect(() => {
        if (
            typeof showOutofStock === 'undefined' &&
            typeof sourceId !== 'undefined'
        ) {
            // default indexes created before show out of stock code has been added to variant
            setShowOutofStock(true)
        } else if (typeof showOutofStock === 'undefined') {
            setShowOutofStock(true)
        }
    }, [showOutofStock])

    return (
        <FlexGrid className={stl`w-full`}>
            <h2
                className={stl`display-subheading`}
                style={{
                    width: '250px',
                }}
            >
                Show out of stock products
            </h2>
            <div className={stl`w-400 flex justify-start`}>
                <Field
                    description={
                        <span>
                            Products marked as not in stock will show up in
                            search results
                        </span>
                    }
                >
                    <Checkbox
                        checked={showOutofStock}
                        onChange={(event: any): void => {
                            setShowOutofStock(event.target.checked as boolean)
                        }}
                    />
                </Field>
            </div>
        </FlexGrid>
    )
}
