import type { Event } from '@algolia/ingestion'
import type { BadgeVariants } from '@algolia/satellite'
import {
    IconButton,
    Badge,
    Card,
    CardHeader,
    FlexGrid,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { ChevronRight, ChevronDown } from 'react-feather'
import { JSONTree } from 'react-json-tree'

import formatDateTime from '../../../utils/DateFormatter'

type EventsProps = {
    event: Event
    handleBadge: (status: string, outcome?: string) => BadgeVariants | undefined
}

export const EventCard: FunctionComponent<EventsProps> = (
    props: EventsProps
) => {
    const { event, handleBadge } = props
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <Card fullBleed className="stl-p-2" style={{ width: '98%' }}>
                <CardHeader
                    className="stl-cursor-pointer"
                    onClick={(): void => {
                        setExpanded(!expanded)
                    }}
                >
                    <FlexGrid
                        alignment="center"
                        spacing="sm"
                        distribution="equalSpacing"
                        className="stl-w-full"
                    >
                        <FlexGrid alignment="center">
                            <Badge
                                className="stl-capitalize"
                                variant={handleBadge(event.status)}
                                size="small"
                                style={{ width: '80px', marginRight: '20px' }}
                            >
                                {event.status === null ? 'Info' : event.status}
                            </Badge>
                            <span className="stl-capitalize">{event.type}</span>
                        </FlexGrid>
                        <FlexGrid
                            alignment="center"
                            spacing="sm"
                            distribution="fill"
                        >
                            <div style={{ minWidth: '180px' }}>
                                <span>{formatDateTime(event.publishedAt)}</span>
                            </div>

                            <IconButton
                                icon={expanded ? ChevronDown : ChevronRight}
                                title="Expand"
                                variant="subtle"
                                size="small"
                            />
                        </FlexGrid>
                    </FlexGrid>
                </CardHeader>
                {expanded && (
                    <FlexGrid
                        direction="column"
                        className="stl-w-full"
                        alignment="leading"
                    >
                        <JSONTree
                            invertTheme
                            theme={'grayscale'}
                            data={event}
                        />
                    </FlexGrid>
                )}
            </Card>
        </>
    )
}
