import { FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import IntegrationService from '../../../services/database/integrationService'
import type { IntegrationType } from '../../../types/database/integration.type'
import { PLATFORMS } from '../../../utils/platforms'
import { useAuth } from '../../AuthContext'
import { Loader } from '../loader'

import { IntegrationCard } from './integrationCard'
import { IntegrationEmptyState } from './integrationEmptyState'

export const Integrations: FunctionComponent = (): any => {
    const { platform } = useAuth()
    const [isLoading, setIsLoading] = useState(true)
    const [dbIntegrations, setDbIntegrations] = useState<IntegrationType[]>([])
    const navigate = useNavigate()

    async function reloadIntegrations(): Promise<any> {
        const integrationsResponse = await IntegrationService.fetchAll()
        setIsLoading(false)
        if (Object.keys(integrationsResponse).length === 0) {
            return false
        }

        setDbIntegrations(integrationsResponse)
        return true
    }

    function redirectToPlaformSetup(): any {
        switch (platform.platform_type) {
            case PLATFORMS.bigcommerce:
                navigate('/bigcommerce/setup')
                break

            default:
        }
    }

    useEffect(() => {
        if (platform !== null) {
            redirectToPlaformSetup()
        } else {
            reloadIntegrations()
        }
    }, [])

    async function integrationSelectAction(
        integration: IntegrationType
    ): Promise<void> {
        switch (integration?.platform.toLowerCase()) {
            case PLATFORMS.bigcommerce.toLowerCase():
                // eslint-disable-next-line no-case-declarations
                const currentIntegration = await IntegrationService.fetch(
                    integration.id as string
                )
                window.open(
                    `https://store-${currentIntegration.config.bcStoreHash}.mybigcommerce.com/manage/app/${process.env.REACT_APP_BIGCOMMERCE_APP_ID}`,
                    '_blank'
                )
                break
            default:
                throw new Error("Platform doesn't exist")
        }
    }

    return (
        <>
            {isLoading && (
                <>
                    <Loader message={'Fetching integrations...'} />
                </>
            )}
            <div className="stl-app-bg">
                <FlexGrid
                    className={stl`w-full px-6 pt-4`}
                    spacing="md"
                    direction="column"
                >
                    <h2 className={stl`display-medium`}>Your Integrations</h2>
                </FlexGrid>

                <FlexGrid
                    className={stl`w-full h-full min-h-screen p-6`}
                    direction="row"
                    alignment="center"
                    spacing="md"
                    wrap={true}
                >
                    {dbIntegrations.length > 0 &&
                        dbIntegrations?.map((integration: IntegrationType) => {
                            return (
                                <div
                                    key={integration.id}
                                    style={{ width: '30%' }}
                                >
                                    <IntegrationCard
                                        integration={integration}
                                        reloadIntegrations={reloadIntegrations}
                                        integrationSelectAction={
                                            integrationSelectAction
                                        }
                                    />
                                </div>
                            )
                        })}
                </FlexGrid>
            </div>
            {dbIntegrations.length === 0 && <IntegrationEmptyState />}
        </>
    )
}
