import { Button, Card, CardHeader, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

type Props = {
    platform: string
    documentationUrl: string
}

export const Support: FunctionComponent<Props> = (props): any => {
    const { platform, documentationUrl } = props

    return (
        <Card className={stl`w-900`} fullBleed>
            <CardHeader className={stl`px-4 mt-4 mb-2`}>
                <h2 className={stl`display-heading`}>Support</h2>
            </CardHeader>
            <FlexGrid
                className={`stl-w-full stl-p-6 stl-border-grey`}
                direction="column"
                spacing="lg"
            >
                <FlexGrid className={stl`w-full`}>
                    <p className={`stl-prl-05`}>
                        {`To find out more information, go to the Algolia
                        ${platform} documentation. If you need help, you can log
                        in to the Support Center and reach out to the support
                        team.`}
                    </p>
                </FlexGrid>
                <FlexGrid className={stl`w-full`}>
                    <Button
                        onClick={(): void => {
                            window.open(
                                documentationUrl,
                                '_blank',
                                'noopener noreferrer'
                            )
                        }}
                        variant="primary"
                    >
                        Documentation
                    </Button>
                    <Button
                        className="stl-ml-10"
                        onClick={(): void => {
                            window.open(
                                `https://support.algolia.com/hc/en-us/requests/new`,
                                '_blank',
                                'noopener noreferrer'
                            )
                        }}
                        variant="primary"
                    >
                        Algolia Support Center
                    </Button>
                </FlexGrid>
            </FlexGrid>
        </Card>
    )
}
