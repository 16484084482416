import type { DateRangePickerCompleteTimeRange } from '@algolia/satellite'
import {
    FlexGrid,
    DateRangePickerActionTypes,
    stl,
    isCompleteTimeRange,
} from '@algolia/satellite'
import moment from 'moment'
import type { FunctionComponent } from 'react'
import { useEffect } from 'react'

import type { RightSidePanelComponentArgs } from './dateRange'
import { DefaultTimeZone } from './defaultTimezone'
import type { TimeValue } from './timeInput'
import { TimeInput } from './timeInput'

type Props = {
    errorMessage?: string
    isValid: boolean
    panelArgs: RightSidePanelComponentArgs
    dateRange: DateRangePickerCompleteTimeRange
}

export const TimeRangePanel: FunctionComponent<Props> = (props): any => {
    const { errorMessage, isValid, panelArgs, dateRange } = props

    const applyTimeValue = (date: Date, timeValue: TimeValue): Date =>
        moment(date)
            .utc()
            .hours(timeValue.hours)
            .minutes(timeValue.minutes)
            .toDate()

    const getTimeValueFromDate = (value: Date): TimeValue => ({
        hours: moment(value).utc().hours(),
        minutes: moment(value).utc().minutes(),
    })

    const updateTime = (startValue: Date, endValue: Date): any => {
        const payload = {
            start: startValue,
            end: endValue,
        }

        if (new Date(payload.start) < new Date(payload.end)) {
            panelArgs.dispatch({
                type: DateRangePickerActionTypes.rangeSelect,
                payload: {
                    start: startValue,
                    end: endValue,
                },
            })
        } else {
            panelArgs.dispatch({
                type: DateRangePickerActionTypes.reset,
                payload: {
                    start: startValue,
                    end: endValue,
                },
            })
        }
    }

    useEffect(() => {
        panelArgs.dispatch({
            type: DateRangePickerActionTypes.rangeSelect,
            payload: {
                start: dateRange.start,
                end: dateRange.end,
            },
        })
    }, [])

    return (
        <div className={stl`flex flex-col`}>
            <DefaultTimeZone />

            <FlexGrid className={stl`flex items-center typo-subdued mb-2`}>
                <FlexGrid
                    distribution="leading"
                    className="width-100"
                >{`From: `}</FlexGrid>
                <FlexGrid distribution="trailing" spacing="sm">
                    {isCompleteTimeRange(dateRange)
                        ? moment(dateRange.start).format('MMM Do')
                        : ''}
                    <TimeInput
                        value={getTimeValueFromDate(dateRange.start)}
                        onChange={(value): any => {
                            updateTime(
                                applyTimeValue(dateRange.start, value),
                                dateRange.end
                            )
                        }}
                    />
                </FlexGrid>
            </FlexGrid>

            <FlexGrid className={stl`flex items-center typo-subdued mb-2`}>
                <FlexGrid
                    distribution="leading"
                    className="width-100"
                >{`To: `}</FlexGrid>
                <FlexGrid distribution="trailing" spacing="sm">
                    {isCompleteTimeRange(dateRange)
                        ? moment(dateRange.end).format('MMM Do')
                        : ''}
                    <TimeInput
                        value={getTimeValueFromDate(dateRange.end)}
                        onChange={(value): any => {
                            updateTime(
                                dateRange.start,
                                applyTimeValue(dateRange.end, value)
                            )
                        }}
                    />
                </FlexGrid>
            </FlexGrid>

            {!isValid && (
                <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
            )}
        </div>
    )
}
