import { FlexGrid, Card, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import IntegrationService from '../../services/database/integrationService'
import type { IntegrationType } from '../../types/database/integration.type'
import type { SourceType } from '../../types/database/source.type'
import { isEmpty } from '../../utils/utils'
import { AlgoliaLoginVerify } from '../common/AlgoliaLoginVerify'
import { IndexStep } from '../common/setup/indexStep'

import { SourceForm } from './sourceForm'

export const SetupSource: FunctionComponent = () => {
    const params = useParams()

    const { integrationId, channelId } = params
    const [integration, setIntegration] = useState<IntegrationType>()
    const [source, setSource] = useState<SourceType | undefined>()
    const [loading, setLoading] = useState(false)
    const [showIndexStep, setShowIndexStep] = useState(false)

    const fetchIntegration = async (): Promise<void> => {
        if (integrationId === integration?.id) {
            return
        }

        setIntegration(await IntegrationService.fetch(integrationId as string))
    }

    useEffect((): void => {
        if (!isEmpty(integrationId)) {
            fetchIntegration()
        }
    }, [integrationId])

    return (
        <>
            {!showIndexStep && (
                <FlexGrid
                    className="stl-w-full stl-h-full stl-flex stl-justify-center stl-min-h-screen stl-p-6 stl-app-bg"
                    direction="column"
                    alignment="center"
                    spacing="md"
                >
                    <Card fullBleed>
                        <AlgoliaLoginVerify />
                        <FlexGrid
                            direction="column"
                            alignment="center"
                            spacing="md"
                            className={stl`p-6`}
                        >
                            <h1 className="stl-display-heading stl-text-grey-900">
                                Configure your Algolia application
                            </h1>

                            <FlexGrid
                                className="stl-w-25 stl-w-full stl-mb-10 stl-p-2"
                                direction="column"
                                alignment="center"
                                spacing="md"
                            >
                                <SourceForm
                                    integration={integration as IntegrationType}
                                    channelId={Number(channelId) ?? 1}
                                    setSource={setSource}
                                    updateSource={(): void => {}}
                                    loading={loading}
                                    setLoading={setLoading}
                                    setShowBcSetup={(): void => {}}
                                    setShowIndexStep={setShowIndexStep}
                                />
                            </FlexGrid>
                        </FlexGrid>
                    </Card>
                </FlexGrid>
            )}
            {showIndexStep && (
                <IndexStep
                    integrationId={integrationId as string}
                    sourceId={source?.id as string}
                    channelId={channelId}
                />
            )}
        </>
    )
}
