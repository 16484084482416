import { Button, FlexGrid, stl, Card, EmptyState } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import IntegrationService from '../../../services/database/integrationService'
import type { BcChannel } from '../../../types/bigCommerce/channelsRespone.type'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { SourceType } from '../../../types/database/source.type'
import { PLATFORMS } from '../../../utils/platforms'
import { useAlert } from '../../AlertContext'
import { useAuth } from '../../AuthContext'

import { ManageSource } from './manageSource'
import { SourceCard } from './sourceCard'

type Props = {
    applications: string[]
    currentChannel?: BcChannel | undefined
    showBcSetup: boolean
    selectedSourceId: string | undefined
    setShowBcSetup: (show: boolean) => void
    editSource: (src: SourceType) => void
    isFromAdminView: () => boolean
}

export const SourceGrid: FunctionComponent<Props> = (props: Props): any => {
    const { platform } = useAuth()
    const params = useParams()
    const navigate = useNavigate()
    const { channelId, integrationId } = params
    const { showErrorAlert, showSuccessAlert } = useAlert()
    const [sources, setSources] = useState<SourceType[] | [] | [SourceType]>()
    const [integration, setIntegration] = useState<IntegrationType | null>()
    const {
        applications,
        currentChannel,
        showBcSetup,
        selectedSourceId,
        isFromAdminView,
        editSource,
        setShowBcSetup,
    } = props

    async function fetchIntegration(): Promise<any> {
        const activeIntegration = await IntegrationService.fetch(
            integrationId as string
        )

        setIntegration(activeIntegration)
        if (params.channelId) {
            setSources(
                activeIntegration?.sources?.filter(
                    (source: SourceType) =>
                        source.channel_id?.toString() === params.channelId
                )
            )
        } else if (typeof currentChannel !== 'undefined') {
            setSources(
                activeIntegration?.sources?.filter(
                    (source: SourceType) =>
                        source.channel_id === currentChannel?.id
                )
            )
        } else {
            setSources(activeIntegration?.sources)
        }
    }

    function createNewSource(): any {
        switch (integration?.platform.toLowerCase()) {
            case PLATFORMS.bigcommerce.toLowerCase():
                navigate(
                    `/${integration?.platform?.toLowerCase()}/setup/${integrationId}/channels/${channelId}`
                )
                break
            default:
                throw new Error("Platform doesn't exist")
        }
    }

    useEffect(() => {
        if (typeof integrationId !== 'undefined') {
            fetchIntegration()
        }
    }, [integrationId, currentChannel, showBcSetup])

    return (
        <>
            {showBcSetup && (
                <ManageSource
                    channelId={channelId as string}
                    integration={integration as IntegrationType}
                    sourceId={selectedSourceId}
                    setShowBcSetup={setShowBcSetup}
                />
            )}
            {!showBcSetup && (
                <>
                    {sources?.length === 0 ? (
                        <FlexGrid
                            direction="column"
                            spacing="md"
                            alignment="center"
                            className={stl`w-128`}
                        >
                            <FlexGrid
                                distribution="center"
                                className={stl`p-10`}
                            >
                                <Card className={stl`mb-8 px-32 py-16 p-16`}>
                                    <EmptyState
                                        title={<span>No sources created</span>}
                                        description="Get started by creating a source"
                                        actions={
                                            <Button
                                                className="stl-xenon-button"
                                                onClick={createNewSource}
                                            >
                                                Create a source
                                            </Button>
                                        }
                                        centered
                                        usageContext="feature"
                                    />
                                </Card>
                            </FlexGrid>
                        </FlexGrid>
                    ) : (
                        ''
                    )}
                    <FlexGrid
                        className={stl`w-full h-full min-h-screen p-6 pt-0`}
                        direction="row"
                        alignment="center"
                        spacing="md"
                        wrap={true}
                    >
                        {typeof sources !== 'undefined' &&
                            sources?.length > 0 &&
                            sources?.map((source: SourceType) => {
                                return (
                                    <div
                                        key={source.id}
                                        style={{ width: '30%' }}
                                    >
                                        <SourceCard
                                            source={source}
                                            integration={
                                                integration as IntegrationType
                                            }
                                            platform={platform}
                                            onActionComplete={fetchIntegration}
                                            showErrorAlert={showErrorAlert}
                                            showSuccessAlert={showSuccessAlert}
                                            userAlgoliaApplications={
                                                applications
                                            }
                                            isFromAdminView={isFromAdminView}
                                            editSource={editSource}
                                        ></SourceCard>
                                    </div>
                                )
                            })}
                    </FlexGrid>
                </>
            )}
        </>
    )
}
