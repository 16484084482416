import { Select } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import AlgoliaService from '../../services/database/algoliaService'
import { isEmpty } from '../../utils/utils'
import { useAuth } from '../AuthContext'

type Props = {
    setSelectedApplication: (app: string) => void
    isDisabled: boolean
    isPlatformAuthed?: boolean
}

export const AlgoliaAppListDropDown: FunctionComponent<Props> = (props) => {
    const [applications, setApplications] = useState([])
    const [isDisabled, setIsDisabled] = useState(props.isDisabled)
    const { setSelectedApplication, isPlatformAuthed } = props
    const { platform, jwtUser } = useAuth()

    const getApplications = async (): Promise<any> => {
        if (!isEmpty(platform) && isEmpty(jwtUser)) {
            return
        }
        await AlgoliaService.getApplications()
            .then((apps) => {
                setApplications(apps)
                setIsDisabled(false)
            })
            .catch(() => {
                setApplications([])
                setIsDisabled(false)
            })
    }

    useEffect(() => {
        if (isPlatformAuthed || platform === null) {
            getApplications()
        }
    }, [isPlatformAuthed, platform, jwtUser])

    return (
        <>
            <Select
                className="stl-w-400"
                disabled={isDisabled}
                onChange={(event: any): void => {
                    if (
                        event.target.value === 'Select an application' ||
                        event.target.value ===
                            'Unable to fetch Algolia applications'
                    ) {
                        setSelectedApplication('')
                        return
                    }
                    setSelectedApplication(event.target.value)
                }}
            >
                <option>Select an application</option>
                {applications.length > 0 ? (
                    applications?.map((application: any) => (
                        <option
                            key={application?.attributes?.application_id}
                            disabled={Boolean(
                                !application?.attributes.permissions.includes(
                                    'keys'
                                )
                            )}
                            value={application.attributes.application_id}
                        >
                            {application?.attributes?.name ||
                                'Nameless application '}
                            ({application?.attributes?.application_id})
                            {!application?.attributes.permissions.includes(
                                'keys'
                            ) && ` *Missing 'keys' permissions`}
                        </option>
                    ))
                ) : (
                    <option>Unable to fetch Algolia applications</option>
                )}
            </Select>
        </>
    )
}
