import { Badge } from '@algolia/satellite'
import type { FunctionComponent, ReactNode } from 'react'

import type { Versioning } from '../../../types/theming/versioning.type'

type Props = {
    version: string | undefined
    versioning: Versioning
}

export const CodeUpdateAvailableBadge: FunctionComponent<Props> = (props) => {
    const { version, versioning } = props

    const badge: ReactNode = (
        <Badge
            className="stl-capitalize"
            variant={'red'}
            size="small"
            style={{ marginLeft: '20px' }}
        >
            Updates Available
        </Badge>
    )

    function displayBadge(): ReactNode | null {
        if (typeof version === 'undefined') {
            return badge
        }

        if (
            typeof version !== 'undefined' &&
            typeof versioning?.latestVersion !== 'undefined' &&
            version < versioning?.latestVersion
        ) {
            return badge
        }

        return null
    }

    return <>{displayBadge()}</>
}
