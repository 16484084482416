import { Button, Card, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import InsightsService from '../../../../services/theming/insightsService'
import type { BcChannel } from '../../../../types/bigCommerce/channelsRespone.type'
import type { Insight } from '../../../../types/database/insight.type'
import type { IntegrationType } from '../../../../types/database/integration.type'
import { useAlert } from '../../../AlertContext'

import { BigOpenDataLayerAnalytics } from './bigOpenDataLayerAnalytics'
import { ClickAnalytics } from './clickAnalytics'
import { SearchAnalytics } from './searchAnalytics'
import { StorefrontEvents } from './storefrontEvents'
import { UserTokenSettings } from './userTokenSettings'

type Props = {
    currentChannel: BcChannel | undefined
    currentIntegration: IntegrationType | null | undefined
    isFromAdminView: () => boolean
}

export const Insights: FunctionComponent<Props> = (props): any => {
    const { currentChannel, currentIntegration, isFromAdminView } = props
    const [currentInsights, setCurrentInsights] = useState<Insight | undefined>(
        currentIntegration?.insights?.find(
            (insight) => insight.channel_id === currentChannel?.id
        )
    )

    const { showSuccessAlert, showErrorAlert } = useAlert()
    const [isLoading, setIsLoading] = useState(false)

    function updateInsights(): void {
        setIsLoading(true)
        InsightsService.updateInsightsSettings(
            currentInsights?.integration_id as string,
            currentInsights as Insight
        )
            .then((data) => {
                if (typeof data.error !== 'undefined') {
                    showErrorAlert(data.error, 'Error')
                    setIsLoading(false)

                    return
                }

                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
                showErrorAlert(
                    'There was an error updating your insights settings.',
                    'Error'
                )
            })
            .finally(() => {
                setIsLoading(false)
                showSuccessAlert('Insights settings updated', 'Success')
            })
    }

    useEffect(() => {
        setCurrentInsights(
            currentIntegration?.insights?.find(
                (insight) => insight.channel_id === currentChannel?.id
            )
        )
    }, [currentIntegration, currentChannel])

    return (
        <Card className={stl`w-900`} fullBleed>
            <SearchAnalytics currentIntegration={currentIntegration} />
            <ClickAnalytics
                currentInsights={currentInsights as Insight}
                setCurrentInsights={setCurrentInsights}
            />
            <UserTokenSettings
                currentInsights={currentInsights as Insight}
                setCurrentInsights={setCurrentInsights}
                isFromAdminView={isFromAdminView}
            />
            <BigOpenDataLayerAnalytics
                currentIntegration={currentIntegration as IntegrationType}
                currentInsights={currentInsights as Insight}
                setCurrentInsights={setCurrentInsights}
            />
            <StorefrontEvents
                currentInsights={currentInsights as Insight}
                setCurrentInsights={setCurrentInsights}
            />

            <FlexGrid
                className={`stl-w-full stl-p-6 stl-border-grey`}
                direction="column"
                spacing="lg"
                alignment="trailing"
            >
                <Button
                    variant="primary"
                    onClick={updateInsights}
                    loading={isLoading}
                    disabled={isFromAdminView()}
                >
                    Save
                </Button>
            </FlexGrid>
        </Card>
    )
}
