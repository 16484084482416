import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const AlgoliaService = {
    async getApplications(): Promise<any> {
        return await makeRequest(
            `/api/v1/algolia/applications`,
            REQUEST_TYPES.get
        )
    },
    async isBigCommerceAppAllowed(applicationId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/algolia/applications/${applicationId}`,
            REQUEST_TYPES.get
        )
    },
}

export default AlgoliaService
