import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const ObservabilityService = {
    async fetchSecureApiToken(integrationId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/secure-api-key`,
            REQUEST_TYPES.get
        )
    },
}

export default ObservabilityService
