import { Card, CardHeader, FlexGrid, IconButton, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { ChevronRight } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'

import BigCommerceService from '../../services/bigCommerce/bigCommerceService'
import type { BcChannel } from '../../types/bigCommerce/channelsRespone.type'
import getPlatform from '../../utils/getPlatform'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'
import { Loader } from '../common/loader'

export const ChannelsList: FunctionComponent = (): any => {
    const navigate = useNavigate()
    const { showErrorAlert } = useAlert()
    const params = useParams()
    const { integrationId } = params
    const [channels, setChannels] = useState<BcChannel[]>([])
    const [sourcesPerChannel, setSourcesPerChannel] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true)
    const { setPlatform } = useAuth()

    async function fetchChannelsData(): Promise<any> {
        const channelsList = await BigCommerceService.fetchChannels()
        setChannels(
            channelsList.data.filter((channel) => channel.status !== 'deleted')
        )
        const channelSources: { [channelId: string]: number } = {}

        const platformData = await getPlatform()

        setPlatform(platformData)

        if (platformData.integrations.sources.length === 0) {
            return
        }

        for (const source of platformData.integrations.sources) {
            const channelId = source.channel_id

            // If we have a channel id, increment the count for that channel if it exists, otherwise we default to 0
            if (channelId) {
                channelSources[channelId] = (channelSources[channelId] || 0) + 1
            }
        }

        setSourcesPerChannel(channelSources)
    }

    useEffect(() => {
        fetchChannelsData()
            .then(() => setLoading(false))
            .catch((error) => {
                showErrorAlert(error, 'Error fetching channels data')
                setLoading(false)
            })
    }, [])

    return (
        <>
            {loading && <Loader message={'Fetching BigCommerce channels...'} />}
            {!loading && (
                <FlexGrid
                    className={stl`w-full h-full p-6 App-bg`}
                    distribution="center"
                >
                    <Card style={{ width: '500px' }}>
                        <CardHeader className={stl`px-4 mt-4 mb-4`}>
                            <h2 className={stl`display-medium`}>Channels</h2>
                        </CardHeader>
                        <FlexGrid
                            direction="column"
                            className={stl`px-4 mb-4`}
                            spacing="sm"
                            alignment="center"
                        >
                            {channels?.map((channel: BcChannel) => {
                                return (
                                    <Card
                                        fullBleed
                                        className={stl` w-full`}
                                        key={channel.id}
                                    >
                                        <CardHeader
                                            className={stl`p-4 w-full cursor-pointer`}
                                            onClick={(): void => {
                                                if (
                                                    sourcesPerChannel[
                                                        channel.id
                                                    ] === 0 ||
                                                    !sourcesPerChannel[
                                                        channel.id
                                                    ]
                                                ) {
                                                    navigate(
                                                        `/bigcommerce/setup/${integrationId}/channels/${channel.id}`
                                                    )
                                                } else {
                                                    navigate(
                                                        `/bigcommerce/${integrationId}/channels/${channel.id}`
                                                    )
                                                }
                                            }}
                                        >
                                            <FlexGrid
                                                alignment="center"
                                                spacing="sm"
                                                distribution="equalSpacing"
                                                className={stl`w-full`}
                                            >
                                                <FlexGrid
                                                    spacing="md"
                                                    alignment="center"
                                                >
                                                    <img
                                                        src={channel.icon_url}
                                                    />
                                                    <FlexGrid
                                                        distribution="center"
                                                        direction="column"
                                                    >
                                                        <h2
                                                            className={stl`display-heading`}
                                                        >
                                                            {channel.name}
                                                        </h2>
                                                        <span
                                                            className={stl`display-subheading`}
                                                        >
                                                            {channel.type}
                                                        </span>
                                                        <span
                                                            className={stl`display-subheading`}
                                                        >
                                                            {sourcesPerChannel[
                                                                channel.id
                                                            ] || '0 '}
                                                            {' source'}
                                                            {sourcesPerChannel[
                                                                channel.id
                                                            ] === 1
                                                                ? ''
                                                                : 's'}
                                                        </span>
                                                    </FlexGrid>
                                                </FlexGrid>
                                                <IconButton
                                                    icon={ChevronRight}
                                                    title="Go to channel"
                                                    variant="subtle"
                                                    size="medium"
                                                />
                                            </FlexGrid>
                                        </CardHeader>
                                    </Card>
                                )
                            })}
                        </FlexGrid>
                    </Card>
                </FlexGrid>
            )}
        </>
    )
}
