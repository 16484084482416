import type { IndexResponse } from '../../types/algolia/indexResponse.type'
import type { IntegrationType } from '../../types/database/integration.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const IntegrationService = {
    async create(integration: IntegrationType): Promise<any> {
        return await makeRequest(
            '/api/v1/integrations',
            REQUEST_TYPES.post,
            JSON.stringify(integration)
        )
    },
    async update(id: string, integration: IntegrationType): Promise<boolean> {
        await makeRequest(
            `/api/v1/integrations/${id}`,
            REQUEST_TYPES.put,
            JSON.stringify(integration)
        )

        return true
    },
    async fetch(id: string): Promise<IntegrationType> {
        return await makeRequest(
            `/api/v1/integrations/${id}`,
            REQUEST_TYPES.get
        )
    },
    async fetchAll(): Promise<IntegrationType[]> {
        return await makeRequest(`/api/v1/integrations`, REQUEST_TYPES.get)
    },
    async delete(id: string): Promise<IntegrationType> {
        return await makeRequest(
            `/api/v1/integrations/${id}`,
            REQUEST_TYPES.delete
        )
    },
    async finalize(integrationId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/finalize`,
            REQUEST_TYPES.post
        )
    },
    async fetchAppIndexes(integrationId: string): Promise<IndexResponse> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/app-indexes`,
            REQUEST_TYPES.get
        )
    },
}

export default IntegrationService
