import { Button, Card, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState } from 'react'

import type { Metafield } from '../../../types/database/metafields.type'
import type { SourceType } from '../../../types/database/source.type'
import { Metafields } from '../../bigcommerce/metafields'
import { AlgoliaLoginVerify } from '../AlgoliaLoginVerify'

type Props = {
    source: SourceType
    integrationId: string
    loading: boolean
    setShowBcSetup: (show: boolean) => void
}

export const ManageMetafields: FunctionComponent<Props> = (
    props: Props
): any => {
    const { source, integrationId, setShowBcSetup } = props
    const [productMetafields, setProductMetafields] = useState<Metafield[]>(
        source.metafields?.filter(
            (field: Metafield) => field.class === 'product'
        ) ?? []
    )
    const [variantMetafields, setVariantMetafields] = useState<Metafield[]>(
        source.metafields?.filter(
            (field: Metafield) => field.class === 'variant'
        ) ?? []
    )

    return (
        <FlexGrid
            className="stl-flex stl-justify-center stl-min-h-screen stl-p-6 stl-app-bg"
            direction="column"
            alignment="center"
            spacing="md"
        >
            <Card fullBleed>
                <AlgoliaLoginVerify />
                <FlexGrid
                    direction="column"
                    alignment="center"
                    spacing="md"
                    className={stl`p-6`}
                >
                    <h4 className="stl-display-heading stl-text-grey-900">
                        Product Metafields
                    </h4>

                    <Metafields
                        integrationId={integrationId as string}
                        sourceId={source.id as string}
                        metafields={productMetafields}
                        setmetafields={setProductMetafields}
                        type="product"
                    />

                    <h4 className="stl-display-heading stl-text-grey-900">
                        Variant Metafields
                    </h4>
                    <Metafields
                        integrationId={integrationId as string}
                        sourceId={source.id as string}
                        metafields={variantMetafields}
                        setmetafields={setVariantMetafields}
                        type="variant"
                    />

                    <Button
                        className="stl-w-full"
                        size="large"
                        onClick={(): void => {
                            setShowBcSetup(false)
                        }}
                    >
                        Cancel
                    </Button>
                </FlexGrid>
            </Card>
        </FlexGrid>
    )
}
