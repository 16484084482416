import type { FunctionComponent } from 'react'
import type { RouteObject } from 'react-router-dom'
import { useMatches } from 'react-router-dom'

export const Breadcrumbs: FunctionComponent = () => {
    const matches: RouteObject[] = useMatches()
    const crumbs = matches
        .filter((match: any) => Boolean(match.handle?.crumb))
        .map((match: any) => {
            return match.handle.crumb(match.params)
        })
    return (
        <ol>
            {crumbs.map((crumb, index) => (
                <li key={index}>{crumb}</li>
            ))}
        </ol>
    )
}
