import { ProgressSpinner } from '@algolia/satellite'
import React from 'react'

type LoaderProps = {
    message?: string
    size?: number
    thickness?: number
    align?: 'center' | 'left' | 'right'
}

export const Loader: React.FunctionComponent<LoaderProps> = (
    props: LoaderProps
) => {
    return (
        <div className="stl-flex stl-flex-col stl-items-center stl-h-full stl-justify-center">
            {props.message && <span className="stl-mb-4">{props.message}</span>}
            <ProgressSpinner
                size={props.size ?? 48}
                thickness={props.thickness ?? 4}
                align={props.align}
            />
        </div>
    )
}
