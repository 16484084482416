import type { Metafield } from '../../types/database/metafields.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const MetafieldService = {
    async create(metafield: Metafield, integrationId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${metafield.source_id}/metafields`,
            REQUEST_TYPES.post,
            JSON.stringify(metafield)
        )
    },
    async getAll(
        integrationId: string,
        sourceId: string
    ): Promise<Metafield[]> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${sourceId}/metafields`,
            REQUEST_TYPES.get
        )
    },
    async get(
        integrationId: string,
        sourceId: string,
        metafieldId: string
    ): Promise<Metafield> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${sourceId}/metafields/${metafieldId}`,
            REQUEST_TYPES.get
        )
    },
    async update(metafield: Metafield, integrationId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${metafield.source_id}/metafields/${metafield.id}`,
            REQUEST_TYPES.put,
            JSON.stringify(metafield)
        )
    },
    async delete(metafield: Metafield, integrationId: string): Promise<any> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/source/${metafield.source_id}/metafields/${metafield.id}`,
            REQUEST_TYPES.delete
        )
    },
}

export default MetafieldService
