import { Card, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import algolia from '../../assets/images/algolia-logo-blue.svg'

export const MaintenanceMode: FunctionComponent = (): any => {
    return (
        <FlexGrid
            className={stl`w-full h-full p-6 App-bg pt-80`}
            distribution="center"
        >
            <Card style={{ width: '500px' }}>
                <FlexGrid
                    direction="column"
                    className={stl`px-4 mb-4`}
                    spacing="sm"
                    alignment="center"
                >
                    <img
                        style={{
                            width: '250px',
                            height: 'auto',
                            paddingTop: '20px',
                        }}
                        className="login-logo "
                        src={algolia}
                        alt="Algolia"
                    />

                    <p style={{ paddingTop: '20px' }}>
                        <b>Application is currently down for maintenance.</b>
                    </p>
                    <p style={{ paddingTop: '20px' }}>
                        Please try back again soon.
                    </p>
                    <p style={{ paddingTop: '20px' }}>
                        Estimated maintenance time is 1 hour.
                    </p>
                </FlexGrid>
            </Card>
        </FlexGrid>
    )
}
