import { Checkbox, Field, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

type Props = {
    isDistinct: boolean
    setIsDistinct: (isDistinct: boolean) => void
}

export const IsDistinctCheckbox: FunctionComponent<Props> = (props) => {
    const { isDistinct, setIsDistinct } = props

    return (
        <FlexGrid className={stl`w-full`}>
            <h2
                className={stl`display-subheading`}
                style={{
                    width: '250px',
                }}
            >
                Show products instead<br></br> of variants
            </h2>
            <div className={stl`w-400 flex justify-start`}>
                <Field
                    description={
                        <span>Display only one result per product</span>
                    }
                >
                    <Checkbox
                        checked={isDistinct}
                        onChange={(event: any): void => {
                            setIsDistinct(event.target.checked as boolean)
                        }}
                    />
                </Field>
            </div>
        </FlexGrid>
    )
}
