import {
    Field,
    Input,
    RangeSlider,
    stl,
    TooltipWrapper,
} from '@algolia/satellite'
import { HelpCircle } from 'react-feather'

export type Props = {
    disabled: boolean
    successThreshold: number
    setSuccessThreshold: (threshold: number) => void
}

export const SuccessThreshold: React.FunctionComponent<Props> = (
    props: Props
) => {
    const { disabled, successThreshold, setSuccessThreshold } = props
    const thresholdSettings = { max: 100, step: 1 }

    return (
        <>
            <Field
                className="stl-w-full stl-text-left"
                description="Specify the success threshold percentage for product indexing."
            >
                <TooltipWrapper
                    delay={500}
                    content="The success threshold allows the index to be marked as successful if the percentage of products successfully imported meets or exceeds the set threshold. For example, if you have the threshold set at 90%, and you have 100 products with 10 failing to import, the index will be considered successful because 90% of the products were imported successfully."
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '5px',
                        }}
                    >
                        <span>Success Threshold</span>
                        <HelpCircle
                            size={'14px'}
                            className="stl-ml-10"
                            color="blue"
                        />
                    </div>
                </TooltipWrapper>
                <div className="stl-pb-10">
                    <p
                        className={stl`display-caption flex justify-between mb-2`}
                    >
                        <span>0</span>
                        <span>25</span>
                        <span>50</span>
                        <span>75</span>
                        <span>{thresholdSettings.max}</span>
                    </p>
                    <RangeSlider
                        disabled={disabled}
                        {...thresholdSettings}
                        track2Color={'red'}
                        value={successThreshold}
                        onChange={(e: any): void => setSuccessThreshold(e)}
                    />
                </div>
                <Input
                    disabled={disabled}
                    min={0}
                    max={100}
                    type="number"
                    className="stl-w-full"
                    placeholder="Threshold"
                    value={successThreshold}
                    onChange={(e: any): void =>
                        setSuccessThreshold(Number(e.target.value))
                    }
                />
            </Field>
        </>
    )
}
