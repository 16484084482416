import type { RequestHeader } from './request'
import { getToken } from './tokenManager'
import { isEmpty } from './utils'

export default async function getPlatform(): Promise<any> {
    const headers: RequestHeader = {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.api+json',
    }

    if (!isEmpty(getToken())) {
        headers.Authorization = `Bearer ${getToken()}`
    }

    const response = await fetch('/api/v1/platforms/current', {
        method: 'GET',
        headers,
    })
    if (!response.ok) {
        return null
    }
    const user = await response.json()
    return user
}
