import { Field, FlexGrid, Input, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

type Props = {
    facetOptionLimit: number
    setFacetOptionLimit: (optionLimit: number) => void
}

export const FacetOptionLimit: FunctionComponent<Props> = (props) => {
    const { facetOptionLimit, setFacetOptionLimit } = props

    return (
        <FlexGrid className={stl`w-full`}>
            <h2
                className={stl`display-subheading`}
                style={{
                    width: '250px',
                }}
            >
                Facet Option Limit
            </h2>
            <div className={stl`w-400 flex justify-start`}>
                <Field
                    description={
                        <span>Amount of facet options to be displayed</span>
                    }
                >
                    <Input
                        type="number"
                        variant="small"
                        placeholder="35"
                        value={facetOptionLimit}
                        onChange={(event: any): void => {
                            setFacetOptionLimit(Number(event.target.value))
                        }}
                    />
                </Field>
            </div>
        </FlexGrid>
    )
}
