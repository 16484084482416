import type {
    IngestionClient,
    Run,
    GetRunsProps,
    RunListResponse,
} from '@algolia/ingestion'
import type { BadgeVariants, DateRangePickerProps } from '@algolia/satellite'
import {
    Badge,
    Link,
    Card,
    FlexGrid,
    stl,
    Table,
    Pagination,
    ProgressSpinner,
    CardHeader,
    CardTitle,
    IconButton,
} from '@algolia/satellite'
import type { FunctionComponent, ReactNode } from 'react'
import { useState, useEffect } from 'react'
import { ChevronRight, RefreshCw } from 'react-feather'
import { useParams, useSearchParams } from 'react-router-dom'

import IntegrationService from '../../../services/database/integrationService'
import SourceService from '../../../services/database/sourceService'
import ObservabilityService from '../../../services/observability/observabilityService'
import type { SourceType } from '../../../types/database/source.type'
import formatDateTime from '../../../utils/DateFormatter'
import getIngestionClient from '../../../utils/getIngestionClient'
import { isEmpty } from '../../../utils/utils'
import { useAlert } from '../../AlertContext'
import { DateRange } from '../dateRange/dateRange'

import { RunDetail } from './runDetail'

export const Runs: FunctionComponent = (): any => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(searchParams.get('page') ?? '1')
    const [runId, setRunId] = useState(searchParams.get('runId'))
    const { integrationId, sourceId, taskId } = useParams()
    const [runList, setRunList] = useState<RunListResponse>()
    const [runsLoading, setRunsLoading] = useState(false)
    const [run, setRun] = useState<Run>()
    const [source, setSource] = useState<SourceType>()
    const [ingestionClient, setIngestionClient] = useState<
        IngestionClient | undefined
    >()
    const { showPersistentAlert } = useAlert()
    const [platform, setPlatform] = useState<string>('')
    const [showRuntimeMessage, setShowRuntimeMessage] = useState<boolean>(false)

    // default date range to 1 week
    const dateTime = new Date()
    const defaultDayRange = 7
    const startDate = new Date(
        new Date(
            dateTime.getTime() - defaultDayRange * 24 * 60 * 60 * 1000
        ).setHours(0, 0, 0, 0)
    )
    const [dateRange, setDateRange] = useState<DateRangePickerProps['range']>({
        start: startDate,
        end: dateTime,
    })

    async function fetchRuns(
        currentPage: number = 1,
        itemsPerPage: number = 10
    ): Promise<any> {
        setRunsLoading(true)
        const runsProp: GetRunsProps = {
            page: currentPage,
            itemsPerPage,
            startDate: dateRange?.start?.toISOString(),
            endDate: dateRange?.end?.toISOString(),
        }

        if (taskId) {
            runsProp.taskID = taskId
        }

        await ingestionClient
            ?.getRuns(runsProp)
            .then((runsResponse) => {
                setRunList(runsResponse)

                if (
                    runsResponse.runs.length > 0 &&
                    calculateElapsedTime(runsResponse.runs[0].createdAt) > 5 &&
                    runsResponse.runs[0].status === 'started'
                ) {
                    setShowRuntimeMessage(true)
                }
            })
            .catch((error) => {
                const messageLink: ReactNode = (
                    <span>
                        {error.message}{' '}
                        <Link
                            href={`https://algolia.com/apps/${ingestionClient?.appId}/dashboard`}
                        >
                            View Algolia app
                        </Link>
                    </span>
                )

                showPersistentAlert('Error', messageLink, 'red')
            })
            .finally(() => setRunsLoading(false))
        setRunsLoading(false)
    }

    async function fetchRun(id: string): Promise<any> {
        setRun(await ingestionClient?.getRun({ runID: id }))
    }

    async function fetchSource(): Promise<any> {
        const sourceResponse = await SourceService.get(
            integrationId as string,
            sourceId as string
        )
        setSource(sourceResponse)
    }

    async function fetchPageData(): Promise<any> {
        const integration = await IntegrationService.fetch(
            integrationId as string
        )

        const apiKeyResponse = await ObservabilityService.fetchSecureApiToken(
            integrationId as string
        )

        setPlatform(integration.platform)

        setIngestionClient(
            getIngestionClient(integration, apiKeyResponse.apiKey)
        )

        await fetchSource()
    }

    useEffect(() => {
        fetchPageData()
    }, [sourceId])

    useEffect(() => {
        if (typeof ingestionClient !== 'undefined') {
            fetchRuns(Number(page))
        }
        if (typeof runId !== 'undefined' && runId !== null) {
            fetchRun(runId)
        }
    }, [ingestionClient, dateRange])

    function handleDetail(selectedRun: Run): void {
        if (selectedRun.runID === run?.runID) {
            setRun(undefined)
            setRunId(null)
            setSearchParams({ page })
        } else {
            setRun(selectedRun)
            setRunId(selectedRun.runID)
            setSearchParams({ runId: selectedRun.runID, page })
        }
    }

    function handleBadge(
        status: string,
        outcome?: string
    ): BadgeVariants | undefined {
        if (outcome === 'failure') {
            return 'red'
        }

        switch (status) {
            case 'failure':
            case 'failed':
                return 'red'

            case 'started':
                return 'blue'

            case 'idled':
            case 'retried':
                return 'orange'

            case null:
            case 'pending':
                return 'grey'

            default:
                return 'green'
        }
    }

    function calculateElapsedTime(createdAt: string): number {
        const createdDate = new Date(createdAt)
        const now = new Date()
        const diff = now.getTime() - createdDate.getTime()
        const minutes = Math.floor(diff / 60000)
        return minutes
    }

    return (
        <div className="stl-app-bg">
            <FlexGrid
                className={stl`w-full h-full p-6 bg-grey-50 App-bg`}
                direction="row"
                spacing="md"
                wrap={true}
                distribution="center"
            >
                <FlexGrid direction="column" spacing="sm" alignment="center">
                    <Card
                        fullBleed
                        style={{
                            width: 650,
                        }}
                    >
                        <CardHeader className="stl-px-6 stl-py-2 stl-border-grey-200">
                            <CardTitle
                                className="stl-display-body"
                                style={{ alignSelf: 'center' }}
                            >
                                {taskId === source?.realtime_task_id && (
                                    <FlexGrid
                                        direction="column"
                                        spacing="none"
                                        alignment="leading"
                                    >
                                        Realtime Update Runs
                                    </FlexGrid>
                                )}
                                {taskId === source?.reindex_task_id && (
                                    <FlexGrid
                                        direction="column"
                                        spacing="none"
                                        alignment="leading"
                                    >
                                        Reindex Runs
                                    </FlexGrid>
                                )}
                            </CardTitle>
                            <FlexGrid
                                direction="row"
                                spacing="xl"
                                alignment="leading"
                            >
                                {isEmpty(run) && (
                                    <DateRange
                                        rightPanelType="dateRangePanel"
                                        dateRange={dateRange}
                                        setDateRange={setDateRange}
                                    />
                                )}
                                <IconButton
                                    icon={RefreshCw}
                                    title="Refresh"
                                    variant="subtle"
                                    onClick={(): void => {
                                        setRunId(null)
                                        setRun(undefined)
                                        setSearchParams({})

                                        fetchRuns()
                                    }}
                                />
                            </FlexGrid>
                        </CardHeader>
                        <CardHeader className="stl-px-6 stl-py-2 stl-border-b stl-border-grey-200">
                            <CardTitle className="stl-display-body">
                                {taskId === source?.realtime_task_id &&
                                    (runList?.runs?.length as number) < 1 && (
                                        <div style={{ flex: '1' }}>
                                            <p className="stl-muted-text">
                                                It can take up to one minute for
                                                a newly created webhook to
                                                appear.
                                            </p>
                                        </div>
                                    )}
                                {taskId === source?.reindex_task_id &&
                                    showRuntimeMessage && (
                                        <p className="stl-muted-text">
                                            Reindex taking a while? Rate limits
                                            on the {platform} APIs might prevent
                                            us from indexing your catalog any
                                            faster. Increasing the rate limits
                                            could potentially speed up indexing
                                            to Algolia.
                                        </p>
                                    )}
                            </CardTitle>
                        </CardHeader>
                        <Table
                            footer={`Showing ${runList?.runs.length || 0} of ${
                                runList?.pagination?.nbItems || 0
                            } results`}
                            smallFooter
                            className="interactive-table"
                        >
                            <thead>
                                <tr>
                                    <th className="stl-text-left">Status</th>

                                    <th className="stl-text-left">
                                        Destination
                                    </th>
                                    <th className="stl-text-left">Action</th>
                                    <th className="stl-text-left stl-min-w-24">
                                        Created At (UTC)
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!runsLoading ? (
                                    runList?.runs.map((eventRun: Run) => {
                                        const runStatus =
                                            eventRun.outcome === 'failure'
                                                ? 'Failed'
                                                : eventRun.status
                                        return (
                                            <tr
                                                key={eventRun.runID}
                                                onClick={(): void => {
                                                    handleDetail(eventRun)
                                                }}
                                                className={
                                                    run?.runID ===
                                                    eventRun.runID
                                                        ? 'selected-row'
                                                        : ''
                                                }
                                            >
                                                <td>
                                                    {' '}
                                                    <Badge
                                                        variant={handleBadge(
                                                            eventRun.status,
                                                            eventRun.outcome as string
                                                        )}
                                                        className="stl-capitalize"
                                                    >
                                                        {runStatus}
                                                    </Badge>
                                                </td>
                                                <td className="stl-text-left">
                                                    {source?.config?.indexName}
                                                </td>
                                                <td className="stl-text-left stl-capitalize">
                                                    {eventRun.type}
                                                </td>
                                                <td className="stl-text-left">
                                                    {formatDateTime(
                                                        eventRun.createdAt
                                                    )}
                                                </td>
                                                <td>
                                                    {(!eventRun ||
                                                        eventRun === run) && (
                                                        <ChevronRight />
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FlexGrid
                                                alignment="center"
                                                distribution="center"
                                                className="stl-w-full"
                                            >
                                                <ProgressSpinner />
                                            </FlexGrid>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card>
                    {typeof runList?.pagination?.nbPages !== 'undefined' &&
                        runList?.pagination?.nbPages > 0 && (
                            <Pagination
                                onChange={(currentPage: any): void => {
                                    fetchRuns(currentPage)
                                    setPage(currentPage)
                                    setRunId(null)
                                    setRun(undefined)
                                    setSearchParams({ page: currentPage })
                                }}
                                currentPage={runList?.pagination?.page || 0}
                                nbPages={runList?.pagination?.nbPages || 0}
                                maxButtons={10}
                            />
                        )}
                </FlexGrid>
                {run !== undefined && (
                    <RunDetail
                        ingestionClient={ingestionClient as IngestionClient}
                        run={run}
                        setRun={setRun}
                        runList={runList}
                        setRunList={setRunList}
                        handleBadge={handleBadge}
                    ></RunDetail>
                )}
            </FlexGrid>
        </div>
    )
}
