import { Button, FlexGrid, Modal, ModalSection, stl } from '@algolia/satellite'
import type { OnValidate } from '@monaco-editor/react'
import { DiffEditor, Editor } from '@monaco-editor/react'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'

type Props = {
    title: string
    language: string
    editableCode: string
    isModalOpen: boolean
    defaultCode: string
    currentVersion: string
    latestVersion: string
    latestCode: string
    isFromAdminView: () => boolean
    setIsModalOpen: (isOpen: boolean) => void
    setEditableCode: (code: string) => void
    save: (code: string, versionBeingSaved: string) => void
}

export const EditCodeModal: FunctionComponent<Props> = (props): any => {
    const {
        title,
        language,
        editableCode,
        isModalOpen,
        defaultCode,
        currentVersion,
        latestVersion,
        latestCode,
        isFromAdminView,
        setIsModalOpen,
        setEditableCode,
        save,
    } = props
    const editorRef = useRef<any>(null)
    const [code, setCode] = useState<string>(editableCode)
    const [isSaveDisabled, setIsSaveDisabled] = useState(isFromAdminView)
    const [validationErrors, setValidationErrors] = useState<OnValidate[]>([])
    const [showDiffEditor, setShowDiffEditor] = useState(false)
    const [updatedLatest, setUpdatedLatest] = useState<string | undefined>()
    const [versionBeingSaved, setVersionBeingSaved] =
        useState<string>(currentVersion)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)

    function handleEditorChange(value: any): void {
        setCode(value)
    }

    function handleEditorDidMount(editor: any): void {
        editorRef.current = editor
    }

    function handleEditorValidation(markers: OnValidate[]): void {
        setValidationErrors(markers)
        if (markers.length === 0) {
            setIsSaveDisabled(false)
        } else {
            setIsSaveDisabled(true)
        }
    }

    function applyLatestCode(): void {
        setShowDiffEditor(false)
        setEditableCode(updatedLatest as string)
        if (latestVersion) {
            setVersionBeingSaved(latestVersion)
        }
    }

    function handleDiffToggle(): void {
        setShowDiffEditor(!showDiffEditor)
    }

    function resetToDefaultCode(): void {
        editorRef.current.setValue(defaultCode)
        setIsConfirmOpen(false)
    }

    useEffect(() => {
        setCode(editableCode)
    }, [editableCode])

    useEffect(() => {
        setVersionBeingSaved(currentVersion)
    }, [currentVersion])

    useEffect(() => {
        setShowDiffEditor(false)
    }, [language])

    return (
        <Modal
            open={isModalOpen}
            title={title}
            animate={false}
            onDismiss={(): void => setIsModalOpen(false)}
            centerY={false}
            fullBleed={true}
            size="large"
            autoFocusOnOpenElement={false}
        >
            <Modal
                open={isConfirmOpen}
                title={`Reset code to default`}
                animate={false}
                onDismiss={(): void => setIsConfirmOpen(false)}
                centerY={true}
            >
                <ModalSection className="stl-mb-2">
                    <div className="stl-mb-5 stl-justify-center">
                        {`Are you sure you want to reset code to default?`}
                    </div>
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-mt-4"
                    >
                        <Button
                            variant="destructive"
                            size="medium"
                            onClick={(): void => {
                                resetToDefaultCode()
                            }}
                        >
                            Yes, Apply Default Code
                        </Button>
                        <Button
                            variant="neutral"
                            size="medium"
                            onClick={(): void => setIsConfirmOpen(false)}
                        >
                            Cancel
                        </Button>
                    </FlexGrid>
                </ModalSection>
            </Modal>
            {!showDiffEditor && (
                <Editor
                    height="70vh"
                    defaultLanguage={language}
                    defaultValue={editableCode}
                    onChange={handleEditorChange}
                    onMount={handleEditorDidMount}
                    onValidate={handleEditorValidation}
                />
            )}
            {showDiffEditor && (
                <DiffEditor
                    height="70vh"
                    originalLanguage={language}
                    modifiedLanguage={language}
                    original={editableCode}
                    modified={latestCode}
                    onMount={(editor: any): void => {
                        editor.onDidUpdateDiff(() => {
                            setUpdatedLatest(
                                editor.getModifiedEditor().getValue()
                            )
                        })
                    }}
                />
            )}
            <FlexGrid className={stl`w-full px-4 mb-4`} distribution="fill">
                {!showDiffEditor && (
                    <FlexGrid className={stl`mt-4`} distribution="leading">
                        <Button
                            disabled={isSaveDisabled}
                            variant="primary"
                            size="medium"
                            onClick={(): void => {
                                save(code, versionBeingSaved)
                                setIsModalOpen(false)
                            }}
                        >
                            Save
                        </Button>
                    </FlexGrid>
                )}
                {showDiffEditor && (
                    <FlexGrid className={stl`mt-4`} distribution="leading">
                        <Button
                            disabled={isSaveDisabled}
                            variant="primary"
                            size="medium"
                            onClick={(): void => {
                                applyLatestCode()
                            }}
                        >
                            Apply Changes
                        </Button>
                    </FlexGrid>
                )}
                {!showDiffEditor && (
                    <FlexGrid className={stl`mt-4`} distribution="trailing">
                        {currentVersion !== latestVersion && (
                            <Button
                                variant="neutral"
                                size="medium"
                                onClick={handleDiffToggle}
                            >
                                View Updates
                            </Button>
                        )}
                        <Button
                            className="stl-ml-10"
                            variant="destructive"
                            size="medium"
                            onClick={(): void => {
                                setIsConfirmOpen(true)
                            }}
                        >
                            Reset To Default
                        </Button>
                    </FlexGrid>
                )}
                {showDiffEditor && (
                    <FlexGrid className={stl`mt-4`} distribution="trailing">
                        <Button
                            variant="destructive"
                            size="medium"
                            onClick={(): void => {
                                setShowDiffEditor(false)
                            }}
                        >
                            Cancel
                        </Button>
                    </FlexGrid>
                )}
            </FlexGrid>
            <FlexGrid
                className={stl`w-full px-4 mb-4`}
                distribution="fill"
                alignment="center"
            >
                {validationErrors.length > 0 && (
                    <div className="code-error">
                        {validationErrors?.map((validationError: any) => {
                            return (
                                <p>{`${validationError.message} at line ${validationError.startLineNumber}`}</p>
                            )
                        })}
                    </div>
                )}
            </FlexGrid>
        </Modal>
    )
}
