import type { Insight } from '../../types/database/insight.type'
import type { InsightsVersioning } from '../../types/theming/versioning.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const InsightsService = {
    async updateInsightsSettings(
        integrationId: string,
        insights: Insight
    ): Promise<any> {
        // eslint-disable-next-line no-param-reassign
        delete insights.last_modified

        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${insights.channel_id}/insights/${insights.id}`,
            REQUEST_TYPES.put,
            JSON.stringify(insights)
        )
    },
    async getInsightsVersioning(
        insights: Insight
    ): Promise<InsightsVersioning> {
        return await makeRequest(
            `/api/v1/integrations/${insights.integration_id}/channel/${insights.channel_id}/insights/versioning`,
            REQUEST_TYPES.get
        )
    },
}

export default InsightsService
