import { Badge, Button } from '@algolia/satellite'
import type { FunctionComponent, ReactNode } from 'react'

import type {
    InsightsVersioning,
    Versioning,
} from '../../../types/theming/versioning.type'

type Props = {
    showUpdate: boolean
    isLoading: boolean
    version: string | undefined
    versioning: InsightsVersioning | Versioning
    update: () => void
}

export const CodeUpdateAvailableButtonWithBadge: FunctionComponent<Props> = (
    props
) => {
    const { showUpdate, isLoading, version, versioning, update } = props

    const buttonBadge: ReactNode = (
        <>
            <Button onClick={update} loading={isLoading}>
                Update
            </Button>
            <Badge
                className="stl-capitalize"
                variant={'red'}
                size="small"
                style={{ marginLeft: '20px' }}
            >
                Updates Available
            </Badge>
        </>
    )

    function displayBadge(): ReactNode | null {
        if (
            showUpdate &&
            typeof version !== 'undefined' &&
            typeof versioning?.latestVersion !== 'undefined' &&
            version < versioning?.latestVersion
        ) {
            return buttonBadge
        }

        return null
    }

    return <>{displayBadge()}</>
}
