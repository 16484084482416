import {
    Button,
    Card,
    FlexGrid,
    Modal,
    ModalSection,
    stl,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState } from 'react'

import bigcommerce from '../../../assets/images/bigcommerce.svg'
import IntegrationService from '../../../services/database/integrationService'
import type { IntegrationType } from '../../../types/database/integration.type'
import { useAlert } from '../../AlertContext'

type Props = {
    integration: IntegrationType
    reloadIntegrations: () => void
    integrationSelectAction: (integration: IntegrationType) => any
}

export const IntegrationCard: FunctionComponent<Props> = (props: Props) => {
    const { integration, reloadIntegrations, integrationSelectAction } = props
    const logos: Record<string, string> = {
        bigcommerce,
    }
    const { showSuccessAlert, showErrorAlert } = useAlert()
    const [isModalOpen, setIsModalOpen] = useState(false)

    function deleteIntegration(currentIntegration: IntegrationType): any {
        IntegrationService.delete(currentIntegration.id as string)
            .then(() => {
                showSuccessAlert(
                    `Deleted ${integration.name} integration`,
                    'Success'
                )
                reloadIntegrations()
            })
            .catch(() => {
                showErrorAlert(
                    `Failed to delete ${integration.name} integration`,
                    'Error'
                )
            })
    }
    const numSources = integration.sources?.length || 0

    return (
        <>
            <Card fullBleed key={integration.id} className={stl`w-full`}>
                <Card.Header
                    className={stl`p-6 flex items-center border-b border-grey-200`}
                >
                    <Card.Title>
                        {integration.platform &&
                            logos[integration.platform.toLowerCase()] && (
                                <img
                                    src={
                                        logos[
                                            integration.platform.toLowerCase()
                                        ]
                                    }
                                    className={stl`inline mr-4 w-6`}
                                />
                            )}
                        {`${integration.name} - ${integration.platform}`}
                    </Card.Title>
                </Card.Header>
                <div className={stl`p-6 space-y-6`}>
                    <p className={stl`mb-4`}>
                        {integration.platform.charAt(0).toUpperCase() +
                            integration.platform.slice(1)}{' '}
                        project <b>{integration.name}</b> is connected to
                        Algolia application ID{' '}
                        <b>{integration.algolia_app_id}</b>, populating{' '}
                        <b>{numSources}</b>{' '}
                        {numSources === 1 ? 'index' : 'indices'}.
                    </p>
                    <div className={stl`text-right`}>
                        <Button
                            variant="primary"
                            className={stl`text-right`}
                            onClick={(): void => {
                                integrationSelectAction(integration)
                            }}
                        >
                            Manage
                        </Button>
                    </div>
                </div>
            </Card>
            <Modal
                open={isModalOpen}
                title={`Delete Integration`}
                animate={true}
                onDismiss={(): void => setIsModalOpen(false)}
                centerY={true}
            >
                <ModalSection>
                    <p className="stl-mb-2">
                        Are you sure you want to delete {integration.name}{' '}
                        integration with {integration.sources?.length} sources?{' '}
                    </p>
                    <FlexGrid direction="row" alignment="center" spacing="md">
                        <Button
                            variant="destructive"
                            size="medium"
                            onClick={(): void => {
                                deleteIntegration(integration)
                                setIsModalOpen(false)
                            }}
                        >
                            Yes, Delete
                        </Button>
                        <Button
                            variant="neutral"
                            size="medium"
                            onClick={(): void => setIsModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </FlexGrid>
                </ModalSection>
            </Modal>
        </>
    )
}
