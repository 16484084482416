import { CardHeader, FlexGrid, stl, Toggle } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import type { Insight } from '../../../../types/database/insight.type'

type Props = {
    currentInsights: Insight
    setCurrentInsights: (insights: Insight) => void
}

export const StorefrontEvents: FunctionComponent<Props> = (props): any => {
    const { currentInsights, setCurrentInsights } = props

    return (
        <>
            <CardHeader className={stl`px-4 mt-4 mb-2`}>
                <h2 className={stl`display-heading`}>Storefront Events</h2>
            </CardHeader>

            <FlexGrid
                className={`stl-w-full stl-p-6`}
                direction="column"
                spacing="lg"
            >
                <FlexGrid className={stl`w-full`}>
                    <p className={`stl-prl-05`}>
                        Select the customer actions you want to track
                    </p>
                </FlexGrid>

                <FlexGrid
                    className={`stl-w-full stl-p-6`}
                    direction="column"
                    spacing="lg"
                >
                    <table className={stl`w-full`}>
                        <tbody>
                            <tr>
                                <td style={{ width: '30%' }}>Add to cart</td>
                                <td>
                                    <Toggle
                                        checked={
                                            currentInsights?.config
                                                ?.add_to_cart_data_layer ??
                                            false
                                        }
                                        disabled={
                                            !currentInsights?.config
                                                ?.is_data_layer_enabled ||
                                            !currentInsights?.is_enabled
                                        }
                                        onChange={(): void => {
                                            setCurrentInsights({
                                                ...(currentInsights as Insight),
                                                config: {
                                                    ...currentInsights?.config,
                                                    add_to_cart_data_layer:
                                                        !currentInsights?.config
                                                            ?.add_to_cart_data_layer,
                                                },
                                            })
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '30%' }}>
                                    Order purchased
                                </td>
                                <td>
                                    <Toggle
                                        checked={
                                            currentInsights?.config
                                                ?.order_purchased_data_layer ??
                                            false
                                        }
                                        disabled={
                                            !currentInsights?.config
                                                ?.is_data_layer_enabled ||
                                            !currentInsights?.is_enabled
                                        }
                                        onChange={(): void => {
                                            setCurrentInsights({
                                                ...(currentInsights as Insight),
                                                config: {
                                                    ...currentInsights?.config,
                                                    order_purchased_data_layer:
                                                        !currentInsights?.config
                                                            ?.order_purchased_data_layer,
                                                },
                                            })
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </FlexGrid>
            </FlexGrid>
        </>
    )
}
