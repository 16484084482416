import { Select } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import type { BcChannel } from '../../types/bigCommerce/channelsRespone.type'
import type { IntegrationType } from '../../types/database/integration.type'
import type { SourceType } from '../../types/database/source.type'

type Props = {
    setSelectedSource: (sourceId: string) => void
    currentChannel: BcChannel | undefined
    integration: IntegrationType | null | undefined
    selectedSource?: string
}

export const SourceDropdown: FunctionComponent<Props> = (props) => {
    const { setSelectedSource, integration, selectedSource, currentChannel } =
        props
    const params = useParams()

    const [sources, setSources] = useState<SourceType[]>()

    function fetchSources(): void {
        if (params.channelId) {
            setSources(
                integration?.sources?.filter(
                    (source: SourceType) =>
                        source.channel_id?.toString() === params.channelId
                )
            )
        } else if (typeof currentChannel !== 'undefined') {
            setSources(
                integration?.sources?.filter(
                    (source: SourceType) =>
                        source.channel_id === currentChannel.id
                )
            )
        } else {
            setSources(integration?.sources)
        }
    }

    useEffect(() => {
        if (typeof selectedSource === 'undefined' && sources?.length === 1) {
            setSelectedSource(sources[0].id as string)
        }
    })

    useEffect(() => {
        fetchSources()
    }, [integration, currentChannel])

    return (
        <>
            <Select
                className="stl-w-400"
                onChange={(event: any): void => {
                    if (event.target.selectedIndex === 0) {
                        setSelectedSource('')
                    } else {
                        setSelectedSource(event.target.value)
                    }
                }}
                value={selectedSource}
            >
                <option>Select a source</option>
                {sources?.map((source: SourceType) => (
                    <option key={source?.id} value={source?.id}>
                        {source?.config.indexName}
                    </option>
                ))}
            </Select>
        </>
    )
}
