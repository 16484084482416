import { FlexGrid, IconButton, stl } from '@algolia/satellite'
import type { FunctionComponent, ReactElement } from 'react'
import { useCallback, useEffect, useState } from 'react'
import type { DroppableProps, DroppableProvided } from 'react-beautiful-dnd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { PlusCircle } from 'react-feather'

import type { InstantSearchSortOrder } from '../../../types/database/instantSearch.type'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { SourceType } from '../../../types/database/source.type'
import { isEmpty } from '../../../utils/utils'

import { SortOrderingCard } from './sortOrderingCard'

type Props = {
    currentSource: string
    integration: IntegrationType
    sortOrders: InstantSearchSortOrder[]
    replicaNames: Array<{ value: string; label: string }>
    setIsChanged: (isChanged: boolean) => void
    onSortOrderChange: (updatedSortOrder: InstantSearchSortOrder[]) => void
    fetchIndexData: () => void
}

function setupDefaultSortOrders(
    integration: IntegrationType,
    sorts: InstantSearchSortOrder[],
    sourceId: string
): InstantSearchSortOrder[] {
    if (!isEmpty(sorts)) {
        return sorts
    }

    let source
    for (const src of integration.sources ?? []) {
        if (src.id === sourceId) {
            source = src
        }
    }

    return [
        {
            title: 'Relevance',
            replicaName: isEmpty(source)
                ? 'Select a source'
                : (source?.config.indexName as string),
            isDefault: true,
        },
    ]
}

export const SortOrdering: FunctionComponent<Props> = (props) => {
    const {
        replicaNames,
        currentSource,
        integration,
        sortOrders,
        setIsChanged,
        onSortOrderChange,
        fetchIndexData,
    } = props
    const [reorderedSortOrders, setReorderedSortOrders] = useState<any[]>(
        setupDefaultSortOrders(integration, sortOrders, currentSource)
    )
    const [isNew, setIsNew] = useState(false)

    function addNewSortOrder(sort: InstantSearchSortOrder): void {
        if (!isEmpty(sort.title) && !isEmpty(sort.replicaName)) {
            setReorderedSortOrders([sort, ...reorderedSortOrders])
            onSortOrderChange([sort, ...reorderedSortOrders])
            setIsChanged(true)
        }
        setIsNew(false)
    }

    function updateSortOrder(
        updatedSortOrder: InstantSearchSortOrder,
        index: number
    ): void {
        const updatedSortOrders = reorderedSortOrders.map(
            (s: InstantSearchSortOrder) => {
                if (s.order === index) {
                    return updatedSortOrder
                }
                return s
            }
        )

        setReorderedSortOrders(updatedSortOrders)
        onSortOrderChange(updatedSortOrders)
    }

    function deleteSortOrder(index: number): void {
        const deletedSortOrders = reorderedSortOrders.filter(
            (s: InstantSearchSortOrder) => s.order !== index
        )

        setReorderedSortOrders(deletedSortOrders)
        onSortOrderChange(deletedSortOrders)
    }

    function createDashboardUrl(): string {
        const source = integration.sources?.filter(
            (src) => src.id === currentSource
        ) as SourceType[]

        if (source?.length < 1) {
            return `https://dashboard.algolia.com/apps/${integration.algolia_app_id}/dashboard`
        }

        return `https://dashboard.algolia.com/apps/${integration.algolia_app_id}/explorer/replicas/${source[0].config.indexName}`
    }

    const StrictModeDroppable = ({
        children,
        ...properties
    }: DroppableProps): any => {
        return <Droppable {...properties}>{children}</Droppable>
    }

    const onDragEnd = useCallback(
        (result: any): void => {
            if (!result.destination) {
                return
            }

            const items = Array.from(reorderedSortOrders)
            const [reorderedItem] = items.splice(result.source.index, 1)
            items.splice(result.destination.index, 0, reorderedItem)

            setReorderedSortOrders(items)
            onSortOrderChange(items)
            setIsChanged(true)
        },
        [reorderedSortOrders]
    )

    useEffect(() => {
        for (const sort of sortOrders) {
            if (!sort.isDefault) {
                // eslint-disable-next-line no-continue
                continue
            }

            let source
            for (const src of integration.sources ?? []) {
                if (src.id === currentSource) {
                    source = src
                }
            }

            sort.replicaName = isEmpty(source)
                ? 'Select a source'
                : (source?.config.indexName as string)
        }
    }, [currentSource])

    return (
        <FlexGrid className={stl`w-full`}>
            <h2
                className={stl`display-subheading`}
                style={{
                    width: '250px',
                }}
            >
                Sort Ordering
            </h2>
            <div className={stl`w-400 flex justify-start flex-col`}>
                <DragDropContext
                    onDragEnd={(result: any): void => onDragEnd(result)}
                >
                    <FlexGrid
                        direction="column"
                        className={stl`w-full`}
                        spacing="sm"
                    >
                        <FlexGrid spacing="sm" alignment="center">
                            <h4 className={stl`display-body`}>
                                <b>Add Sort</b>{' '}
                            </h4>
                            <IconButton
                                disabled={false}
                                variant="subtle"
                                title="Add new facet"
                                icon={PlusCircle}
                                onClick={(): void => {
                                    fetchIndexData()
                                    setIsNew(true)
                                }}
                            />
                        </FlexGrid>
                        <StrictModeDroppable
                            droppableId="droppable"
                            direction="vertical"
                            type="FACET"
                        >
                            {(provided: DroppableProvided): any => (
                                <div
                                    {...provided.innerRef}
                                    ref={provided.innerRef}
                                >
                                    {isNew && (
                                        <SortOrderingCard
                                            replicaNames={replicaNames}
                                            draggableId={Date.now().toString()}
                                            index={0}
                                            isNew={true}
                                            setIsNew={setIsNew}
                                            setIsChanged={setIsChanged}
                                            addNewSortOrder={addNewSortOrder}
                                            updateSortOrder={(): void => {}}
                                            deleteSortOrder={(): void => {}}
                                        />
                                    )}

                                    {reorderedSortOrders?.map(
                                        (
                                            sort: InstantSearchSortOrder,
                                            index: number
                                        ): ReactElement => (
                                            <SortOrderingCard
                                                replicaNames={replicaNames}
                                                key={`${sort.replicaName}-${index}`}
                                                draggableId={`${sort.replicaName}-${index}`}
                                                sortOrder={sort}
                                                index={index}
                                                isNew={false}
                                                setIsNew={setIsNew}
                                                setIsChanged={setIsChanged}
                                                addNewSortOrder={
                                                    addNewSortOrder
                                                }
                                                updateSortOrder={(
                                                    updatedSortOrder: InstantSearchSortOrder,
                                                    order: number
                                                ): void => {
                                                    updateSortOrder(
                                                        updatedSortOrder,
                                                        order
                                                    )
                                                }}
                                                deleteSortOrder={(
                                                    order: number
                                                ): void => {
                                                    deleteSortOrder(order)
                                                }}
                                            />
                                        )
                                    )}

                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </FlexGrid>
                </DragDropContext>
                <p className={stl`display-caption text-grey-600 mt-2`}>
                    {`Manage the replicas for your index in the `}
                    <a target="_blank" href={createDashboardUrl()}>
                        Algolia Dashboard
                    </a>
                </p>
            </div>
        </FlexGrid>
    )
}
