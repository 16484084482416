import { Checkbox, Field } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import BigCommerceService from '../../services/bigCommerce/bigCommerceService'
import type {
    BcCurrenciesResponse,
    BcCurrency,
} from '../../types/bigCommerce/currenciesRespone.type'
import { isEmpty } from '../../utils/utils'
import { useAuth } from '../AuthContext'

type Props = {
    selectedCurrencies?: any
    disabled: boolean
    setSelectedCurrencies: (srcId: any) => void
}

export const CurrencyOptions: FunctionComponent<Props> = (props: Props) => {
    const { platform } = useAuth()
    const { selectedCurrencies, disabled, setSelectedCurrencies } = props

    const [currencies, setCurrencies] = useState<BcCurrenciesResponse>()

    const fetchStoreData = async (): Promise<void> => {
        if (!isEmpty(platform)) {
            setCurrencies(await BigCommerceService.fetchCurrencies())
        }
    }

    useEffect(() => {
        if (typeof currencies === 'undefined') {
            fetchStoreData()
        } else if (currencies.length === 1) {
            setSelectedCurrencies([currencies[0].currency_code.toString()])
        }
    }, [currencies])

    return (
        <Field className="stl-w-full stl-text-left" label="Currencies">
            {!isEmpty(currencies) && (
                <div className="stl-flex stl-flex-col stl-px-8">
                    {currencies?.map((currency: BcCurrency) => {
                        const isOnlyCurrency = currencies.length === 1
                        const isChecked =
                            isOnlyCurrency ||
                            (typeof selectedCurrencies !== 'undefined' &&
                                selectedCurrencies.includes(
                                    currency.currency_code.toString()
                                ))

                        return (
                            <div
                                className="stl-flex stl-flex-col stl-px-8"
                                key={`div-${currency.currency_code}`}
                            >
                                <Checkbox
                                    key={`${currency.currency_code}`}
                                    id={currency.currency_code.toString()}
                                    disabled={disabled}
                                    defaultChecked={isChecked}
                                    onClick={(event: any): void => {
                                        if (event.target.checked) {
                                            if (
                                                !selectedCurrencies.includes(
                                                    currency.currency_code
                                                )
                                            ) {
                                                setSelectedCurrencies([
                                                    ...selectedCurrencies,
                                                    event.target.id,
                                                ])
                                            }
                                        } else {
                                            setSelectedCurrencies(
                                                selectedCurrencies.filter(
                                                    (currencyValue: string) => {
                                                        return (
                                                            currencyValue !==
                                                            event.target.id
                                                        )
                                                    }
                                                )
                                            )
                                        }
                                    }}
                                >
                                    {currency.name}
                                </Checkbox>
                            </div>
                        )
                    })}
                </div>
            )}
            {isEmpty(currencies) && selectedCurrencies.length > 0 && (
                <>
                    {selectedCurrencies?.map((selectedCurrency: any): any => {
                        return (
                            <div
                                className="stl-flex stl-flex-col stl-px-8"
                                key={`div-${selectedCurrency}`}
                            >
                                <Checkbox
                                    key={selectedCurrency}
                                    id={selectedCurrency}
                                    disabled={disabled}
                                    defaultChecked={true}
                                >
                                    {selectedCurrency}
                                </Checkbox>
                            </div>
                        )
                    })}
                </>
            )}
        </Field>
    )
}
