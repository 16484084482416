import { Button, CardHeader, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import type { IntegrationType } from '../../../../types/database/integration.type'

type Props = {
    currentIntegration: IntegrationType | null | undefined
}

export const SearchAnalytics: FunctionComponent<Props> = (props): any => {
    const { currentIntegration } = props

    return (
        <>
            <CardHeader className={stl`px-4 mt-4 mb-2`}>
                <h2 className={stl`display-heading`}>Search Analytics</h2>
            </CardHeader>
            <FlexGrid
                className={`stl-w-full stl-p-6 stl-border-grey`}
                direction="column"
                spacing="lg"
            >
                <FlexGrid className={stl`w-full`}>
                    <p className={`stl-prl-05`}>
                        See your Analytics (like total searches, no results
                        rate, daily searches) and manage AI-features on the
                        Algolia Dashboard.
                    </p>
                </FlexGrid>
                <FlexGrid className={stl`w-full`}>
                    <Button
                        onClick={(): void => {
                            window.open(
                                `https://www.algolia.com/apps/${currentIntegration?.algolia_app_id}/events/debugger`,
                                '_blank',
                                'noopener noreferrer'
                            )
                        }}
                        variant="primary"
                    >
                        Visit Algolia Dashboard
                    </Button>
                </FlexGrid>
            </FlexGrid>
        </>
    )
}
