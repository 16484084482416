import type { Recommend } from '../../types/database/recommend.type'
import type { RecommendVersioning } from '../../types/theming/versioning.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const RecommendService = {
    async createRecommendSettings(
        integrationId: string,
        channel: number,
        data: Recommend
    ): Promise<Recommend> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/recommend`,
            REQUEST_TYPES.post,
            JSON.stringify(data)
        )
    },
    async fetchRecommendSettings(recommend: Recommend): Promise<Recommend> {
        return await makeRequest(
            `/api/v1/integrations/${recommend.integration_id}/channel/${recommend.channel_id}/recommend/${recommend.id}`,
            REQUEST_TYPES.get
        )
    },
    async updateRecommendSettings(recommend: Recommend): Promise<any> {
        const recommendCopy = { ...recommend }
        // eslint-disable-next-line no-param-reassign
        delete recommend.id
        // eslint-disable-next-line no-param-reassign
        delete recommend.integration_id
        // eslint-disable-next-line no-param-reassign
        delete recommend.channel_id
        // eslint-disable-next-line no-param-reassign
        delete recommend.config?.widget_template_id
        // eslint-disable-next-line no-param-reassign
        delete recommend.config?.active_widget_template_version_id
        // eslint-disable-next-line no-param-reassign
        delete recommend.created_at
        // eslint-disable-next-line no-param-reassign
        delete recommend.last_modified
        // eslint-disable-next-line no-param-reassign
        delete recommend.config?.config_script_id
        return await makeRequest(
            `/api/v1/integrations/${recommendCopy.integration_id}/channel/${recommendCopy.channel_id}/recommend/${recommendCopy.id}`,
            REQUEST_TYPES.put,
            JSON.stringify(recommend)
        )
    },
    async applyRecommendSettings(recommend: Recommend): Promise<Recommend> {
        return await makeRequest(
            `/api/v1/integrations/${recommend.integration_id}/channel/${recommend.channel_id}/recommend/${recommend.id}/apply`,
            REQUEST_TYPES.post
        )
    },
    async removeRecommendSettings(recommend: Recommend): Promise<Recommend> {
        return await makeRequest(
            `/api/v1/integrations/${recommend.integration_id}/channel/${recommend.channel_id}/recommend/${recommend.id}/remove`,
            REQUEST_TYPES.delete
        )
    },
    async getRecommendVersioning(
        recommend: Recommend
    ): Promise<RecommendVersioning> {
        return await makeRequest(
            `/api/v1/integrations/${recommend.integration_id}/channel/${recommend.channel_id}/recommend/${recommend.id}/versioning`,
            REQUEST_TYPES.get
        )
    },
}

export default RecommendService
