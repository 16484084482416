import { Link } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import type { IntegrationType } from '../../../types/database/integration.type'
import { isEmpty } from '../../../utils/utils'

type Props = {
    integration: IntegrationType
    channelId: number
}

export const StoreUrl: FunctionComponent<Props> = (props: Props): any => {
    const { integration, channelId } = props
    const [showUrl, setShowUrl] = useState(false)
    const [url, setUrl] = useState<string | undefined>()

    useEffect(() => {
        const channelSources =
            integration?.sources?.filter(
                (src) => src.channel_id === channelId
            ) ?? []

        if (isEmpty(channelSources[0]?.store_url)) {
            setShowUrl(false)
            return
        }

        setShowUrl(true)
        setUrl(channelSources[0].store_url)
    }, [integration, channelId])

    return (
        <>
            {showUrl && (
                <p>
                    <Link href={url as string} target="_blank">
                        {url}
                    </Link>
                </p>
            )}
        </>
    )
}
