import { ButtonLink, Card, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import algolia from '../../assets/images/algolia-logo-blue.svg'

import { Header } from './header/header'

export const NotFound404: FunctionComponent = () => {
    return (
        <>
            <Header />
            <FlexGrid
                className={stl`w-full h-full min-h-screen p-10 bg-grey-50 App-bg`}
                direction="column"
                alignment="center"
                spacing="md"
                wrap={true}
            >
                <Card
                    fullBleed
                    className={stl`max-w-96`}
                    style={{
                        background: 'linear-gradient(#fff,#f5f5fa)',
                        boxShadow:
                            '0 5px 15px 0 rgb(37 44 97 / 15%), 0 2px 4px 0 rgb(93 100 148 / 20%)',
                    }}
                >
                    <FlexGrid
                        className={stl`w-full h-full min-h-screen p-6 bg-grey-50`}
                        direction="column"
                        alignment="center"
                        spacing="sm"
                        wrap={true}
                    >
                        <div
                            style={{ fontSize: '36px' }}
                            className={stl`p-6 pb-2`}
                        >
                            Error 404
                        </div>
                        <div className={stl`typo-display-body p-6`}>
                            Sorry the page you requested was not found
                        </div>

                        <ButtonLink
                            size="large"
                            className={stl`p-6 xenon-button`}
                            style={{ borderRadius: '25px' }}
                            href="/"
                            target="_self"
                        >
                            {`BACK TO INTEGRATIONS`}
                        </ButtonLink>

                        <div className={stl`p-6`}>
                            <div
                                className={stl`typo-display-heading stl-items-center`}
                                style={{ textAlign: 'center' }}
                            >
                                Need Help?
                            </div>
                            <a href="mailto:support@algolia.com">
                                support@algolia.com
                            </a>
                        </div>
                    </FlexGrid>
                </Card>

                <img
                    style={{ height: '30px', marginTop: '48px' }}
                    className="login-logo p-6"
                    src={algolia}
                    alt="Algolia"
                />
            </FlexGrid>
        </>
    )
}
