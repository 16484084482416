import type { InstantSearch } from '../../types/database/instantSearch.type'
import type { InstantsearchDefaultCode } from '../../types/theming/instantsearchDefaultCode.type'
import type { Versioning } from '../../types/theming/versioning.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const InstantsearchService = {
    async createInstantsearchSettings(
        integrationId: string,
        channel: number,
        data: InstantSearch
    ): Promise<InstantSearch> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch`,
            REQUEST_TYPES.post,
            JSON.stringify(data)
        )
    },
    async fetchInstantsearchSettings(
        integrationId: string,
        channel: number,
        instantsearchId: string
    ): Promise<InstantSearch> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}`,
            REQUEST_TYPES.get
        )
    },
    async updateInstantsearchSettings(
        integrationId: string,
        channel: number,
        instantsearchId: string,
        data: InstantSearch
    ): Promise<InstantSearch> {
        const instantsearch = {
            config: data.config,
            css: data.css,
            facets: data.facets,
            is_enabled: data.is_enabled,
            javascript: data.javascript,
            sort_orders: data.sort_orders,
        }
        delete instantsearch.config?.active_widget_version_id
        delete instantsearch.config?.page_id
        delete instantsearch.config?.placement_id
        delete instantsearch.config?.form_script_id
        delete instantsearch.config?.widget_id

        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}`,
            REQUEST_TYPES.put,
            JSON.stringify(instantsearch)
        )
    },
    async applyInstantSearchToTheme(
        integrationId: string,
        channel: number,
        instantsearchId: string
    ): Promise<InstantSearch> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}/apply`,
            REQUEST_TYPES.post
        )
    },
    async removeInstantSearchFromTheme(
        integrationId: string,
        channel: number,
        instantsearchId: string
    ): Promise<InstantSearch> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}/remove`,
            REQUEST_TYPES.delete
        )
    },
    async getInstantsearchDefaultCode(
        integrationId: string,
        channel: number,
        instantsearchId: string
    ): Promise<InstantsearchDefaultCode> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}/default-code`,
            REQUEST_TYPES.get
        )
    },
    async getInstantsearchVersioning(
        integrationId: string,
        channel: number,
        instantsearchId: string
    ): Promise<Versioning> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}/versioning`,
            REQUEST_TYPES.get
        )
    },
    async getReplicas(
        integrationId: string,
        channel: number,
        instantsearchId: string
    ): Promise<string[]> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}/replicas`,
            REQUEST_TYPES.get
        )
    },
    async updateCss(
        integrationId: string,
        channel: number,
        instantsearchId: string,
        cssData: { css: string; config: { css_version: string } }
    ): Promise<string[]> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}/update-css`,
            REQUEST_TYPES.patch,
            JSON.stringify(cssData)
        )
    },
    async updateJavascript(
        integrationId: string,
        channel: number,
        instantsearchId: string,
        javascriptData: {
            javascript: string
            config: { javascript_version: string }
        }
    ): Promise<string[]> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/channel/${channel}/instantsearch/${instantsearchId}/update-javascript`,
            REQUEST_TYPES.patch,
            JSON.stringify(javascriptData)
        )
    },
}

export default InstantsearchService
