import {
    Button,
    Card,
    Field,
    FlexGrid,
    Modal,
    ModalSection,
    Pagination,
    stl,
    Table,
    Toggle,
    Input,
    IconButton,
    ModalFooter,
    TooltipWrapper,
} from '@algolia/satellite'
import * as Sentry from '@sentry/react'
import type { FunctionComponent } from 'react'
import { useState, useEffect } from 'react'
import { Edit, HelpCircle, RefreshCw } from 'react-feather'
import { useParams } from 'react-router-dom'

import BigCommerceService from '../../services/bigCommerce/bigCommerceService'
import type {
    BcCategoriesTreeResponse,
    CategoryIds,
} from '../../types/bigCommerce/categories.type'
import type { Entity } from '../../types/database/entities.type'
import type {
    InstantSearch,
    InstantSearchFacet,
} from '../../types/database/instantSearch.type'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'
import { Loader } from '../common/loader'
import { RegionsDropdown } from '../common/regionsDropdown'
import { FacetList } from '../common/theming/facetList'

type CategoryItem = {
    id: number
    name: string
    isEnabled: boolean
    url?: string
}

type Props = {
    setCategoriesEntity: (categoriesEntity: Entity) => void
    categoriesEntity: Entity
    errors: any
    currentInstantSearch: InstantSearch | undefined
    facetableAttributes: Array<{ value: string; label: string }> | undefined
    setCategoryChanged: (isChanged: boolean) => void
    setIsCategoryIdsChanged: (isChanged: boolean) => void
    handleCategoryFacetUpdate: (selectedCategoryId: number) => void
    handleCategoryToggle: (selectedCategoryId: number) => void
    setUpdatedCategoryFacets: (updatedFacets: InstantSearchFacet[]) => void
    updatedCategoryFacets: InstantSearchFacet[]
    setIsCategoryModalOpen: (isOpen: boolean) => void
    isCategoryModalOpen: boolean
    isFromAdminView: () => boolean
    setCategoryFacetChange: (isChanged: boolean) => void
    isCategoryFacetChanged: boolean
    hasMainToggleChanged: boolean
    setHasMainToggleChanged: (state: boolean) => void
    fetchIndexData: () => Promise<void>
    setDeletedCategoryIds: (deletedCategoryIds: number[]) => void
}

export const Categories: FunctionComponent<Props> = (props) => {
    const {
        setCategoriesEntity,
        categoriesEntity,
        errors,
        currentInstantSearch,
        facetableAttributes,
        setCategoryChanged,
        setIsCategoryIdsChanged,
        handleCategoryFacetUpdate,
        handleCategoryToggle,
        setUpdatedCategoryFacets,
        updatedCategoryFacets,
        setIsCategoryModalOpen,
        isCategoryModalOpen,
        isFromAdminView,
        setCategoryFacetChange,
        isCategoryFacetChanged,
        hasMainToggleChanged,
        setHasMainToggleChanged,
        fetchIndexData,
        setDeletedCategoryIds,
    } = props

    const [displayedCategories, setDisplayedCategories] = useState<
        CategoryItem[]
    >([])
    const [categoriesLength, setCategoriesLength] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedCategory, setSelectedCategory] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [categoryLoading, setCategoryLoading] = useState<{
        [key: number]: boolean
    }>({})
    const [isHovered, setIsHovered] = useState(false)
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0)
    const [categoryError, setCategoryError] = useState<any>(null)
    const [categoriesArray, setCategoriesArray] = useState<CategoryItem[]>([])
    const [searchTerm, setSearchTerm] = useState('')

    const { showErrorAlert } = useAlert()
    const { platform } = useAuth()
    const { channelId } = useParams()

    const itemsPerPage = 5

    const updateCategoryIds = async (): Promise<CategoryIds> => {
        if (!categoriesEntity?.config) {
            return {}
        }

        if (!channelId) {
            return categoriesEntity.config.category_ids
        }

        const response: BcCategoriesTreeResponse[] =
            await BigCommerceService.fetchCategories(parseInt(channelId, 10))

        const updatedCategoryIds: CategoryIds = {}

        const deletedCategoryIds: number[] = []
        const responseCategoryIds = new Set<number>()

        response.forEach((category: BcCategoriesTreeResponse) => {
            const categoryId = category.category_id as number
            const categoryName = category.name as string
            responseCategoryIds.add(categoryId)

            if (
                categoriesEntity?.config &&
                categoryId in categoriesEntity.config.category_ids
            ) {
                updatedCategoryIds[categoryId] = {
                    ...categoriesEntity.config.category_ids[categoryId],
                    name: categoryName,
                }
            } else {
                updatedCategoryIds[categoryId] = {
                    name: categoryName,
                    isEnabled: true,
                    facets: [],
                }
            }
        })

        if (categoriesEntity?.config) {
            Object.keys(categoriesEntity.config.category_ids).forEach(
                (categoryIdStr) => {
                    const categoryId = parseInt(categoryIdStr, 10)
                    if (!responseCategoryIds.has(categoryId)) {
                        deletedCategoryIds.push(categoryId)
                    }
                }
            )
        }

        setDeletedCategoryIds(deletedCategoryIds)
        setIsCategoryIdsChanged(true)
        return updatedCategoryIds
    }

    useEffect(() => {
        if (!categoriesEntity?.config) {
            return
        }

        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage

        const categoriesObject = categoriesEntity.config.category_ids

        // In order to support pagination we must convert the map to an array with id, name, status
        const categoriesWithIds = Object.entries(categoriesObject).map(
            ([id, details]: any) => {
                return { id: parseInt(id, 10), ...details }
            }
        )

        // Sort categories alphabetically by name
        categoriesWithIds.sort((a, b) => a.name.localeCompare(b.name))

        setCategoriesArray(categoriesWithIds)

        // Filter categories by search term
        const filteredCategories = categoriesWithIds.filter((category) =>
            category.name.toLowerCase().includes(searchTerm.toLowerCase())
        )

        const slicedCategories = filteredCategories.slice(startIndex, endIndex)

        setCategoriesLength(filteredCategories.length)
        setDisplayedCategories(slicedCategories)
    }, [categoriesEntity, currentPage, searchTerm])

    useEffect(() => {
        if (categoryError) {
            Sentry.captureException(categoryError)
        }
    }, [categoryError])

    const handleRefresh = async (): Promise<void> => {
        try {
            setLoading(true)

            const updatedCategoryEntity = { ...categoriesEntity }

            if (!updatedCategoryEntity?.config) {
                return
            }

            const refreshCategoryIds = await updateCategoryIds()

            if (!refreshCategoryIds) {
                return
            }

            updatedCategoryEntity.config.category_ids = refreshCategoryIds

            setCategoriesEntity(updatedCategoryEntity)
        } catch (error) {
            setCategoryError(error)
            showErrorAlert('Unable to refresh categories')
        } finally {
            setLoading(false)
        }
    }

    const handleMainCategoryToggle = async (
        event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        if (typeof categoriesEntity.config === 'undefined') {
            showErrorAlert('Unable to toggle categories')
            return
        }
        try {
            setLoading(true)

            categoriesEntity.config.isEnabled = event.target.checked

            if (event.target.checked) {
                const refreshCategoryIds = await updateCategoryIds()

                if (!refreshCategoryIds) {
                    return
                }

                categoriesEntity.config.category_ids = refreshCategoryIds

                setIsCategoryIdsChanged(true)
                setCategoriesEntity(categoriesEntity)
                return
            }

            setIsCategoryIdsChanged(false)
        } catch (error) {
            setCategoryError(error)
            showErrorAlert('Unable to toggle categories')
        } finally {
            setLoading(false)
            setHasMainToggleChanged(true)
            setCategoriesEntity(categoriesEntity)
        }
    }

    const handleCategoryRegionUpdate = (placement_region: string): void => {
        try {
            const updatedCategoryEntity = { ...categoriesEntity }

            if (!updatedCategoryEntity.config) {
                return
            }

            updatedCategoryEntity.config.placement_region = placement_region

            setCategoryChanged(true)
            setCategoriesEntity(updatedCategoryEntity)
        } catch (error) {
            setCategoryError(error)
            showErrorAlert(
                'Unable to update category placement region',
                placement_region
            )
        }
    }

    const handleCategorySelectorUpdate = (
        category_css_selector: string
    ): void => {
        try {
            const updatedCategoryEntity = { ...categoriesEntity }

            if (!updatedCategoryEntity.config) {
                return
            }

            updatedCategoryEntity.config.css_selector = category_css_selector

            setCategoryChanged(true)
            setCategoriesEntity(updatedCategoryEntity)
        } catch (error) {
            setCategoryError(error)
            showErrorAlert(
                'Unable to update category search CSS selector',
                category_css_selector
            )
        }
    }

    function getTooltipContent(): string {
        if (
            currentInstantSearch === undefined ||
            currentInstantSearch.id === undefined
        ) {
            return 'You must configure InstantSearch before editing category facets.'
        }
        if (typeof categoriesEntity.id === 'undefined') {
            return 'You must save InstantSearch before editing category facets for the first time.'
        }
        if (hasMainToggleChanged) {
            return 'You must save InstantSearch before editing category facets.'
        }
        return ''
    }

    function isInstantSearchOutdated(): boolean {
        if (
            typeof currentInstantSearch?.config?.javascript_version ===
                'undefined' &&
            typeof currentInstantSearch?.config?.css_version === 'undefined'
        ) {
            return true
        }

        if (
            currentInstantSearch?.config?.javascript_version &&
            currentInstantSearch?.config?.css_version &&
            (currentInstantSearch?.config?.javascript_version < '1.12' ||
                currentInstantSearch?.config?.css_version < '1.12') &&
            typeof platform !== 'undefined'
        ) {
            return true
        }

        return false
    }

    function shouldCategoriesBeDisabled(): boolean {
        if (
            hasMainToggleChanged ||
            categoriesEntity?.config?.isEnabled === false ||
            currentInstantSearch === undefined ||
            currentInstantSearch.id === undefined ||
            typeof categoriesEntity.id === 'undefined'
        ) {
            return true
        }
        return false
    }

    return (
        <>
            <Modal
                open={isCategoryModalOpen}
                title={`${selectedCategory} (ID ${selectedCategoryId}) - Category Facets`}
                animate={true}
                onDismiss={(): void => {
                    setIsCategoryModalOpen(false)
                }}
                centerY={false}
                fullBleed={true}
                size="medium"
                autoFocusOnOpenElement={false}
            >
                <ModalSection>
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-ml-23 stl-source-header stl-pb-10"
                    >
                        <FacetList
                            facetableAttributes={
                                facetableAttributes?.filter((item) => {
                                    const facets =
                                        categoriesEntity?.config?.category_ids[
                                            selectedCategoryId
                                        ]?.facets?.concat(updatedCategoryFacets)

                                    if (!facets || facets.length === 0) {
                                        return true
                                    }

                                    return !facets
                                        .map(
                                            (usedItem: {
                                                attribute: string
                                            }): string => usedItem.attribute
                                        )
                                        .includes(item.value)
                                }) ?? []
                            }
                            isFromAdminView={isFromAdminView}
                            setIsChanged={setCategoryFacetChange}
                            facets={
                                categoriesEntity?.config?.category_ids[
                                    selectedCategoryId
                                ]?.facets ?? []
                            }
                            onFacetChange={(
                                updatedFacets: InstantSearchFacet[]
                            ): void => {
                                setUpdatedCategoryFacets(updatedFacets)
                            }}
                            fetchIndexData={fetchIndexData}
                        />
                    </FlexGrid>
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-ml-23 stl-pl-23"
                    >
                        <p className="stl-muted-text">
                            If a category does not have any facets it will
                            inherit the default InstantSearch facets.
                        </p>
                    </FlexGrid>
                </ModalSection>
                <ModalFooter>
                    <FlexGrid className={stl`w-full mb-4`} distribution="fill">
                        <FlexGrid className={stl`pl-23`} distribution="leading">
                            <Button
                                variant="primary"
                                disabled={
                                    isFromAdminView() || !isCategoryFacetChanged
                                }
                                onClick={(): void => {
                                    handleCategoryFacetUpdate(
                                        selectedCategoryId
                                    )
                                }}
                                loading={loading}
                            >
                                Save
                            </Button>
                        </FlexGrid>
                    </FlexGrid>
                </ModalFooter>
            </Modal>
            <FlexGrid className={stl`w-full`}>
                <h2
                    className={stl`display-subheading`}
                    style={{ width: '250px' }}
                >
                    Categories
                </h2>
                <Card fullBleed style={{ width: 600 }}>
                    <Card.Header
                        className={stl`p-6 flex items-center border-b border-grey-200`}
                    >
                        <div>
                            <Card.Title>InstantSearch Categories</Card.Title>
                            <p className={stl`display-caption`}>
                                This will enable or disable InstantSearch for
                                all categories. Or you can toggle InstantSearch
                                for individual categories below. InstantSearch
                                must be enabled in addition to IS Categories.
                                {isInstantSearchOutdated() &&
                                    typeof currentInstantSearch?.id !==
                                        'undefined' && (
                                        <b>
                                            {' '}
                                            Please upgrade InstantSearch
                                            JavaScript & CSS to v1.12 or greater
                                            to enable InstantSearch categories.
                                        </b>
                                    )}
                                {typeof currentInstantSearch?.id ===
                                    'undefined' && (
                                    <b>
                                        {' '}
                                        You must save InstantSearch before
                                        enabling InstantSearch categories.
                                    </b>
                                )}
                            </p>
                            <br></br>
                            <Input
                                type="search"
                                variant="small"
                                value={searchTerm}
                                onChange={(e): void => {
                                    setSearchTerm(e.target.value)
                                }}
                                placeholder="Search categories..."
                                clearable
                                className="stl-w-350"
                                disabled={shouldCategoriesBeDisabled()}
                            />
                        </div>
                        <div style={{ paddingRight: '0.5rem' }}>
                            <Toggle
                                defaultChecked={false}
                                checked={
                                    categoriesEntity?.config?.isEnabled ?? false
                                }
                                disabled={
                                    isInstantSearchOutdated() ||
                                    isFromAdminView()
                                }
                                onChange={(e): void => {
                                    handleMainCategoryToggle(e)
                                }}
                            ></Toggle>
                        </div>
                        <div>
                            <IconButton
                                icon={RefreshCw}
                                title="Fetch the latest categories from BigCommerce"
                                variant="subtle"
                                disabled={
                                    (!categoriesEntity?.config?.isEnabled ||
                                        isFromAdminView()) ??
                                    true
                                }
                                onClick={(): void => {
                                    handleRefresh()
                                }}
                                onMouseEnter={(): void => setIsHovered(true)}
                                onMouseLeave={(): void => setIsHovered(false)}
                                showTooltip={isHovered}
                            />
                        </div>
                    </Card.Header>
                    {loading && (
                        <div>
                            <br />
                            <Loader />
                            <br />
                        </div>
                    )}
                    {!loading && (
                        <Table
                            footer={`Showing ${displayedCategories.length} of ${categoriesLength} categories`}
                        >
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th
                                        className="text-right"
                                        style={{ paddingRight: '2.5rem' }}
                                    >
                                        Facets
                                    </th>
                                    <th className="text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedCategories.map(
                                    (category: CategoryItem) => {
                                        const isDuplicate =
                                            categoriesArray.some(
                                                (c) =>
                                                    c.name === category.name &&
                                                    c.id !== category.id
                                            )

                                        return (
                                            <tr key={category.id}>
                                                <td className="text-left">
                                                    {isDuplicate ? (
                                                        <TooltipWrapper
                                                            delay={250}
                                                            content={`Duplicate category name. ID for category: ${category.id}`}
                                                            align={'start'}
                                                            alignOffset={80}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    marginBottom:
                                                                        '5px',
                                                                }}
                                                            >
                                                                {category.name}
                                                                <HelpCircle
                                                                    size={
                                                                        '14px'
                                                                    }
                                                                    color="blue"
                                                                    className={stl`ml-5`}
                                                                ></HelpCircle>
                                                            </div>
                                                        </TooltipWrapper>
                                                    ) : (
                                                        category.name
                                                    )}
                                                </td>
                                                <td className="text-right">
                                                    <TooltipWrapper
                                                        delay={250}
                                                        content={getTooltipContent()}
                                                    >
                                                        <Button
                                                            onClick={async (): Promise<void> => {
                                                                await fetchIndexData()
                                                                setSelectedCategory(
                                                                    category.name
                                                                )
                                                                setIsCategoryModalOpen(
                                                                    true
                                                                )
                                                                setSelectedCategoryId(
                                                                    category.id
                                                                )
                                                            }}
                                                            startIcon={Edit}
                                                            className="mb-10"
                                                            size="medium"
                                                            variant="neutral"
                                                            disabled={shouldCategoriesBeDisabled()}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </TooltipWrapper>
                                                </td>
                                                <td className="text-right">
                                                    <div className="flex justify-end">
                                                        {categoryLoading[
                                                            category.id
                                                        ] ? (
                                                            <Loader
                                                                size={18}
                                                                thickness={2}
                                                                align={'left'}
                                                            />
                                                        ) : (
                                                            <Toggle
                                                                checked={
                                                                    category.isEnabled
                                                                }
                                                                disabled={
                                                                    (!categoriesEntity
                                                                        ?.config
                                                                        ?.isEnabled ??
                                                                        true) ||
                                                                    isFromAdminView() ||
                                                                    Object.keys(
                                                                        categoryLoading
                                                                    ).length >=
                                                                        1 ||
                                                                    shouldCategoriesBeDisabled()
                                                                }
                                                                onChange={async (): Promise<void> => {
                                                                    setCategoryLoading(
                                                                        (
                                                                            prev
                                                                        ) => ({
                                                                            ...prev,
                                                                            [category.id]:
                                                                                true,
                                                                        })
                                                                    )
                                                                    try {
                                                                        await handleCategoryToggle(
                                                                            category.id
                                                                        )
                                                                    } finally {
                                                                        setCategoryLoading(
                                                                            {}
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </Table>
                    )}
                    {!loading &&
                        Math.ceil(categoriesLength / itemsPerPage) > 1 && (
                            <>
                                <div
                                    className={stl`flex justify-center bg-grey-100 pb-2`}
                                >
                                    <Pagination
                                        onChange={(newPage: number): void => {
                                            setCurrentPage(newPage)
                                        }}
                                        nbPages={Math.ceil(
                                            categoriesLength / itemsPerPage
                                        )}
                                        currentPage={currentPage}
                                        maxButtons={5}
                                    />
                                </div>
                            </>
                        )}
                </Card>
            </FlexGrid>
            <FlexGrid className={stl`w-full`}>
                <h2
                    className={stl`display-subheading`}
                    style={{ width: '250px' }}
                >
                    Categories Placement Region
                </h2>
                <Field
                    description={
                        <span>
                            Placement region to place InstantSearch Categories
                            for your theme. <br></br>
                            Required when InstantSearch Categories are enabled.
                        </span>
                    }
                    state={{
                        errors: [errors.category_region],
                        status: errors.category_region ? 'invalid' : 'default',
                    }}
                >
                    <RegionsDropdown
                        channelId={parseInt(channelId!, 10)}
                        templateFile="pages/category"
                        setSelectedPlacementRegion={handleCategoryRegionUpdate}
                        selectedPlacementRegion={
                            categoriesEntity?.config?.placement_region ??
                            'category_below_header'
                        }
                        disabled={
                            shouldCategoriesBeDisabled() ||
                            categoriesEntity?.config?.isEnabled === false
                        }
                    ></RegionsDropdown>
                </Field>
            </FlexGrid>
            <FlexGrid className={stl`w-full`}>
                <h2
                    className={stl`display-subheading`}
                    style={{
                        width: '250px',
                    }}
                >
                    Categories CSS Selector
                </h2>
                <div className={stl`w-400 flex justify-start`}>
                    <Field
                        className={stl`w-full`}
                        description={
                            <span>
                                CSS Selector for hiding the default page content
                                search. You might have to change this value to
                                an element selector that works for your theme.
                                This field is case sensitive and required when
                                InstantSearch Categories is enabled.
                            </span>
                        }
                        state={{
                            errors: [errors.category_css_selector],
                            status: errors.category_css_selector
                                ? 'invalid'
                                : 'default',
                        }}
                    >
                        <Input
                            variant="small"
                            placeholder="page"
                            value={categoriesEntity?.config?.css_selector}
                            onChange={(event: any): void =>
                                handleCategorySelectorUpdate(event.target.value)
                            }
                            disabled={
                                shouldCategoriesBeDisabled() ||
                                categoriesEntity?.config?.isEnabled === false
                            }
                        />
                    </Field>
                </div>
            </FlexGrid>
        </>
    )
}
