import { BigBertha } from '@algolia/satellite'
import { isEmpty } from 'lodash'
import type { FunctionComponent } from 'react'

import { useAuth } from '../AuthContext'

export const NotificationBanner: FunctionComponent = (): any => {
    const auth = useAuth()
    const { platform } = auth
    const minInstantSearchVersionAllowed = '1.15'

    function shouldDisplayPolyFillBanner(): boolean {
        let isValid = false

        if (window.location.pathname === '/auth') {
            return isValid
        }

        for (const instantsearch of platform?.integrations?.instantsearch ??
            []) {
            if (
                !isEmpty(instantsearch.config.javascript_version) &&
                instantsearch.is_enabled &&
                instantsearch.config.javascript_version <
                    minInstantSearchVersionAllowed
            ) {
                isValid = true
            }
        }

        return isValid
    }

    return (
        <>
            {shouldDisplayPolyFillBanner() && (
                <BigBertha>
                    <div style={{ textAlign: 'center' }}>
                        We have released a security update for your
                        Instantsearch javascript template. We highly encourage
                        you to upgrade your templates to the latest version. To
                        learn more on how to update your template, please read
                        our{' '}
                        <a
                            style={{
                                color: 'white',
                                textDecoration: 'underline',
                            }}
                            target="_blank"
                            href="https://www.algolia.com/doc/integration/bigcommerce/search-settings/instantsearch/"
                        >
                            documentation.
                        </a>
                    </div>
                </BigBertha>
            )}
        </>
    )
}
