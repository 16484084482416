import { CardHeader, Checkbox, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import IntegrationService from '../../../../services/database/integrationService'
import InsightsService from '../../../../services/theming/insightsService'
import type { AutoComplete } from '../../../../types/database/autoComplete.type'
import type { Insight } from '../../../../types/database/insight.type'
import type { InstantSearch } from '../../../../types/database/instantSearch.type'
import type { IntegrationType } from '../../../../types/database/integration.type'
import type { InsightsVersioning } from '../../../../types/theming/versioning.type'
import { useAlert } from '../../../AlertContext'
import { CodeUpdateAvailableButtonWithBadge } from '../../../common/theming/codeUpdateAvailableButtonWithBadge'

type Props = {
    currentIntegration: IntegrationType
    currentInsights: Insight
    setCurrentInsights: (insights: Insight) => void
}

export const BigOpenDataLayerAnalytics: FunctionComponent<Props> = (
    props
): any => {
    const { currentIntegration, currentInsights, setCurrentInsights } = props
    const { showSuccessAlert, showErrorAlert } = useAlert()

    const [isLoading, setIsLoading] = useState(false)
    const [autocomplete, setAutocomplete] = useState<AutoComplete | undefined>()
    const [instantsearch, setInstantsearch] = useState<
        InstantSearch | undefined
    >()
    const [versioning, setVersioning] = useState<InsightsVersioning>()

    async function fetchInsightsVersioning(): Promise<void> {
        setVersioning(
            await InsightsService.getInsightsVersioning(currentInsights)
        )
    }

    async function findAutocompleteAndInstantsearch(): Promise<void> {
        const integration = await IntegrationService.fetch(
            currentIntegration.id as string
        )

        const is = integration?.instantsearch?.find(
            (iS: InstantSearch) => iS.channel_id === currentInsights?.channel_id
        ) as InstantSearch
        setInstantsearch(is ?? undefined)

        const ac = integration?.autocomplete?.find(
            (aC: AutoComplete) => aC.channel_id === currentInsights?.channel_id
        ) as InstantSearch
        setAutocomplete(ac ?? undefined)
    }

    function validateIfBodlAllowed(): boolean {
        if (
            typeof autocomplete !== 'undefined' &&
            autocomplete.is_enabled &&
            typeof autocomplete?.config?.javascript_version !== 'undefined' &&
            typeof versioning?.minAutocompleteVersionRequired !== 'undefined' &&
            autocomplete?.config?.javascript_version <
                versioning.minAutocompleteVersionRequired
        ) {
            showErrorAlert(
                'Autocomplete version must be upgraded before enabling',
                'Error'
            )

            return false
        }

        if (
            typeof instantsearch !== 'undefined' &&
            instantsearch.is_enabled &&
            typeof instantsearch?.config?.javascript_version !== 'undefined' &&
            typeof versioning?.minInstantsearchVersionRequired !==
                'undefined' &&
            instantsearch?.config?.javascript_version <
                versioning.minInstantsearchVersionRequired
        ) {
            showErrorAlert(
                'Instantsearch version must be upgraded before enabling',
                'Error'
            )

            return false
        }

        return true
    }

    function updateBodlVersion(): void {
        setIsLoading(true)
        InsightsService.updateInsightsSettings(
            currentInsights?.integration_id as string,
            {
                ...currentInsights,
                config: {
                    ...currentInsights.config,
                    current_bodl_version: versioning?.latestVersion,
                },
            } as Insight
        )
            .then((data) => {
                if (typeof data.error !== 'undefined') {
                    showErrorAlert(data.error, 'Error')
                    setIsLoading(false)

                    return
                }
                setCurrentInsights(data)
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
                showErrorAlert(
                    'There was an error upgrading your Big Open Data Layer settings.',
                    'Error'
                )
            })
            .finally(() => {
                setIsLoading(false)
                showSuccessAlert(
                    'Big Open Data Layer version updated',
                    'Success'
                )
            })
    }

    useEffect(() => {
        fetchInsightsVersioning()
    }, [])

    useEffect(() => {
        if (typeof currentIntegration !== 'undefined') {
            findAutocompleteAndInstantsearch()
        }
    }, [currentIntegration])

    return (
        <>
            <CardHeader className={stl`px-4 mt-4 mb-2`}>
                <h2 className={stl`display-heading`}>
                    Big Open Data Layer Analytics
                </h2>
            </CardHeader>
            <FlexGrid
                className={`stl-w-full stl-p-6`}
                direction="column"
                spacing="lg"
            >
                <FlexGrid className={stl`w-full`}>
                    <p className={`stl-prl-05`}>
                        <CodeUpdateAvailableButtonWithBadge
                            showUpdate={
                                currentInsights?.config
                                    ?.is_data_layer_enabled ?? false
                            }
                            version={
                                currentInsights?.config?.current_bodl_version
                            }
                            versioning={versioning as InsightsVersioning}
                            isLoading={isLoading}
                            update={updateBodlVersion}
                        />
                    </p>
                </FlexGrid>
                <FlexGrid className={stl`w-full`}>
                    <p className={`stl-prl-05`}>
                        {`Enhance store insights by tracking critical customer
                        actions like adding items to carts or completing
                        purchases. This data helps you identify best-selling
                        products and optimize your inventory. Learn more about
                        how this works `}
                        <a
                            href="https://developer.bigcommerce.com/docs/integrations/hosted-analytics"
                            target="_blank"
                        >
                            Big Open Data Layer
                        </a>
                    </p>
                </FlexGrid>
                <FlexGrid className={stl`w-full`}>
                    <Checkbox
                        disabled={!currentInsights?.is_enabled}
                        checked={currentInsights?.config?.is_data_layer_enabled}
                        onChange={(): void => {
                            if (!validateIfBodlAllowed()) {
                                setCurrentInsights({
                                    ...(currentInsights as Insight),
                                    config: {
                                        ...currentInsights?.config,
                                        is_data_layer_enabled: false,
                                    },
                                })
                                return
                            }

                            if (
                                typeof currentInsights.config
                                    ?.current_bodl_version === 'undefined'
                            ) {
                                setCurrentInsights({
                                    ...(currentInsights as Insight),
                                    config: {
                                        ...currentInsights?.config,
                                        is_data_layer_enabled:
                                            !currentInsights?.config
                                                ?.is_data_layer_enabled,
                                        current_bodl_version:
                                            versioning?.latestVersion,
                                    },
                                })
                            } else {
                                setCurrentInsights({
                                    ...(currentInsights as Insight),
                                    config: {
                                        ...currentInsights?.config,
                                        is_data_layer_enabled:
                                            !currentInsights?.config
                                                ?.is_data_layer_enabled,
                                    },
                                })
                            }
                        }}
                    >
                        <div className={stl`cursor-pointer`}>
                            Use data layer analytics
                        </div>
                    </Checkbox>
                </FlexGrid>
            </FlexGrid>
        </>
    )
}
