import { FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { Info } from 'react-feather'

export const DefaultTimeZone: FunctionComponent = (): any => {
    return (
        <FlexGrid distribution="trailing">
            <span
                className={stl`flex items-center space-x-1 display-caption typo-subdued p-6`}
            >
                <Info size={12} />
                <span>Default timezone is UTC</span>
            </span>
        </FlexGrid>
    )
}
