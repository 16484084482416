import type { CategoriesPatch } from '../../types/bigCommerce/categories.type'
import type { Entity } from '../../types/database/entities.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const EntityService = {
    async createEntity(integrationId: string, entity: Entity): Promise<Entity> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${entity.instantsearch_id}/entity`,
            REQUEST_TYPES.post,
            JSON.stringify(entity)
        )
    },
    async updateEntity(integrationId: string, entity: Entity): Promise<Entity> {
        // eslint-disable-next-line no-param-reassign
        delete entity.last_modified

        // eslint-disable-next-line no-param-reassign
        delete entity.created_at

        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${entity.instantsearch_id}/entity/${entity.id}`,
            REQUEST_TYPES.put,
            JSON.stringify(entity)
        )
    },
    async patchEntity(
        integrationId: string,
        entity: Entity,
        data: CategoriesPatch
    ): Promise<Entity> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${entity.instantsearch_id}/entity/${entity.id}`,
            REQUEST_TYPES.patch,
            JSON.stringify(data)
        )
    },

    async applyEntity(integrationId: string, data: Entity): Promise<Entity> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${data.instantsearch_id}/entity/${data.id}/apply`,
            REQUEST_TYPES.post
        )
    },
    async removeEntity(integrationId: string, data: Entity): Promise<Entity> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${data.instantsearch_id}/entity/${data.id}/remove`,
            REQUEST_TYPES.delete
        )
    },
}

export default EntityService
