import { FlexGrid, Button, Card } from '@algolia/satellite'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import algolia from '../assets/images/algolia-logo-blue.svg'

import { useAuth } from './AuthContext'
import { Loader } from './common/loader'

export const Login: React.FunctionComponent = () => {
    const { user } = useAuth()
    const [showError, setShowError] = useState(false)

    if (user === undefined) {
        return (
            <>
                <Loader />
            </>
        )
    }

    if (user) {
        return <Navigate to={'/integrations'} />
    }

    function login(): void {
        window.location.replace('/api/v1/login')
    }

    function signout(): void {
        const windowParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100`
        window.open(
            'https://www.algolia.com/users/sign_out',
            'appex',
            windowParams
        )
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.get('failure')) {
            setShowError(true)
        }
    })

    return (
        <>
            <div
                style={{ paddingTop: '200px' }}
                className="stl-w-full stl-h-full stl-flex stl-justify-center stl-min-h-screen stl-p-6"
            >
                <FlexGrid
                    className="login-container"
                    direction="column"
                    alignment="center"
                    spacing="xl"
                    distribution="fill"
                >
                    <img
                        style={{ width: '250px', height: 'auto' }}
                        className="login-logo "
                        src={algolia}
                        alt="Algolia"
                    />
                    <Card className="stl-w-350">
                        <FlexGrid
                            direction="column"
                            spacing="sm"
                            alignment="center"
                        >
                            <h1 className="stl-display-large stl-text-left stl-font-bold stl-text-grey-900 stl-mb-2">
                                Sign in with Algolia
                            </h1>
                            <Button
                                className="stl-xenon-button"
                                size="large"
                                onClick={(): void => {
                                    login()
                                }}
                            >
                                Sign in
                            </Button>
                            {showError && (
                                <p style={{ color: 'red', fontSize: '10px' }}>
                                    No access allowed.{' '}
                                    <span
                                        style={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={signout}
                                    >
                                        Sign out
                                    </span>{' '}
                                    and try again.
                                </p>
                            )}
                        </FlexGrid>
                    </Card>
                </FlexGrid>
            </div>
        </>
    )
}
