import { FlexGrid, Button, Card, stl, BigBertha } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AlgoliaService from '../../services/database/algoliaService'
import IntegrationService from '../../services/database/integrationService'
import { PLATFORMS } from '../../utils/platforms'
import { isEmpty } from '../../utils/utils'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'
import { AlgoliaLoginVerify } from '../common/AlgoliaLoginVerify'
import { AlgoliaAppListDropDown } from '../common/algoliaAppListDropDown'

export const SetupApplication: FunctionComponent = () => {
    const params = useParams()
    const navigate = useNavigate()

    const { integrationId } = params
    const { isPlatformAuthed, platform } = useAuth()
    const { showErrorAlert } = useAlert()

    const [selectedApplication, setSelectedApplication] = useState('')
    const [isApplicationAllowed, setIsApplicationAllowed] = useState(false)
    const [showBanner, setShowBanner] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = React.useState({
        algolia_app_id: '',
        indexName: '',
    })

    function validate(): boolean {
        let isValid = true
        const newErrors = {
            algolia_app_id: '',
            indexName: '',
            recordType: '',
        }

        if (isEmpty(integrationId) && selectedApplication === '') {
            newErrors.algolia_app_id = 'Algolia App ID is required'
            isValid = false
        }

        return isValid
    }

    function createIntegration(): void {
        setLoading(true)
        IntegrationService.create({
            platform: PLATFORMS.bigcommerce,
            algolia_app_id: selectedApplication,
            config: {},
        })
            .then((integrationResponse) => {
                if (typeof integrationResponse.id === 'undefined') {
                    showErrorAlert(
                        'There was an error creating your integration',
                        'Error'
                    )
                }

                navigate(`/bigcommerce/${integrationResponse.id}/channels`)
            })
            .catch((err: any) => {
                setLoading(false)
                setErrors({ ...errors, ...err })
                let errorMessage = 'There was an error saving your integration'
                if (
                    !isEmpty(err) &&
                    (err.includes('required to be ugraded') ||
                        err.includes('Invalid session') ||
                        err.includes(
                            'maximum number of Authentications reached'
                        ))
                ) {
                    errorMessage = err
                }
                showErrorAlert(errorMessage, 'Error')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAlgoliaAppPlan = async (appId: string): Promise<void> => {
        setLoading(true)
        await AlgoliaService.isBigCommerceAppAllowed(appId)
            .then(() => {
                setIsApplicationAllowed(true)
            })
            .catch(() => {
                setIsApplicationAllowed(false)
            })
        setLoading(false)
        setShowBanner(true)
    }

    useEffect(() => {
        if (!isEmpty(selectedApplication)) {
            getAlgoliaAppPlan(selectedApplication)
        }
    }, [selectedApplication])

    useEffect((): void => {
        if (!isEmpty(platform) && platform.integration_id !== null) {
            navigate(`/bigcommerce/${platform.integration_id}/channels`)
        }
    })

    return (
        <>
            {!isEmpty(selectedApplication) &&
                !isApplicationAllowed &&
                showBanner && (
                    <BigBertha>
                        {`Please select another application or upgrade your application to a `}
                        <a
                            target="_blank"
                            className="stl-plan-bertha"
                            href={`https://www.algolia.com/pricing/`}
                        >
                            premium or elevate plan.
                        </a>{' '}
                    </BigBertha>
                )}
            <FlexGrid
                className="stl-w-full stl-h-full stl-flex stl-justify-center stl-min-h-screen stl-p-6 stl-app-bg"
                direction="column"
                alignment="center"
                spacing="md"
            >
                <Card fullBleed>
                    <AlgoliaLoginVerify />
                    <FlexGrid
                        direction="column"
                        alignment="center"
                        spacing="md"
                        className={stl`p-6`}
                    >
                        <h1 className="stl-display-heading stl-text-grey-900">
                            Configure your Algolia application
                        </h1>

                        <FlexGrid
                            className="stl-w-25 stl-w-full stl-mb-10 stl-p-2"
                            direction="column"
                            alignment="center"
                            spacing="md"
                        >
                            <span className="stl-w-25 stl-text-grey-700 stl-text-center">
                                Select the Algolia application you want to use.
                                <p className="stl-w-25 stl-text-grey-700 stl-text-center">
                                    Only applications on{' '}
                                    <a
                                        target="_blank"
                                        href={`https://www.algolia.com/pricing/`}
                                    >
                                        build, premium or elevate plans
                                    </a>{' '}
                                    allowed.
                                </p>
                            </span>

                            <AlgoliaAppListDropDown
                                setSelectedApplication={setSelectedApplication}
                                isPlatformAuthed={isPlatformAuthed}
                                isDisabled={true}
                            />

                            <Button
                                className="stl-w-full stl-xenon-button"
                                size="large"
                                loading={loading}
                                disabled={
                                    selectedApplication === '' ||
                                    (!isEmpty(selectedApplication) &&
                                        !isApplicationAllowed)
                                }
                                onClick={(): void => {
                                    if (validate()) {
                                        createIntegration()
                                    }
                                }}
                            >
                                Next step: Channel selection
                            </Button>
                        </FlexGrid>
                    </FlexGrid>
                </Card>
            </FlexGrid>
        </>
    )
}
