import { Input, DataTable, Field, IconButton } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Edit, Save, Trash, X } from 'react-feather'

import ConnectionsService from '../../services/connections/connectionsService'
import MetafieldService from '../../services/database/metafieldService'
import type { Metafield } from '../../types/database/metafields.type'
import type { SourceType } from '../../types/database/source.type'
import { isEmpty } from '../../utils/utils'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'

import { MetafieldsDeleteConfirm } from './metafieldsDeleteConfirm'

type Props = {
    integrationId: string
    sourceId: string
    type: string
    metafields: Metafield[]
    setmetafields: (metafield: Metafield[]) => void
}

export const Metafields: FunctionComponent<Props> = (props: Props): any => {
    const { isPlatformAuthed, platform } = useAuth()
    const { showErrorAlert, showSuccessAlert } = useAlert()
    const { integrationId, sourceId, type, metafields, setmetafields } = props
    const [editIndex, setEditIndex] = useState(-1)
    const [newKey, setNewKey] = useState<string | undefined>()
    const [isNewLoading, setIsNewLoading] = useState(false)
    const [isEditLoading, setIsEditLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [newNamespace, setNewNamespace] = useState<string | undefined>()
    const [activeMetafield, setActiveMetafield] = useState<
        Metafield | undefined
    >()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [errors, setErrors] = useState({
        newKey: '',
        newNamespace: '',
    })
    let rows: any = []

    // metafield saved in db
    const createPlainRow = (
        key: string,
        namespace: string,
        index: number
    ): any => {
        return {
            Key: <div>{`${key}`}</div>,
            Namespace: <div>{`${namespace}`}</div>,
            ' ': (
                <div key={key}>
                    <IconButton
                        disabled={!isPlatformAuthed || isEmpty(platform)}
                        title="Edit"
                        icon={Edit}
                        variant="subtle"
                        onClick={(): void => {
                            setActiveMetafield(metafields[index])
                            setEditIndex(index)
                        }}
                    />
                    <IconButton
                        disabled={!isPlatformAuthed || isEmpty(platform)}
                        title="Delete"
                        icon={Trash}
                        variant="subtle"
                        onClick={(): void => {
                            setActiveMetafield(metafields[index])
                            setIsModalOpen(true)
                        }}
                    />
                </div>
            ),
        }
    }

    // edit a metafield
    const createEditRow = (index: number): any => {
        return {
            Key: (
                <Input
                    disabled={!isPlatformAuthed}
                    placeholder="Key"
                    variant="small"
                    value={activeMetafield?.key}
                    onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                        setActiveMetafield({
                            ...(activeMetafield as Metafield),
                            key: e.target.value,
                        })
                    }}
                />
            ),
            Namespace: (
                <Input
                    disabled={!isPlatformAuthed}
                    placeholder="Namespace"
                    variant="small"
                    value={activeMetafield?.namespace}
                    onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                        setActiveMetafield({
                            ...(activeMetafield as Metafield),
                            namespace: e.target.value,
                        })
                    }}
                />
            ),
            ' ': (
                <div>
                    <IconButton
                        disabled={!isPlatformAuthed || isEmpty(platform)}
                        title="Save"
                        icon={Save}
                        loading={isEditLoading}
                        variant="subtle"
                        onClick={(): void =>
                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            updateMetafield(activeMetafield as Metafield, index)
                        }
                    />
                    <IconButton
                        disabled={
                            !isPlatformAuthed ||
                            isEmpty(platform) ||
                            isEditLoading
                        }
                        title="Cancel"
                        icon={X}
                        variant="subtle"
                        onClick={(): void => setEditIndex(-1)}
                    />
                </div>
            ),
        }
    }

    const setupRows = (): void => {
        rows = metafields.map((field: Metafield, index: number) =>
            editIndex === index
                ? createEditRow(index)
                : createPlainRow(field.key, field.namespace, index)
        )

        // add a metafield
        rows.push({
            Key: (
                <Field
                    state={{
                        errors: [errors.newKey],
                        status: errors.newKey ? 'invalid' : 'default',
                    }}
                >
                    <Input
                        disabled={!isPlatformAuthed}
                        placeholder="Key"
                        variant="small"
                        value={newKey}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setErrors({
                                ...errors,
                                newKey: '',
                            })
                            setNewKey(e.target.value)
                        }}
                    />
                </Field>
            ),
            Namespace: (
                <Field
                    state={{
                        errors: [errors.newNamespace],
                        status: errors.newNamespace ? 'invalid' : 'default',
                    }}
                >
                    <Input
                        disabled={!isPlatformAuthed}
                        placeholder="Namespace"
                        variant="small"
                        value={newNamespace}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setErrors({
                                ...errors,
                                newNamespace: '',
                            })
                            setNewNamespace(e.target.value)
                        }}
                    />
                </Field>
            ),
            ' ': (
                <div>
                    <IconButton
                        disabled={!isPlatformAuthed || isEmpty(platform)}
                        title="Save"
                        icon={Save}
                        loading={isNewLoading}
                        variant="subtle"
                        onClick={(): void => {
                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            saveNewMetafield()
                        }}
                    />
                </div>
            ),
        })
    }

    const validateNewMetafields = (): boolean => {
        let valid = true
        const newErrors = {
            newKey: '',
            newNamespace: '',
        }

        if (isEmpty(newKey)) {
            newErrors.newKey = 'Key is required'
            valid = false
        }

        if (isEmpty(newNamespace)) {
            newErrors.newNamespace = 'Namespace is required'
            valid = false
        }

        setErrors(newErrors)
        return valid
    }

    const saveNewMetafield = (): void => {
        setIsNewLoading(true)
        if (!validateNewMetafields()) {
            return
        }

        const newmetafield: Metafield = {
            source_id: sourceId,
            key: newKey as string,
            namespace: newNamespace as string,
            class: type,
        }

        MetafieldService.create(newmetafield, integrationId)
            .then((metafieldResponse: SourceType) => {
                ConnectionsService.sourceUpdate(integrationId, sourceId)
                    .then(() => {
                        setmetafields(
                            metafieldResponse.metafields?.filter(
                                (field: Metafield) => field.class === type
                            ) ?? []
                        )

                        setupRows()
                        setNewKey(undefined)
                        setNewNamespace(undefined)
                        showSuccessAlert('Saved metafield')
                        setIsNewLoading(false)
                    })
                    .catch(() => {
                        setIsNewLoading(false)
                        showErrorAlert(
                            'Failed sending created metafields data',
                            'Error'
                        )
                    })
            })
            .catch((err) => {
                setIsNewLoading(false)
                showErrorAlert(err, 'Error')
            })
    }

    const deleteMetafield = (metafield: Metafield): void => {
        setIsDeleting(true)
        MetafieldService.delete(metafield, integrationId)
            .then(() => {
                ConnectionsService.sourceUpdate(integrationId, sourceId)
                    .then(() => {
                        setmetafields(
                            metafields.filter(
                                (field) => field.id !== metafield.id
                            )
                        )
                        setIsModalOpen(false)
                        setActiveMetafield(undefined)
                        showSuccessAlert('Metafield deleted')
                        setIsDeleting(false)
                    })
                    .catch(() => {
                        setIsDeleting(false)
                        showErrorAlert(
                            'Failed sending deleted metafields data',
                            'Error'
                        )
                    })
            })
            .catch(() => {
                setIsDeleting(false)
                setIsModalOpen(false)
                setActiveMetafield(undefined)
                showErrorAlert('Failed deleting metafield')
            })
    }

    const updateMetafield = (metafield: Metafield, index: number): void => {
        setIsEditLoading(true)
        MetafieldService.update(metafield, integrationId)
            .then((metafieldResponse: Metafield) => {
                ConnectionsService.sourceUpdate(integrationId, sourceId)
                    .then(() => {
                        if (isEmpty(metafieldResponse.id)) {
                            setActiveMetafield(undefined)
                            showErrorAlert('Failed updating metafield')
                            return
                        }

                        setmetafields(
                            metafields.filter((field) => {
                                if (field.id === activeMetafield?.id) {
                                    return metafieldResponse
                                }

                                return field
                            })
                        )
                        setEditIndex(-1)
                        setActiveMetafield(undefined)
                        metafields[index] = metafieldResponse
                        setmetafields(metafields)
                        showSuccessAlert('Metafield updated')
                        setIsEditLoading(false)
                    })
                    .catch(() => {
                        setIsEditLoading(false)
                        showErrorAlert(
                            'Failed sending updated metafields data',
                            'Error'
                        )
                    })
            })
            .catch(() => {
                setIsEditLoading(false)
                setActiveMetafield(undefined)
                showErrorAlert('Failed updating metafield')
            })
    }

    setupRows()

    return (
        <>
            <MetafieldsDeleteConfirm
                isLoading={isDeleting}
                isModalOpen={isModalOpen}
                metafield={activeMetafield as Metafield}
                setIsModalOpen={setIsModalOpen}
                deleteMetafield={deleteMetafield}
                type={type}
            />
            <span id="metafieldsDataTable">
                <DataTable data={rows} columns={['Key', 'Namespace', ' ']} />
            </span>
        </>
    )
}
