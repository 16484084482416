import { Field, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

import { RECORD_TYPES } from '../../utils/recordTypes'

type Props = {
    sourceId?: string
    recordType?: string
    disabled: boolean
    setRecordType: (indexingLevel: string) => void
}

export const RecordTypeRadioButton: FunctionComponent<Props> = (props) => {
    const params = useParams()
    const {
        sourceId: propSourceId,
        recordType,
        disabled,
        setRecordType,
    } = props
    const { sourceId: paramSourceId } = params
    const sourceId = propSourceId || paramSourceId

    return (
        <>
            <Field className="stl-w-full stl-text-left" label="Record type">
                <label className={stl`cursor-pointer`}>
                    <Field className="stl-w-full stl-text-left stl-pb-15">
                        <input
                            disabled={
                                typeof sourceId !== 'undefined' || disabled
                            }
                            type="radio"
                            name="option"
                            checked={recordType === RECORD_TYPES.product}
                            onChange={(): void => {
                                setRecordType(RECORD_TYPES.product)
                            }}
                        />
                        <span className="stl-ml-10">
                            Product level indexing
                        </span>
                        <div className="stl-ml-23 stl-display-caption stl-text-grey-600 stl-mt-2">
                            We'll index one record per product
                        </div>
                    </Field>
                </label>
                <label className={stl`cursor-pointer`}>
                    <Field className="stl-w-full stl-text-left">
                        <input
                            disabled={
                                typeof sourceId !== 'undefined' || disabled
                            }
                            type="radio"
                            name="option"
                            checked={recordType === RECORD_TYPES.variant}
                            onChange={(): void => {
                                setRecordType(RECORD_TYPES.variant)
                            }}
                        />
                        <span className="stl-ml-10">
                            Variant level indexing
                        </span>
                        <div className="stl-ml-23 stl-display-caption stl-text-grey-600 stl-mt-2">
                            We'll index one record per variant
                        </div>
                    </Field>
                </label>
            </Field>
        </>
    )
}
