import type { ReactNode } from 'react'
import { useEffect, createContext, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Notification } from './common/notification'

interface AlertContextType {
    showErrorAlert: (newTitle?: any, newMessage?: any) => void
    showSuccessAlert: (newTitle?: any, newMessage?: any) => void
    showWarningAlert: (newTitle?: any, newMessage?: any) => void
    showInfoAlert: (newTitle?: any, newMessage?: any) => void
    showPersistentAlert: (
        newTitle: any,
        newMessage: any,
        newVariant?: any
    ) => void
}

const AlertContext = createContext<AlertContextType>({
    showErrorAlert: (): void => {},
    showSuccessAlert: (): void => {},
    showWarningAlert: (): void => {},
    showInfoAlert: (): void => {},
    showPersistentAlert: (): void => {},
})

export function useAlert(): AlertContextType {
    return useContext(AlertContext)
}

type Props = {
    children: ReactNode
}

export function AlertProvider({ children }: Props): JSX.Element {
    const [title, setTitle] = useState('Something went wrong')
    const [variant, setVariant] = useState('red' as const)
    const [message, setMessage] = useState(undefined)
    const [isShowing, setIsShowing] = useState(false)
    const location = useLocation()

    const showAlert = (
        newTitle?: any,
        newMessage?: any,
        newVariant?: any,
        persistent?: boolean
    ): void => {
        setTitle(newTitle || 'Something went wrong')
        setVariant(newVariant || 'red')
        setMessage(newMessage || undefined)
        setIsShowing(true)
        if (!persistent) {
            setTimeout(() => {
                setIsShowing(false)
            }, 5000)
        }
    }

    useEffect(() => {
        setIsShowing(false)
    }, [location])

    const showErrorAlert = (
        newMessage?: React.FC | string,
        newTitle: string = 'Something went wrong'
    ): void => {
        showAlert(newTitle, newMessage, 'red')
    }

    const showSuccessAlert = (
        newMessage?: any,
        newTitle: string = 'Success'
    ): void => {
        showAlert(newTitle, newMessage, 'green')
    }

    const showWarningAlert = (
        newMessage?: any,
        newTitle: string = 'Warning'
    ): void => {
        showAlert(newTitle, newMessage, 'orange')
    }

    const showInfoAlert = (newMessage?: any, newTitle?: string): void => {
        showAlert(newTitle, newMessage, 'accent')
    }

    const showPersistentAlert = (
        newTitle: string,
        newMessage: any,
        newVariant: string = 'accent'
    ): void => {
        showAlert(newTitle, newMessage, newVariant, true)
    }

    return (
        <AlertContext.Provider
            value={{
                showErrorAlert,
                showSuccessAlert,
                showWarningAlert,
                showInfoAlert,
                showPersistentAlert,
            }}
        >
            {isShowing ? (
                <Notification
                    title={title}
                    variant={variant}
                    message={message}
                    bannerState={setIsShowing}
                />
            ) : null}
            {children}
        </AlertContext.Provider>
    )
}
