export default function formatDateTime(
    dateString?: string,
    options?: Intl.DateTimeFormatOptions
): string {
    if (!dateString) {
        return ''
    }

    const dateFormater = new Intl.DateTimeFormat('en-US', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        ...options,
    })

    return dateFormater
        .format(new Date(dateString))
        .replace(/(,|\bat\b)/gi, '')
        .trim()
}
