import { Button, IconButton, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { UploadCloud } from 'react-feather'

import SourceService from '../../services/database/sourceService'
import type { SourceType } from '../../types/database/source.type'
import { useAlert } from '../AlertContext'

type Props = {
    isIconButton?: boolean
    source: SourceType
    isFromAdminView: () => boolean
}

export const ReindexButton: FunctionComponent<Props> = (props: Props): any => {
    const { isIconButton, source, isFromAdminView } = props
    const [isLoading, setIsLoading] = useState(false)
    const { showErrorAlert, showSuccessAlert } = useAlert()

    function reindex(sourceId: string, integrationId: string): any {
        setIsLoading(true)
        SourceService.reindex(integrationId, sourceId)
            .then((data) => {
                setIsLoading(false)
                if (typeof data.error !== 'undefined') {
                    showErrorAlert(
                        'There was an error triggering the reindex',
                        'Error'
                    )

                    return
                }
                showSuccessAlert('Reindex has been triggered', 'Success')
            })
            .catch((error) => {
                setIsLoading(false)
                let message = 'There was an error triggering the reindex'

                if (error.includes('A run is already in progress for task')) {
                    message = 'Index is already in progress'
                }

                showErrorAlert(message, 'Error')
            })
    }

    return isIconButton ? (
        <IconButton
            loading={isLoading}
            icon={UploadCloud}
            title="Reindex"
            variant="primary"
            disabled={isFromAdminView()}
            onClick={(): void => {
                reindex(source.id as string, source.integration_id as string)
            }}
        />
    ) : (
        <Button
            loading={isLoading}
            variant="primary"
            className={stl`w-full stl-mb-4`}
            startIcon={UploadCloud}
            disabled={isFromAdminView()}
            onClick={(): void => {
                reindex(source.id as string, source.integration_id as string)
            }}
        >
            Reindex
        </Button>
    )
}
